import React, {Fragment, useEffect, useState} from "react";
import {STATIC_VARIABLE} from "../constants/static-data";
import Breadcrumb from "./common/breadcrumb";
import {
  Navigation,
  Box,
  MessageSquare,
  Users,
  Briefcase,
  CreditCard,
  ShoppingCart,
  Calendar,
} from "react-feather";
import CountUp from "react-countup";
import { Chart } from "react-google-charts";
import { Bar, Line } from "react-chartjs-2";
import { lineOptions, buyOption, employeeData, employeeOptions } from "../constants/chartData";
// image impoer
import user2 from "../assets/images/dashboard/user2.jpg";
import user1 from "../assets/images/dashboard/user1.jpg";
import man from "../assets/images/dashboard/man.png";
import user from "../assets/images/dashboard/user.png";
import designer from "../assets/images/dashboard/designer.jpg";
import {Button, Card, CardBody, CardHeader, Col, Container, Media, Row, Table} from "reactstrap";
import {handleGetDashboardAPI} from "../controller/dashboard";
import {setOrderStatusTag, setPriceFormat, setStateHelper, setStockStatusTag} from "../utils";
import {useProps} from "../contexts";
import {useHistory} from "react-router-dom";

const getColourByIndex = (index) => {
  let className = '';

  switch (index) {
    case 0:
      className = 'order-color-primary';
      break;
    case 1:
      className = 'order-color-secondary';
      break;
    case 2:
      className = 'order-color-danger';
      break;
    case 3:
      className = 'order-color-warning';
      break;
    case 4:
      className = 'order-color-success';
      break;
    default:
      className = 'order-color-primary';
  }

  return className;
}

const topMemberHeader = ['ID', 'Email', 'Total Spend'];
const claimListHeader = ['Claim ID', 'Name', 'Date', 'Claim Amount'];
const orderListHeader = ['Oder ID', 'Price', 'Status', 'Date'];
const shopListHeader = ['Shop Name', 'Account', 'Stock Status', 'Date'];

const Dashboard = () => {
  const { _handleChange } = useProps();
  const history = useHistory();
  const [mainState, setMainState] = useState({
    totals: {
      member: 0,
      vip: 0,
      vvip: 0,
      shop: 0,
    },
    orderOnline: {
      chart: [],
      list: []
    },
    orderLocal: {
      chart: [],
      list: []
    },
    lineChartData: [],
    topMember: [],
    claimList: [],
    orderList: [],
    shopList: [],
  })

  const handleChangePath = (path: string) => () => {
    history.push(path)
  }

  const _handleGetDashboard = () => {
    handleGetDashboardAPI({
      changeContext: _handleChange,
      setMainState: setStateHelper(setMainState),
    });
  }

  useEffect(() => {
    _handleGetDashboard()
  }, [])

  const lineData = {
    labels: ["100", "200", "300", "400", "500", "600", "700", "800"],
    datasets: [
      {
        lagend: "none",
        data: [2.5, 3, 3, 0.9, 1.3, 1.8, 3.8, 1.5],
        borderColor: "#ff8084",
        backgroundColor: "#ff8084",
        borderWidth: 2,
      },
      {
        lagend: "none",
        data: [3.8, 1.8, 4.3, 2.3, 3.6, 2.8, 2.8, 2.8],
        borderColor: "#a5a5a5",
        backgroundColor: "#a5a5a5",
        borderWidth: 2,
      },
    ],
  };

  const buyData = {
    labels: ["", "10", "20", "30", "40", "50"],
    datasets: [
      {
        backgroundColor: "transparent",
        borderColor: "#13c9ca",
        data: [20, 5, 80, 10, 100, 15],
      },
      {
        backgroundColor: "transparent",
        borderColor: "#a5a5a5",
        data: [0, 50, 20, 70, 30, 27],
      },
      {
        backgroundColor: "transparent",
        borderColor: "#ff8084",
        data: [0, 30, 40, 10, 86, 40],
      },
    ],
  };

  const doughnutOptions = {
    title: "",
    pieHole: 0.35,
    pieSliceBorderColor: "none",
    colors: STATIC_VARIABLE.colorCode,
    legend: {
      position: "none",
    },
    pieSliceText: "none",
    tooltip: {
      trigger: "none",
    },
    animation: {
      startup: true,
      easing: "linear",
      duration: 1500,
    },
    chartArea: { left: 0, top: 10, width: "360px", height: "100%" },
    enableInteractivity: false,
  };
  const pieOptions = {
    title: "",
    pieHole: 1,
    slices: STATIC_VARIABLE.colorCode.map(e => ({color: e})),
    // slices: [
    //   {
    //     color: "#ff8084",
    //   },
    //   {
    //     color: "#13c9ca",
    //   },
    //   {
    //     color: "#f0b54d",
    //   },
    // ],
    tooltip: {
      showColorCode: false,
    },
    chartArea: { left: 0, top: 10, width: "360px", height: "100%" },
    legend: "none",
  };
  const LineOptions = {
    hAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
    },
    vAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
    },
    colors: ["#ff8084"],
    legend: "none",
  };
  const LineOptions1 = {
    hAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
    },
    vAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
    },
    colors: ["#13c9ca"],
    chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
    legend: "none",
  };
  const LineOptions2 = {
    hAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
    },
    vAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
    },
    colors: ["#f5ce8a"],
    chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
    legend: "none",
  };
  const LineOptions3 = {
    hAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
    },
    vAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
    },
    colors: ["#a5a5a5"],
    chartArea: { left: 0, top: 0, width: "100%", height: "100%" },
    legend: "none",
  };

  const cloneEmployeeData = {...employeeData};
  cloneEmployeeData.datasets[0].data = mainState.lineChartData;

  return (
    <Fragment>
      <Breadcrumb title="Dashboard" parent="Dashboard" />
      <Container fluid={true}>
        <Row>
          <Col xs={12} md={6} lg={3}>
            <Card className=" o-hidden widget-cards">
              <CardBody className="bg-danger">
                <Media className="static-top-widget row">
                  <div className="icons-widgets col-4">
                    <div className="align-self-center text-center">
                      {/*<Navigation className="font-warning" />*/}
                      <Users className="font-danger" />
                    </div>
                  </div>
                  <Media body className="col-8">
                    <span className="m-0">Member</span>
                    <h3 className="mb-0">
                      # <CountUp className="counter" end={mainState.totals.member} /><br/>
                      {/*<small> This Month</small>*/}
                    </h3>
                  </Media>
                </Media>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <Card className=" o-hidden  widget-cards">
              <CardBody className="bg-secondary ">
                <Media className="static-top-widget row">
                  <div className="icons-widgets col-4">
                    <div className="align-self-center text-center">
                      {/*<Box className="font-secondary" />*/}
                      <Users className="font-danger" />
                    </div>
                  </div>
                  <Media body className="col-8">
                    <span className="m-0">VIP</span>
                    <h3 className="mb-0">
                      # <CountUp className="counter" end={mainState.totals.vip} /><br/>
                      {/*<small> Total Items</small>*/}
                    </h3>
                  </Media>
                </Media>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <Card className="o-hidden widget-cards">
              <CardBody className="bg-primary">
                <Media className="static-top-widget row">
                  <div className="icons-widgets col-4">
                    <div className="align-self-center text-center">
                      {/*<MessageSquare className="font-primary" />*/}
                      <Users className="font-danger" />
                    </div>
                  </div>
                  <Media body className="col-8">
                    <span className="m-0">VVIP</span>
                    <h3 className="mb-0">
                      # <CountUp className="counter" end={mainState.totals.vvip} /> <br/>
                      {/*<small> This Month</small>*/}
                    </h3>
                  </Media>
                </Media>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} md={6} lg={3}>
            <Card className=" o-hidden widget-cards">
              <CardBody className="bg-bronze">
                <Media className="static-top-widget row">
                  <div className="icons-widgets col-4">
                    <div className="align-self-center text-center">
                      <Users className="font-danger" />
                    </div>
                  </div>
                  <Media body className="col-8">
                    <span className="m-0">Shop</span>
                    <h3 className="mb-0">
                      # <CountUp className="counter" end={mainState.totals.shop} /><br/>
                      {/*<small> Total People</small>*/}
                    </h3>
                  </Media>
                </Media>
              </CardBody>
            </Card>
          </Col>
           <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Sales Status</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs={12} md={6} lg={3}>
                    <div className="order-graph">
                      <h6>Order by online shop location</h6>
                      <div className="chart-block chart-vertical-center">
                        <Chart
                          chartType="PieChart"
                          data={mainState.orderOnline.chart}
                          options={pieOptions}
                          graph_id="PieChartOnline"
                          width={"100%"}
                          height={"180px"}
                          legend_toggle
                        />
                        {/*<Chart*/}
                        {/*  chartType="PieChart"*/}
                        {/*  loader={<div>Loading Chart</div>}*/}
                        {/*  data={mainState.orderOnline.chart}*/}
                        {/*  options={doughnutOptions}*/}
                        {/*  legend_toggle*/}
                        {/*/>*/}
                      </div>
                      <div className="order-graph-bottom" style={{ height: '200px', overflow: 'scroll' }}>
                        {mainState.orderOnline.list.map((item, index) => (
                          <Media>
                            <div className="order-color-primary" style={{backgroundColor: STATIC_VARIABLE.colorCode[index]}}></div>
                            <Media body>
                              <h6 className="mb-0">
                                {item.state} <span className="pull-right">RM {setPriceFormat(item.total)}</span>
                              </h6>
                            </Media>
                          </Media>
                        ))}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={3}>
                    <div className="order-graph sm-order-space">
                      <h6>Order by local shop location</h6>
                      <div className="peity-chart-dashboard text-center">
                        <Chart
                          chartType="PieChart"
                          data={mainState.orderLocal.chart}
                          options={pieOptions}
                          graph_id="PieChart"
                          width={"100%"}
                          height={"180px"}
                          legend_toggle
                        />
                      </div>
                      <div className="order-graph-bottom sales-location">
                        {mainState.orderLocal.list.map((item, index) => (
                          <Media>
                            <div className="order-color-primary" style={{backgroundColor: STATIC_VARIABLE.colorCode[index]}}></div>
                            {/*<div className={getColourByIndex(index)}></div>*/}
                            <Media body>
                              <h6 className="mb-0 mr-0">
                                {item.state} <span className="pull-right">RM {setPriceFormat(item.total)}</span>
                              </h6>
                            </Media>
                          </Media>
                        ))}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={12} lg={6}>
                    <div className="order-graph xl-space">
                      <h6>Revenue for last week</h6>
                      <div className="ct-4 flot-chart-container">
                        <Line data={cloneEmployeeData} options={employeeOptions} />
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} md={12} lg={6}>
            <Card>
              <CardHeader>
                <div className="product-buttons customize-table-header">
                  <h5>Top Performance Account</h5>
                  <Button
                    type="button"
                    color="primary"
                    onClick={handleChangePath('/users/list-user')}
                  >
                    View More
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <div className="user-status table-responsive latest-order-table">
                  <Table>
                    <thead>
                      <tr>
                        {topMemberHeader.map(e => (
                          <th scope="col">{e}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {!mainState.topMember.length &&
                        <tr>
                          <td colspan="3" align="center">No data found</td>
                        </tr>
                      }
                      {mainState.topMember.map(e => (
                        <tr>
                          <td><a className="customize-a" href={`/users/details-user/${e.ID}`}>#{e.ID}-{e.username}</a></td>
                          <td>{e.email}</td>
                          <td className="digits">RM {setPriceFormat(e.spend)}</td>
                          {/*<td className="font-danger">Bank Transfers</td> */}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {/*<a href="#javaScript" className="btn btn-primary">*/}
                  {/*  View All Orders*/}
                  {/*</a>*/}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} md={12} lg={6}>
            <Card className="height-equal">
              <CardHeader>
                <div className="product-buttons customize-table-header">
                  <h5>Commission Claim List</h5>
                  <Button
                    type="button"
                    color="primary"
                    onClick={handleChangePath('/claim')}
                  >
                    View More
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <div className="user-status table-responsive products-table">
                  <table className="table table-bordernone mb-0" style={{width: '100%'}}>
                    <thead>
                      <tr>
                        {claimListHeader.map(e => (
                          <th scope="col" >{e}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {!mainState.claimList.length &&
                        <tr>
                          <td colspan="4" align="center">No data found</td>
                        </tr>
                      }
                      {mainState.claimList.map(e => (
                        <tr>
                          <td style={{minWidth: '20%'}}><a className="customize-a" href={`/claims/details-claim/${e.ID}`}>{`#${e.ID}-${e.username}`}</a></td>
                          <td><a className="customize-a" href={`/users/details-user/${e.memberID}`}>#{e.memberID}-{e.username}</a></td>
                          <td>{e.date}</td>
                          <td className="digits">RM {setPriceFormat(e.claimAmount)}</td>
                          {/*<td className="font-primary">Pending</td>*/}
                          {/*<td className="digits">$6523</td>*/}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col xs={12} md={12} lg={6}>
            <Card className="height-equal">
              <CardHeader>
                <div className="product-buttons customize-table-header">
                  <h5>Member Order List</h5>
                  <Button
                    type="button"
                    color="primary"
                    onClick={handleChangePath('/sales/orders')}
                  >
                    View More
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <div className="user-status table-responsive products-table">
                  <table className="table table-bordernone mb-0">
                    <thead>
                    <tr>
                      {orderListHeader.map(e => (
                        <th scope="col">{e}</th>
                      ))}
                    </tr>
                    </thead>
                    <tbody>
                    {!mainState.orderList.length &&
                      <tr>
                        <td colspan="4" align="center">No data found</td>
                      </tr>
                    }
                    {mainState.orderList.map(e => (
                      <tr>
                        <td style={{minWidth: '20%'}}><a className="customize-a" href={`/sales/orders-details/${e.ID}`}>{e.ID}</a></td>
                        <td className="digits">RM {setPriceFormat(e.amount)}</td>
                        <td>{setOrderStatusTag(e.status)}</td>
                        <td>{e.createdAt}</td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col xs={12} md={12} lg={6}>
            <Card className="height-equal">
              <CardHeader>
                <div className="product-buttons customize-table-header">
                  <h5>Replenishment List</h5>
                  <Button
                    type="button"
                    color="primary"
                    onClick={handleChangePath('/shop/list-shop')}
                  >
                    View More
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <div className="user-status table-responsive products-table">
                  <table className="table table-bordernone mb-0">
                    <thead>
                    <tr>
                      {shopListHeader.map(e => (
                        <th scope="col">{e}</th>
                      ))}
                    </tr>
                    </thead>
                    <tbody>
                    {!mainState.shopList.length &&
                    <tr>
                      <td colspan="4" align="center">No data found</td>
                    </tr>
                    }
                    {mainState.shopList.map(e => (
                      <tr>
                        <td><a className="customize-a" href={`/shop/details-shop/${e.shopID}`}>#{e.shopID}-{e.shopName}</a></td>
                        <td><a className="customize-a" href={`/users/details-user/${e.memberID}`}>#{e.memberID}-{e.username}</a></td>
                        <td className="digits">{setStockStatusTag(e.stockStatus)}</td>
                        <td>{e.createdAt}</td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>

        </Row>
      </Container>
    </Fragment>
  );
};

// javascript:void(0)

export default Dashboard;
