import React, { Fragment, useState } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Button, ButtonDropdown, Col, Form, FormGroup, Input, InputGroup, Label, Row} from "reactstrap";
import moment from "moment";

const Tabset = ({
  isCreate,
  mainState,
  handleSubmit = () => {},
  handleTextInput = () => {},
  handleSelectDate = () => {},
}) => {
  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendDate] = useState(new Date());
  const handleStartDate = (date) => {
    setstartDate(date);
  };

  const handleEndDate = (date) => {
    setendDate(date);
  };
  const clickActive = (event) => {
    document.querySelector(".nav-link").classList.remove("show");
    event.target.classList.add("show");
  };

  console.log('check', isCreate)

  return (
    <Fragment>
      <Tabs>
        <TabList className="nav nav-tabs tab-coupon">
          <Tab className="nav-link" onClick={(e) => clickActive(e)}>
            General
          </Tab>
          {/* <Tab className="nav-link" onClick={(e) => clickActive(e)}>
            Restriction
          </Tab>
          <Tab className="nav-link" onClick={(e) => clickActive(e)}>
            Usage
          </Tab> */}
        </TabList>

        <TabPanel>
          <div className="tab-pane fade active show needs-validation">
            {/* <Form className="needs-validation" noValidate=""> */}
            <h4>General</h4>
            <Row>
              <Col sm="12">
                <div className="form-group row">
                  <Label className="col-xl-3 col-md-4">
                    <span>*</span> Title
                  </Label>
                  <Input
                    className="form-control col-md-7"
                    id="validationCustom0"
                    type="text"
                    required=""
                    value={mainState.title}
                    onChange={(event) => handleTextInput(event, "title")}
                  />
                </div>
                <div className="form-group row">
                  <Label className="col-xl-3 col-md-4">Description</Label>
                  <Input
                    className="form-control col-md-7"
                    id="validationCustom0"
                    type="text"
                    required=""
                    value={mainState.description}
                    onChange={(event) => handleTextInput(event, "description")}
                  />
                </div>
                <div className="form-group row">
                  <Label className="col-xl-3 col-md-4">
                    <span>*</span> Code
                  </Label>
                  <Input
                    className="form-control col-md-7"
                    id="validationCustom1"
                    type="text"
                    required=""
                    value={mainState.code}
                    onChange={(event) => handleTextInput(event, "code")}
                  />
                  <div className="valid-feedback">Please Provide a Valid Voucher Code.</div>
                </div>
                {/* <div className="form-group row">
										<Label className="col-xl-3 col-md-4">Start Date</Label>
										<DatePicker
											selected={startDate}
											onChange={handleStartDate}
										/>
									</div>
									<div className="form-group row">
										<Label className="col-xl-3 col-md-4">End Date</Label>
										<DatePicker
											selected={endDate}
											endDate={endDate}
											onChange={handleEndDate}
										/>
									</div> */}
                {/* <div className="form-group row">
										<Label className="col-xl-3 col-md-4">Free Shipping</Label>
										<div className="col-md-7 checkbox-space">
											<Label className="d-block">
												<Input
													className="checkbox_animated"
													id="chk-ani2"
													type="checkbox"
												/>
												Allow Free Shipping
											</Label>
										</div>
									</div> */}
                <div className="form-group row">
                  <Label className="col-xl-3 col-md-4">
                    <span>*</span> Discount
                  </Label>
                  <InputGroup className="col-md-7 p-0">
                    <Input
                      style={{ width: 'auto' }}
                      className="form-control"
                      id="validationCustom02"
                      type="number"
                      required=""
                      value={mainState.discount}
                      onChange={(event) => handleTextInput(event, "discount")}
                    />
                    <ButtonDropdown>
                      <select
                        className="custom-select"
                        required=""
                        defaultValue={1}
                        value={mainState.discountType}
                        onChange={(event) => handleTextInput(event, "discountType")}
                      >
                        <option value="1">MYR</option>
                        <option value="2">Percents (%)</option>
                      </select>
                    </ButtonDropdown>
                  </InputGroup>
                </div>
                <div className="form-group row">
                  <Label className="col-xl-3 col-md-4">
                    <span>*</span> Type
                  </Label>
                  <select
                    className="custom-select col-md-7"
                    required=""
                    disabled={!isCreate}
                    value={mainState.type}
                    onChange={(event) => handleTextInput(event, "type")}
                  >
                    <option value="">--Select--</option>
                    <option value="1">Welcome</option>
                    <option value="2">Birthday</option>
                    <option value="3">Normal</option>
                    <option value="4">Public</option>
                    <option value="5">Public Member</option>
                  </select>
                </div>
                {mainState.type === 3 &&
                  <div className="form-group row">
                    <Label className="col-xl-3 col-md-4">
                      <span>*</span> Total Quantity
                    </Label>
                    <Input
                      className="form-control col-md-7"
                      type="number"
                      required=""
                      min={0}
                      value={mainState.total}
                      onChange={(event) => handleTextInput(event, "total")}
                    />
                  </div>
                }

                <div className="form-group row">
                  <Label className="col-xl-3 col-md-4"><span>*</span>Minimum Spend</Label>
                  <FormGroup className="col-md-7 p-0 m-0">
                    <InputGroup>
                      <Input
                        style={{ width: 'auto' }}
                        className="form-control customize-first-border"
                        id="validationCustom1"
                        type="number"
                        min={0}
                        required=""
                        value={mainState.minSpend}
                        onChange={(event) => handleTextInput(event, "minSpend")}
                      />
                      <Input disabled className="customize-side-input customize-second-border" value="MYR" />
                    </InputGroup>
                  </FormGroup>
                </div>

                {/*<div className="form-group row">*/}
                {/*  <Label className="col-xl-3 col-md-4">Maximum Spend</Label>*/}
                {/*  <Input className="form-control col-md-7" id="validationCustom5" type="number" />*/}
                {/*</div>*/}

                <div className="form-group row">
                  <Label className="col-xl-3 col-md-4">Points</Label>
                  <InputGroup className="col-md-7 p-0">
                    <Input
                      className="form-control"
                      id="validationCustom02"
                      type="number"
                      min={0}
                      required=""
                      value={mainState.points}
                      onChange={(event) => handleTextInput(event, "points")}
                    />
                  </InputGroup>
                </div>

                <div className="form-group row">
                  <Label className="col-xl-3 col-md-4">Valid Days</Label>
                  <InputGroup className="col-md-7 p-0">
                    <Input
                      className="form-control"
                      id="validationCustom02"
                      type="number"
                      required=""
                      min={0}
                      value={mainState.validDays}
                      onChange={(event) => handleTextInput(event, "validDays")}
                    />
                  </InputGroup>
                </div>

                <div className="form-group row">
                  <Label className="col-xl-3 col-md-4">Expire Date</Label>
                  <DatePicker
                    className="col-md-7"
                    selected={(mainState.expiredDate !== "" && mainState.expiredDate) ? moment(mainState.expiredDate).toDate() : undefined}
                    onChange={(date) => handleSelectDate(date, "expiredDate")}
                  />
                </div>
                <div className="form-group row">
                  <Label className="col-xl-3 col-md-4">Status</Label>
                  <div className="col-md-7 checkbox-space">
                    <Label className="d-block">
                      <Input
                        className="checkbox_animated"
                        id="chk-ani2"
                        type="checkbox"
                        checked={(mainState.status)}
                        onChange={(event) => handleTextInput(event, "status")}
                      />
                      Enable the Voucher
                    </Label>
                  </div>
                </div>
              </Col>
            </Row>
            {/* </Form> */}
          </div>
        </TabPanel>
        <TabPanel>
          {/* <Form className="needs-validation" noValidate=""> */}
          <h4>Restriction</h4>
          <div className="form-group row">
            <Label className="col-xl-3 col-md-4">Products</Label>
            <Input className="form-control col-md-7" id="validationCustom3" type="text" required="" />
            <div className="valid-feedback">Please Provide a Product Name.</div>
          </div>
          <div className="form-group row">
            <Label className="col-xl-3 col-md-4">Category</Label>
            <select className="custom-select col-md-7" required="">
              <option value="">--Select--</option>
              <option value="1">Electronics</option>
              <option value="2">Clothes</option>
              <option value="2">Shoes</option>
              <option value="2">Digital</option>
            </select>
          </div>
          <div className="form-group row">
            <Label className="col-xl-3 col-md-4">Minimum Spend</Label>
            <Input className="form-control col-md-7" id="validationCustom4" type="number" />
          </div>
          <div className="form-group row">
            <Label className="col-xl-3 col-md-4">Maximum Spend</Label>
            <Input className="form-control col-md-7" id="validationCustom5" type="number" />
          </div>
          {/* </Form> */}
        </TabPanel>
        <TabPanel>
          {/* <Form className="needs-validation" noValidate=""> */}
          <h4>Usage Limits</h4>
          <div className="form-group row">
            <Label className="col-xl-3 col-md-4">Per Limit</Label>
            <Input className="form-control col-md-7" id="validationCustom6" type="number" />
          </div>
          <div className="form-group row">
            <Label className="col-xl-3 col-md-4">Per Customer</Label>
            <Input className="form-control col-md-7" id="validationCustom7" type="number" />
          </div>
          {/* </Form> */}
        </TabPanel>
      </Tabs>
      <div className="pull-right">
        <Button type="button" color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </div>
    </Fragment>
  );
};

export default Tabset;
