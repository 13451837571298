import React, { Fragment, useEffect, useRef, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";
import { useParams } from "react-router-dom";
import { useProps } from "../../contexts";
import { STATIC_VARIABLE } from "../../constants/static-data";

//Component
import Breadcrumb from "../common/breadcrumb";
import DetailsSetClaim from "./details-set";
import DetailsSetCommission from "./commission-details";
import ClaimModal from "../common/modal/claim-modal";
import { handleGetClaimDetailsAPI, handleSubmitReceiptAPI } from "../../controller/claims";
import { getArrayObjByLabel, setStateHelper } from "../../utils";
import DeletePopUp from "../common/modal/DeletePopUp";
import DownloadButton from "../common/download-btn";
//Controller

const ClaimDetails = () => {
  const { claimID } = useParams();
  const history = useHistory();
  const { _handleChange } = useProps();
  const claimModelRef = useRef(null);
  const deletePopUpRef = useRef(null);

  const [mainState, setMainState] = useState({
    ID: 11,
    claimStatus: 4,
    receiptURL: "",
    createdAt: "",
    bank: {
      name: "",
      account: "",
      recipientName: "",
      billingAddress: {
        address: "",
        state: "",
        postcode: 81000,
        country: "",
      },
    },
    claimDetails: {
      memberID: 1,
      username: null,
      phoneNumber: null,
      email: "",
      claimStatus: 4,
      isShop: false,
      tier: 3,
    },
    commissionDetails: {
      firstLayer: {
        point: 0,
        commission: 0,
        rate: 0,
      },
      secondLayer: {
        point: 0,
        commission: 0,
        rate: 0,
      },
      shopLayer: {
        point: "-",
        commission: "-",
        rate: "-",
      },
      totalPoint: 0,
      totalCommission: 0,
    },
    rateDetails: {
      firstLayer: {
        normalRate: 0,
        targetPV: 0,
        targetRate: 0,
      },
      secondLayer: {
        normalRate: 0,
        targetPV: 0,
        targetRate: 0,
      },
      shopLayer: {
        normalRate: 0,
        targetPV: 0,
        targetRate: 0,
      },
      isHitTarget: 0,
    },
  });

  const _handleSuspendAPI = (file) => {
    const suspend = getArrayObjByLabel(STATIC_VARIABLE.claimStatusArray, 'Suspend');
    handleSubmitReceiptAPI( file, claimID, suspend.value, _handleChange).then((isSuccess) => {
      if (isSuccess) {
        deletePopUpRef.current.handleClose();
        history.goBack();
      }
    })
  }

  const _handleSubmitReceiptAPI = (file) => {
    if (file.length > 0) {
      const claimed = getArrayObjByLabel(STATIC_VARIABLE.claimStatusArray, 'Claimed');
      handleSubmitReceiptAPI( file, claimID, claimed.value, _handleChange).then((isSuccess) => {
        if (isSuccess) {
          claimModelRef.current.handleClose();
          history.goBack();
        }
      })
    }
    else {
      _handleChange({
        snackbar: true,
        snackbarType: 1,
        snackbarContent: "Please upload at least one image!",
      })
    }
  }

  const _handleGetClaimDetailsAPI = () => {
    handleGetClaimDetailsAPI(setStateHelper(setMainState), claimID, _handleChange);
  };

  useEffect(() => {
    _handleGetClaimDetailsAPI();
  }, []);

  return (
    <Fragment>
      <Breadcrumb title={"Claim Details"} parent="Claim" />
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <div className="customize-header-bottom-container" style={{ alignItems: 'center' }}>
              <h5>Claim Details [{moment(mainState.createdAt).format('MMM YYYY')}]</h5>
              {
                mainState.claimStatus == getArrayObjByLabel(STATIC_VARIABLE.claimStatusArray, 'Unclaimed').value &&
                <div>
                  <Button
                    type="button"
                    color="secondary"
                    outline
                    style={{marginRight: 10}}
                    onClick={() => {
                      deletePopUpRef.current.setMainState({
                        isOpen: true,
                        itemName: `Claim [ID: ${mainState.ID}] `
                      });
                    }}
                  >
                    Suspend
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => {
                      claimModelRef.current.handleOpen();
                    }}
                  >
                    Claim
                  </Button>
                </div>
              }

              {mainState.receiptURL && <DownloadButton path={mainState.receiptURL} name={'Receipt Image'}/>}
            </div>
          </CardHeader>
          <CardBody>
            <DetailsSetClaim userDetails={mainState.claimDetails} bankDetails={mainState.bank}/>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <h5>Commission Details</h5>
          </CardHeader>
          <CardBody>
            <DetailsSetCommission commissionDetails={mainState.commissionDetails} rateDetails={mainState.rateDetails}/>
          </CardBody>
        </Card>
      </Container>
      <ClaimModal ref={claimModelRef} handleSubmit={(e) => _handleSubmitReceiptAPI(e)} />
    <DeletePopUp ref={deletePopUpRef} isSuspend title="Claim" handleDelete={() => _handleSuspendAPI([])} />
    </Fragment>
  );
};

export default ClaimDetails;
