import React from "react";
import ReactExport from "react-data-export";
import moment from "moment";
import {getArrayObjByValue} from "../../utils";
import {STATIC_VARIABLE} from "../../constants/static-data";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const titleStyle = {
  fill: {
    patternType: "solid",
    fgColor: {rgb: "C0C0C0"}
  },
  alignment: {
    vertical: 'center',
    horizontal: 'center',
  },
  border: {
    top: { style: 'thin', color: '000000' },
    bottom: { style: 'thin', color: '000000' },
    left: { style: 'thin', color: '000000' },
    right: { style: 'thin', color: '000000' }
  }
};

const columnStyle = {
  border: {
    top: { style: 'thin', color: '000000' },
    bottom: { style: 'thin', color: '000000' },
    left: { style: 'thin', color: '000000' },
    right: { style: 'thin', color: '000000' }
  }
}

const highlightColumn = {
  fill: {
    fgColor: { rgb: 'D9E1F2' }
  },
  border: {
    top: { style: 'thin', color: '000000' },
    bottom: { style: 'thin', color: '000000' },
    left: { style: 'thin', color: '000000' },
    right: { style: 'thin', color: '000000' }
  }
}

const getStockStatusColor = (status) => {
  let color = ''

  switch (status) {
    case 1:
      color = 'FFC000'
      break
    case 2:
      color = '70AD47'
      break
    case 3:
      color = 'ED7D31'
      break
    default:
      color = '70AD47'
  }

  return color
}


function ExcelShopList(props) {
  const {items} = props;

  const dataItem = []
  let index = 0;

  // location: "Johor"
  // name: "Liang Company"
  // phoneNumber: "0123456789"
  // productStock: (4) [{…}, {…}, {…}, {…}]
  // shopCode: "WL62BD"
  // status: 5
  // totalStock: 10
  for (const item of items) {
    const tmpArray = [
      {value: index+1, style: columnStyle},
      {value: item.name, style: columnStyle},
      {value: item.phoneNumber, style: columnStyle},
      {value: item.location, style: columnStyle},
      {value: getArrayObjByValue(STATIC_VARIABLE.shopStatusArray, item.status).label, style: columnStyle},
      {value: item.totalStock, style: columnStyle},
      {value: 'Name', style: highlightColumn},
      {value: 'Stock Status', style: highlightColumn},
      {value: item.memberCreatedAt, style: columnStyle},
    ]
    dataItem.push(tmpArray)


    for (const product of item.productStock) {
      const nextArr = [
        {value: ''},
        {value: ''},
        {value: ''},
        {value: ''},
        {value: ''},
        {value: ''},
        {value: product.name, style: columnStyle},
        {
          value: product.stock,
          style: {
            font: {
              color: {rgb: getStockStatusColor(product.stockStatus)}
            },
            border: {
              top: { style: 'thin', color: '000000' },
              bottom: { style: 'thin', color: '000000' },
              left: { style: 'thin', color: '000000' },
              right: { style: 'thin', color: '000000' }
            }
          }
        },
        {value: ''}
      ]
      dataItem.push(nextArr)
    }

    index += 1
  }

  const multiDataSet = [
    {
      columns: [
        {title: "No", style: titleStyle, width: {wpx: 30}},
        {title: "Shop Name", style: titleStyle, width: {wpx: 150}},
        {title: "Phone Number", style: titleStyle, width: {wpx: 270}},
        {title: "Location", style: titleStyle, width: {wpx: 270}},
        {title: "Status", style: titleStyle, width: {wpx: 150}},
        {title: "Total Stock", style: titleStyle, width: {wpx: 150}},
        {title: "Products", style: titleStyle, width: {wpx: 150}},
        {title: "Member Join Date", style: titleStyle, width: {wpx: 150}},
      ],
      data: dataItem
    }
  ];

  return (
    <ExcelFile
      element={<button id='shop-export' style={{ display: 'none' }}>Download Data</button>}
      filename={`Replenishment-List-${moment().unix()}`}
    >
      <ExcelSheet dataSet={multiDataSet} name="Organization"/>
    </ExcelFile>
  )
}

export default ExcelShopList;
