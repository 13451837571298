import React, { Fragment, useEffect, useRef, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import useQuery from "../../hooks/useQuery";
import { useProps } from "../../contexts";
//Component
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../common/datatable";
import data from "../../assets/data/sales-transactions";
import { handleGetOrderTransactionListAPI } from "../../controller/order";
import { setPriceFormat, setStateHelper } from "../../utils";
// ID:"142",
// transaction_id:"#212145214510",
// createdAt:"Sep 2,19",
// payment_method:"Paypal",
// status:"Pending",
// amount:"$175/-"
const generateBody = (body, page, rowsPerPage, handleDelete = () => {}) => {
  console.log(body)
  return body.map((e, i) => ({
    id: <p style={{ fontWeight: "bold" }}>{(page - 1) * rowsPerPage + i + 1}.</p>,
    transaction_id: e.ID,
    payment_method: e.paymentMethod,
    orderID: (
      <a className="customize-a" href={`/sales/orders-details/${e.orderID}`}>
        #{e.orderID}
      </a>
    ),
    amount: 'RM ' + setPriceFormat(e.amount),
    createdAt: e.createdAt,
  }));
};

const header = [
  { id: "id", label: "", align: "center" },
  { id: "transaction_id", label: "Transaction Id", align: "left" },
  { id: "payment_method", label: "Payment Method", align: "left" },
  { id: "orderID", label: "Order ID", align: "left" },
  { id: "amount", label: "Total Amount", align: "center" },
  { id: "createdAt", label: "Created Date", align: "center" },
];

const Transactions_sales = () => {
  const [query, setQuery] = useQuery();
  const { _handleChange } = useProps();
  const deleteSalesPopUpRef = useRef();
  const [mainState, setMainState] = useState({
    list: [],
    page: query.page ? parseInt(query.page) : 1,
    limit: query.limit ? parseInt(query.limit) : 10,
    total: 10,
    order: query.order || "desc",
    orderBy: query.orderBy || "date",
    search: "",
    status: 0,
    categoryID: 0,
    refreshData: false,
  });

  const _handleGetOrderList = () => {
    const queryObj = {
      page: mainState.search ? 1 : mainState.page,
      limit: mainState.limit,
      order: mainState.order,
      orderBy: mainState.orderBy,
      search: mainState.search,
    };
    const param = {
      ...queryObj,
      changeContext: _handleChange,
      setMainState: setStateHelper(setMainState),
    };
    setQuery(queryObj);
    handleGetOrderTransactionListAPI(param);
  };

  useEffect(() => {
    _handleGetOrderList();
  }, [
    mainState.page,
    mainState.order,
    mainState.orderBy,
    mainState.limit,
    mainState.search,
    mainState.refreshData
  ]);

  const body = generateBody(mainState.list, mainState.page, mainState.limit);
  return (
    <Fragment>
      <Breadcrumb title="Transactions" parent="Sales" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>List of Transactions</h5>
              </CardHeader>
              <CardBody>
                <div id="batchDelete" className="transactions">
                  <Datatable
                    header={header}
                    myData={body}
                    order={mainState.order}
                    orderBy={mainState.orderBy}
                    setState={setStateHelper(setMainState)}
                    rowsPerPage={mainState.limit}
                    count={mainState.total}
                    page={mainState.page}
                    total={mainState.total}
                    pagination={true}
                    class="-striped -highlight"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Transactions_sales;
