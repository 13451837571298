import React, { useImperativeHandle, useState, useCallback } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { getArrayObjByValue, setStateHelper } from "../../../utils";

const DeleteShopPopUp = React.forwardRef((props, ref) => {
  const { title = "", handleDelete = () => {} } = props;

  const [mainState, setMainState] = useState({
    deleteID: 0,
    itemName: "",
    isOpen: false,
    selectedIC: '',
    icList: [],
    othersID: ""
  });

  const handleOpen = () => {
    setStateHelper(setMainState)({ isOpen: true });
  };

  const handleClose = () => {
    setStateHelper(setMainState)({ isOpen: false });
  };

  const _handleDelete = () => {
    handleDelete(mainState.deleteID);
  };

  const handleSetICList = (list) => {
    list.map((e, index) => {
      e.isSelect = false;
    })
    setStateHelper(setMainState)({icList: list})
  };

  const _handleSelectIC = (id) => {
    let _list = [...mainState.icList];
    let icNumber = '';

    _list.map((e, index) => {
      if (index == id) {
        e.isSelect = !e.isSelect;

        if (e.isSelect) {
          icNumber = e.icNumber
        }
      }
      else {
        e.isSelect = false;
      }
    })
    setStateHelper(setMainState)({
      icList: _list,
      selectedIC: icNumber,
      othersID: ""
    })
  }

  const handleOtherIDChange = (event) => {
    const value = event.target.value
    const resetList = [...mainState.icList]

    resetList.map((e) => e.isSelect = false)

    setStateHelper(setMainState)({
      icList: resetList,
      othersID: value,
      selectedIC: value
    })
  }

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleClose,
    setMainState,
    newIC: mainState.selectedIC,
    handleSetICList
  }));

  return (
    <Modal isOpen={mainState.isOpen} toggle={handleClose} centered>
      <ModalHeader toggle={handleClose}>
        <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          {"Delete"} {title}
        </h5>
      </ModalHeader>
      <ModalBody style={{ margin: "0px 10px" }}>
      <Label style={{fontWeight:'bold'}}>Please select one partner IC or a new IC to inherit member account.</Label>
        <Label style={{marginTop: 10}}>Available IC No.</Label>
        {mainState.icList.map((e, index) => {
          return (
            <Row >
              <Col>
              <div className="col-md-7 checkbox-space">
                <Label className="d-block">
                  <Input
                    className="checkbox_animated"
                    id="chk-ani2"
                    type="checkbox"
                    checked={e.isSelect}
                    onChange={(event) => {_handleSelectIC(index)}}
                  />
                  {e.icNumber}
                </Label>
              </div>
              </Col>
            </Row>
          );
        })}
        <Row >
          <Col>
            <div className="col-md-7 checkbox-space">
              <Label className="d-block">
                <Input
                  className="checkbox_animated"
                  id="chk-ani2"
                  type="checkbox"
                  checked={!!mainState.othersID}
                  // onChange={(event) => {}}
                />
                Other IC No.
              </Label>
            </div>
            <Input
              className="form-control col-xl-8 col-md-7"
              style={{ marginLeft: 30 }}
              id={"newIC"}
              type={"text"}
              placeholder={"New IC No."}
              required=""
              value={mainState.othersID}
              onChange={handleOtherIDChange}
            />
          </Col>
        </Row>
        <Label style={{fontWeight:'bold', fontSize: 12, marginTop: 10}}>Noted*: If ssm partner already has other member account, IC will unable to inherit this account.</Label>

      </ModalBody>
      <ModalFooter>
        <Button type="button" color="primary" onClick={_handleDelete}>
          {"Delete"}
        </Button>
        <Button type="button" outline color="secondary" onClick={handleClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
});
export default DeleteShopPopUp;
