import {STATIC_VARIABLE, TAG_ENUM} from "../constants/static-data";


export const handleDownloadImage = async (fileURL) => {
  const link = document.createElement("a");
  link.target = "_blank";
  link.href = fileURL.path;
  link.download = fileURL.name;
  link.click();
};

export const setStateHelper = (callback) => (newState) => {
  callback((prevState) => ({
    ...prevState,
    ...newState,
  }));
};

export const getArrayObjByValue = (array, value, valueKey = 'value') => {
  if (!array) return;
  let result = array.filter(obj => {
    return obj[valueKey] == parseInt(value);
  })
  return result[0];
};

export const getArrayObjByLabel = (array, label) => {
  if (!array) return;
  let result = array.filter(obj => {
    return obj.label == label
  })
  return result[0];
};

export const checkIsSuperAdmin = (user) => {
  let result = getArrayObjByValue(STATIC_VARIABLE.roleArray, user.role);
  if (result == 'Super Admin') {
    return true;
  }
  else {
    return false;
  }
}

export const plainTextWithoutHTML = (html) => {
  return html.replace(/(<([^>]+)>)/gi, "");
}

export const setOrderStatusTag = (status) => {
  let className = "badge badge-success";
  let text = '';
  switch (status) {
    case getArrayObjByLabel(STATIC_VARIABLE.orderStatusArray, 'Pending Payment').value:
      className = "badge badge-pending-payment";
      text = "Pending Payment"
      break;
    case getArrayObjByLabel(STATIC_VARIABLE.orderStatusArray, 'Processing').value:
      className = "badge badge-processing";
      text = "Processing"
      break;
    case getArrayObjByLabel(STATIC_VARIABLE.orderStatusArray, 'Cancel').value:
      className = "badge badge-danger";
      text = "Cancel"
      break;
    case getArrayObjByLabel(STATIC_VARIABLE.orderStatusArray, 'Completed').value:
      className = "badge badge-success";
      text = "Completed"
      break;
    case getArrayObjByLabel(STATIC_VARIABLE.orderStatusArray, 'Failed').value:
      className = "badge badge-danger-red";
      text = "Failed"
      break;
    default:
      break;
  }
  let result = <span className={className}>{text}</span>
  return result;
}

export const setStockTransactionStatusTag = (status) => {
  let className = "badge badge-success";
  let text = '';
  switch (status) {
    case getArrayObjByLabel(STATIC_VARIABLE.stockTransactionStatusArray, 'Restock').value:
      className = "badge badge-success";
      text = "Restock"
      break;
    case getArrayObjByLabel(STATIC_VARIABLE.stockTransactionStatusArray, 'Sell').value:
      className = "badge badge-danger-red";
      text = "Sell"
      break;
  }
  let result = <span className={className}>{text}</span>
  return result;
}

export const setTargetStatusTag = (status) => {
  let className = "badge badge-success";
  let text = '';
  switch (status) {
    case getArrayObjByLabel(STATIC_VARIABLE.targetStatusArray, 'Completed').value:
      className = "badge badge-success";
      text = "Completed"
      break;
    case getArrayObjByLabel(STATIC_VARIABLE.targetStatusArray, 'Uncompleted').value:
      className = "badge badge-danger";
      text = "Uncompleted"
      break;
    default:
      break;
  }
  let result = <span className={className}>{text}</span>
  return result;
}

export const setProductStatusTag = (status) => {
  let className = "badge badge-success";
  let text = '';
  switch (status) {
    case getArrayObjByLabel(STATIC_VARIABLE.productStatusArray, 'Published').value:
      className = "badge badge-success";
      text = "Published";
      break;
    case getArrayObjByLabel(STATIC_VARIABLE.productStatusArray, 'Draft').value:
      className = "badge badge-danger";
      text = "Draft"
      break;
    default:
      break;
  }
  let result = <span className={className}>{text}</span>
  return result;
}

export const setShopStatusTag = (status) => {
  let className = "badge badge-success";
  let text = '';
  switch (status) {
    case getArrayObjByLabel(STATIC_VARIABLE.userStatusArray, 'Active').value:
      className = "badge badge-success";
      text = "Yes"
      break;
    case getArrayObjByLabel(STATIC_VARIABLE.userStatusArray, 'Suspend').value:
      className = "badge badge-danger";
      text = "No"
      break;
    default:
      break;
  }
  let result = <span className={className}>{text}</span>
  return result;
}

export const setIsJoinShopStatusTag = (status) => {
  let className = "badge badge-success";
  let text = '';
  switch (status) {
    case getArrayObjByLabel(STATIC_VARIABLE.orderRoleStatusArray, 'Member').value:
      className = "badge badge-danger";
      text = "No"
      break;
    case getArrayObjByLabel(STATIC_VARIABLE.orderRoleStatusArray, 'Shop').value:
      className = "badge badge-success";
      text = "Yes"
      break;
    default:
      break;
  }
  let result = <span className={className}>{text}</span>
  return result;
}

export const setBenefitStatusTag = (status) => {
  let className = "badge badge-secondary";
  let text = '';
  switch (status) {
    case getArrayObjByLabel(STATIC_VARIABLE.benefitStatusArray, 'Active').value:
      className = "badge badge-success";
      text = "Active"
      break;
    case getArrayObjByLabel(STATIC_VARIABLE.benefitStatusArray, 'Suspend').value:
      className = "badge badge-danger";
      text = "Inactive"
      break;
    default:
      break;
  }
  let result = <span className={className}>{text}</span>
  return result;
}

export const setAccountStatusTag = (status) => {
  let className = "badge badge-secondary";
  let text = '';
  switch (status) {
    case getArrayObjByLabel(STATIC_VARIABLE.userStatusArray, 'Active').value:
      className = "badge badge-success";
      text = "Active"
      break;
    case getArrayObjByLabel(STATIC_VARIABLE.userStatusArray, 'Suspend').value:
      className = "badge badge-danger";
      text = "Suspend"
      break;
    case getArrayObjByLabel(STATIC_VARIABLE.userStatusArray, 'Unverified').value:
      className = "badge badge-warning";
      text = "Unverified"
      break;
    default:
      break;
  }
  let result = <span className={className}>{text}</span>
  return result;
}

export const setTierStatusTag = (status) => {
  let className = "badge badge-secondary";
  let text = '';
  switch (status) {
    case getArrayObjByLabel(STATIC_VARIABLE.levelStatusArray, 'Member').value:
      className = "badge badge-bronze";
      text = "Member"
      break;
    case getArrayObjByLabel(STATIC_VARIABLE.levelStatusArray, 'VIP').value:
      className = "badge badge-silverc";
      text = "VIP"
      break;
    case getArrayObjByLabel(STATIC_VARIABLE.levelStatusArray, 'VVIP').value:
      className = "badge badge-goldc";
      text = "VVIP"
      break;
    default:
      break;
  }
  let result = <span className={className}>{text}</span>
  return result;
}

export const setShopVerifyStatusTag = (status) => {
  let className = "badge badge-success";
  let text = '';
  switch (status) {
    case getArrayObjByLabel(STATIC_VARIABLE.shopStatusArray, 'Pending Verify').value:
      className = "badge badge-warning";
      text = "Pending Verify"
      break;
    case getArrayObjByLabel(STATIC_VARIABLE.shopStatusArray, 'Pending Payment').value:
      className = "badge badge-pending-payment";
      text = "Pending Payment"
      break;
    case getArrayObjByLabel(STATIC_VARIABLE.shopStatusArray, 'Processing').value:
      className = "badge badge-processing";
      text = "Processing"
      break;
    // case getArrayObjByLabel(STATIC_VARIABLE.shopStatusArray, 'Delivered').value:
    //   className = "badge badge-delivered";
    //   text = "Delivered"
    //   break;
    case getArrayObjByLabel(STATIC_VARIABLE.shopStatusArray, 'Completed').value:
      className = "badge badge-success";
      text = "Completed"
      break;
    case getArrayObjByLabel(STATIC_VARIABLE.shopStatusArray, 'Suspend').value:
      className = "badge badge-danger";
      text = "Suspend"
      break;
    default:
      break;
  }
  let result = <span className={className}>{text}</span>
  return result;
}

export const setStockStatusTag = (status) => {
  let className = "badge badge-warning";
  let text = '';
  switch (status) {
    case getArrayObjByLabel(STATIC_VARIABLE.stockStatusArray, 'Low Stock').value:
      className = "badge badge-warning";
      text = "Low Stock"
      break;
    case getArrayObjByLabel(STATIC_VARIABLE.stockStatusArray, 'OK').value:
      className = "badge badge-success";
      text = "OK"
      break;
    case getArrayObjByLabel(STATIC_VARIABLE.stockStatusArray, 'Out Of Stock').value:
      className = "badge badge-danger-red";
      text = "Out Of Stock"
      break;
    default:
      break;
  }
  let result = <span className={className}>{text}</span>
  return result;
}

export const setYesNoTag = (state: number) => {
  let className = "badge badge-danger";
  let text = '';

  switch (state) {
    case 1:
      className = "badge badge-success";
      text = "Yes"
      break;
    case 2:
      className = "badge badge-danger";
      text = "No"
      break;
    default:
      break;
  }
  return <span className={className}>{text}</span>;
}

export const setClaimStatusTag = (status) => {
  let className = "badge badge-warning";
  let text = '';
  switch (status) {
    case getArrayObjByLabel(STATIC_VARIABLE.claimStatusArray, 'Unclaimed').value:
      className = "badge badge-warning";
      text = "Unclaimed"
      break;
    case getArrayObjByLabel(STATIC_VARIABLE.claimStatusArray, 'Claimed').value:
      className = "badge badge-success";
      text = "Claimed"
      break;
    case getArrayObjByLabel(STATIC_VARIABLE.claimStatusArray, 'Cancel').value:
      className = "badge badge-danger";
      text = "Cancel"
      break;
    case getArrayObjByLabel(STATIC_VARIABLE.claimStatusArray, 'Suspend').value:
      className = "badge badge-danger";
      text = "Suspend"
      break;
    default:
      break;
  }
  let result = <span className={className}>{text}</span>
  return result;
}


export const setStatusTag = (status, text) => {
  let className = "badge badge-secondary";
  switch (status) {
    case TAG_ENUM.SUCCESS:
      className = "badge badge-secondary";
      text = "Activate"
      break;
    case TAG_ENUM.WARNING:
      className = "badge badge-warning";
      text = "Suspend"
      break;
    case TAG_ENUM.DANGER:
      className = "badge badge-danger";
      break;
    case TAG_ENUM.PRIMARY:
      className = "badge badge-primary";
      break;
    case TAG_ENUM.SECONDARY:
      className = "badge badge-secondary";
      break;
    case TAG_ENUM.INFO:
      className = "badge badge-info";
      break;
    case TAG_ENUM.LIGHT:
      className = "badge badge-light";
      break;
    default:
      break;
  }
  let result = <span className={className}>{text}</span>

  return result;
}

export const setPriceFormat = (price) => {
  if (typeof price == "string") {
    return 'err'
  }

  return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};
