import React, { Fragment, useCallback, useState } from "react";
import { useHistory } from "react-router";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useProps } from "../../contexts";
import { handleCreateAdminAPI } from "../../controller/admin";
import { setStateHelper } from "../../utils";



const TabsetUser = () => {
  const { _handleChange } = useProps();
  const history = useHistory();
  const [mainState, setMainState] = useState({
    username: "",
    firstName: "",
    lastName: "",
    email: '',
    password: '',
    confirmPassword: '',
    isSuperAdmin: false 
  });

  const _handleCreateAdmin = () => {
    handleCreateAdminAPI(mainState, _handleChange).then((e) => {
      if (e) {
        history.push('/admins/list-admin');
      }
    });
  };


  const FormDiv = useCallback(({value, label, type, id, disabled=false, setState}) => {
    let input =  <Input
      className="form-control col-xl-8 col-md-7"
      id={id}
      type={type}
      disabled={disabled}
      required=""
      value={value}
      onChange={(e) => {
        setStateHelper(setMainState)({[e.target.id]: e.target.value})
      }}
    />

    return (
      <FormGroup className="row">
        <Label className="col-xl-3 col-md-4">
         <span>*</span>{label}
        </Label>
        {input}
      </FormGroup>
    );
  },[])

	return (
		<Fragment>
			<Tabs>
				<TabList className="nav nav-tabs customize-filter-tab-header">
					<Tab className="nav-link">Account</Tab>
					<Tab className="nav-link">Permission</Tab>
				</TabList>
				<TabPanel>
     
					<Form className="needs-validation user-add" noValidate="">
						<h4>Account Details</h4>
            <FormDiv value={mainState.username} label={'Username'} id={'username'} type={'text'} />
            <FormDiv value={mainState.firstName} label={'First Name'} id={'firstName'} type={'text'} />
            <FormDiv value={mainState.lastName} label={'Last Name'} id={'lastName'} type={'text'} />
            <FormDiv value={mainState.email} label={'Email'} id={'email'} type={'email'} />
            <FormDiv value={mainState.password} label={'Password'} id={'password'} type={'text'} />
					</Form>
				</TabPanel>
				<TabPanel>
					<Form className="needs-validation user-add" noValidate="">
						<div className="permission-block">
							<div className="attribute-blocks">
								<h5 className="f-w-600 mb-3">Admin Permission </h5>
								<Row>
									<Col xl="9" sm="8">
										<FormGroup className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated" >
											<Label className="d-block">
												<Input
													className="radio_animated"
													id="edo-ani1"
													type="radio"
													name="rdo-ani"
                          checked={mainState.isSuperAdmin}
                          onChange={(e) => {
                            setStateHelper(setMainState)({isSuperAdmin: true})
                          }}
												/>
												Super Admin
											</Label>
											<Label className="d-block">
												<Input
													className="radio_animated"
													id="edo-ani2"
													type="radio"
													name="rdo-ani"
                          checked={!mainState.isSuperAdmin}
                          onChange={(e) => {
                            setStateHelper(setMainState)({isSuperAdmin: false})
                          }}
												/>
												Admin
											</Label>
										</FormGroup>
									</Col>
								</Row>
						  </div>
						</div>
					</Form>
				</TabPanel>
			</Tabs>
			<div className="pull-right">
				<Button type="button" color="primary" onClick={_handleCreateAdmin}>
					Submit
				</Button>
			</div>
		</Fragment>
	);
};

export default TabsetUser;
