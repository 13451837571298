import React, {useState} from "react";
import moment from "moment";
import { PDFViewer, Font, Page, Text, View, Image, Document, StyleSheet } from '@react-pdf/renderer';

import boldFont from '../../assets/fonts/AnyConv.com__Inter-Bold.ttf';
import regularFont from '../../assets/fonts/AnyConv.com__Inter-Regular.ttf';
import normalFont from '../../assets/fonts/AnyConv.com__Inter-Medium.ttf';
import extraBold from '../../assets/fonts/AnyConv.com__Inter-ExtraBold.ttf';

// Images
import Logo from "../../assets/images/vose-logo.png"

Font.register({ family: 'Inter-ExtraBold', src: extraBold });
Font.register({ family: 'Inter-Bold', src: boldFont });
Font.register({ family: 'Inter-Regular', src: regularFont });
Font.register({ family: 'Inter-Normal', src: normalFont });

const styles = StyleSheet.create({
  body: {
    padding: '40px',
  },
  image: {
    width: '150px',
  },
  noteText: {
    fontSize: 12,
    marginBottom: 5,
  },
  contentText: {
    fontSize: 12,
    marginBottom: 5,
    fontFamily: 'Inter-Normal',
  },
  titleText: {
    fontSize: 12,
    fontWeight: '900',
    marginBottom: 5,
    fontFamily: 'Inter-ExtraBold',
  },
  titleTextWidth: {
    fontSize: 12,
    fontWeight: '900',
    fontFamily: 'Inter-ExtraBold',
    width: '25%',
    textAlign: 'center',
    borderColor: 'gray',
    borderRightWidth: 1,
    padding: '15px 13px 13px 0',
  },
  telContentText: {
    fontSize: 12,
    fontFamily: 'Inter-Normal',
  },
  tableContent: {
    width: '80px',
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  contentTextWidth: {
    fontSize: 12,
    fontFamily: 'Inter-Normal',
    width: '25%',
    textAlign: 'center',
    borderColor: 'gray',
    borderRightWidth: 1,
    padding: '5px 0',
  },
  grid: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  gridItem: {
    width: '50%'
  },
  contentRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginBottom: 5,
  },
  rowLabel: {
    width: 80,
  },
  table: {
    marginTop: 10,
    paddingBottom: 0,
    // padding: '0px 10px',
    borderColor: 'gray',
    borderWidth: 1,
    // borderBottomWidth: 0,
  },
  marginRowHead: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    margin: 0,
    padding: '0 15px',
  },
  marginRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    margin: 0,
    padding: '0 15px',
  },
  paddingRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '0 15px 5px',
  },
  sectionProduct: {
    marginBottom: 10,
    borderColor: 'gray',
    borderBottomWidth: 1,
    borderTopWidth: 1,
  },
});


export default function ClaimPDF(props) {
  const { details } = props;
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    // <PDFViewer style={{ width: '100%', height: '100vh' }}>
    <Document
      onLoadSuccess={onDocumentLoadSuccess}
    >
      <Page size="A4" wrap={true} style={styles.body}>
        <View style={{
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          <Image
            style={styles.image}
            src={Logo}
          />
        </View>

        <View style={{
          margin: '30px 0 10px',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}>
          <View style={styles.flexColumn}>
            <View>
              <Text style={styles.titleText}>Claim Details: [{moment(details.createdAt).format('MMM YYYY')}]</Text>
            </View>
          </View>
        </View>


        {/* Basic Info */}
        <View style={styles.grid}>
          <View style={styles.gridItem}>

            <View style={styles.contentRow}>
              <View style={styles.rowLabel}>
                <Text style={styles.contentText}>Claim By</Text>
              </View>
              <View>
                <Text style={styles.contentText}>: #{details.claimDetails.memberID} {details.claimDetails.username} </Text>
              </View>
            </View>

            <View style={styles.contentRow}>
              <View style={styles.rowLabel}>
                <Text style={styles.contentText}>Phone</Text>
              </View>
              <View>
                <Text style={styles.contentText}>: {details.claimDetails.phoneNumber}</Text>
              </View>
            </View>

            <View style={styles.contentRow}>
              <View style={styles.rowLabel}>
                <Text style={styles.contentText}>Email</Text>
              </View>
              <View>
                <Text style={styles.contentText}>: {details.claimDetails.email}</Text>
              </View>
            </View>

          </View>

          <View style={styles.gridItem}>

            <View style={styles.contentRow}>
              <View style={styles.rowLabel}>
                <Text style={styles.contentText}>Claim Status</Text>
              </View>
              <View>
                <Text style={styles.contentText}>: {details.claimStatusLabel}</Text>
              </View>
            </View>

            <View style={styles.contentRow}>
              <View style={styles.rowLabel}>
                <Text style={styles.contentText}>Tier</Text>
              </View>
              <View>
                <Text style={styles.contentText}>: {details.levelLabel}</Text>
              </View>
            </View>

            <View style={styles.contentRow}>
              <View style={styles.rowLabel}>
                <Text style={styles.contentText}>Is Shop</Text>
              </View>
              <View>
                <Text style={styles.contentText}>: {details.shopLabel}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={{marginTop: '10px'}} />

        {/* Bank Details */}
        <View style={styles.grid}>
          <View style={styles.gridItem}>
            <View style={styles.contentRow}>
              <View style={styles.rowLabel}>
                <Text style={styles.contentText}>Bank Name</Text>
              </View>
              <View>
                <Text style={styles.contentText}>: {details.bank.name}</Text>
              </View>
            </View>

            <View style={styles.contentRow}>
              <View style={styles.rowLabel}>
                <Text style={styles.contentText}>Recipient Name</Text>
              </View>
              <View>
                <Text style={styles.contentText}>: {details.bank.recipientName}</Text>
              </View>
            </View>
          </View>

          <View style={styles.gridItem}>
            <View style={styles.contentRow}>
              <View style={styles.rowLabel}>
                <Text style={styles.contentText}>Bank Account</Text>
              </View>
              <View>
                <Text style={styles.contentText}>: {details.bank.account}</Text>
              </View>
            </View>

            <View style={styles.contentRow}>
              <View style={styles.rowLabel}>
                <Text style={styles.contentText}>IC</Text>
              </View>
              <View>
                {/*<Text style={styles.contentText}>: </Text>*/}
                {details.bank.icImg ?
                  <Image
                    style={{width: '130px'}}
                    src={details.bank.icImg}
                  />
                  : <Text style={styles.contentText}>: -</Text>
                }
              </View>
            </View>
          </View>
        </View>


        <View style={{marginTop: '40px'}} />
        <View>
          <Text style={styles.titleText}>Commission Details</Text>
        </View>

        <View style={styles.table}>
          <View style={styles.marginRowHead}>
            <Text style={{
              fontSize: 12,
              fontWeight: '900',
              fontFamily: 'Inter-ExtraBold',
              width: '15%',
              textAlign: 'left',
              borderColor: 'gray',
              borderRightWidth: 1,
              padding: '15px 0 13px',
            }} />
            <Text style={styles.titleTextWidth}>Total Point</Text>
            <Text style={styles.titleTextWidth}>Commission Rate</Text>
            <Text style={{
              fontSize: 12,
              fontWeight: '900',
              fontFamily: 'Inter-ExtraBold',
              width: '25%',
              textAlign: 'right',
              padding: '15px 0 13px',
            }}>Amount (RM)</Text>
          </View>

          <View style={styles.sectionProduct}>
            <View style={styles.marginRow}>
              <Text style={{
                fontSize: 12,
                fontFamily: 'Inter-Normal',
                width: '15%',
                textAlign: 'left',
                borderColor: 'gray',
                borderRightWidth: 1,
                padding: '5px 0',
              }}>1st Layer</Text>
              <Text style={styles.contentTextWidth}>{details.commissionDetails.firstLayer.point}</Text>
              <Text style={styles.contentTextWidth}>{details.commissionDetails.firstLayer.rate}%</Text>
              <View style={{
                width: '25%',
                padding: '5px 0',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}>
                <Text style={{
                  fontSize: 12,
                  fontFamily: 'Inter-Normal',
                  paddingLeft: '42px',
                }}>RM</Text>
                <Text style={{
                  fontSize: 12,
                  fontFamily: 'Inter-Normal',
                }}>
                  {details.commissionDetails.firstLayer.commission.toFixed(2)}
                </Text>
              </View>
            </View>

            <View style={styles.marginRow}>
              <Text style={{
                fontSize: 12,
                fontFamily: 'Inter-Normal',
                width: '15%',
                textAlign: 'left',
                borderColor: 'gray',
                borderRightWidth: 1,
                padding: '5px 0',
              }}>2nd Layer</Text>
              <Text style={styles.contentTextWidth}>{details.commissionDetails.secondLayer.point}</Text>
              <Text style={styles.contentTextWidth}>{details.commissionDetails.secondLayer.rate}%</Text>
              <View style={{
                width: '25%',
                padding: '5px 0',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}>
                <Text style={{
                  fontSize: 12,
                  fontFamily: 'Inter-Normal',
                  paddingLeft: '42px',
                }}>RM</Text>
                <Text style={{
                  fontSize: 12,
                  fontFamily: 'Inter-Normal',
                }}>
                  {details.commissionDetails.secondLayer.commission.toFixed(2)}
                </Text>
              </View>
            </View>

            <View style={styles.marginRow}>
              <Text style={{
                fontSize: 12,
                fontFamily: 'Inter-Normal',
                width: '15%',
                textAlign: 'left',
                borderColor: 'gray',
                borderRightWidth: 1,
                padding: '5px 0',
              }}>Shop</Text>
              <Text style={styles.contentTextWidth}>{details.commissionDetails.shopLayer.point}</Text>
              <Text style={styles.contentTextWidth}>{details.commissionDetails.shopLayer.rate}%</Text>
              <View style={{
                width: '25%',
                padding: '5px 0',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}>
                <Text style={{
                  fontSize: 12,
                  fontFamily: 'Inter-Normal',
                  paddingLeft: '42px',
                }}>RM</Text>
                <Text style={{
                  fontSize: 12,
                  fontFamily: 'Inter-Normal',
                }}>
                  {details.commissionDetails.shopLayer.commission === '-' ? '-' : details.commissionDetails.shopLayer.commission.toFixed(2)}
                </Text>
              </View>
            </View>

          </View>

          <View style={styles.paddingRow}>
            <Text style={styles.titleText}>(Total:)</Text>
            <View style={styles.tableContent}>
              <Text style={styles.titleText}>RM</Text>
              <Text style={styles.titleText}>{details.commissionDetails.totalCommission}</Text>
            </View>
          </View>

        </View>

      </Page>
    </Document>
    // </PDFViewer>
  )
}

