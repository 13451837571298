import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, FormGroup } from "reactstrap";
import { Tabs, TabList, Tab } from "react-tabs";
import { useProps } from "../../contexts";
//hooks
import useQuery from "../../hooks/useQuery";
//Component
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../common/datatable";
//Controller
import {setShopVerifyStatusTag, setStateHelper, setYesNoTag} from "../../utils";
import { handleGetShopVerifyListAPI, handleGetShopListAPI } from "../../controller/shop";
import SearchInput from "../common/search-input";
import { STATIC_VARIABLE } from "../../constants/static-data";

const generateBody = (body, page, rowsPerPage, handleOpen) => {
  return body.map((e, i) => ({
    id: <p style={{ fontWeight: "bold" }}>{(page - 1) * rowsPerPage + i + 1}.</p>,
    companyName: e.isBeautician === 1 ? <span style={{ color: 'rgb(117, 126, 101)' }}>{e.companyName}</span> : e.companyName,
    phoneNumber: e.phoneNumber,
    email: e.email,
    state: e.state,
    SSMID: e.SSMID,
    isBeautician: setYesNoTag(e.isBeautician),
    status: setShopVerifyStatusTag(e.status),
    edit: (
      <div>
        <a href={`/shop/verify-details-shop/${e.ID}`}>
          <i
            className="fa fa-pencil"
            style={{
              width: 35,
              fontSize: 20,
              padding: 11,
              color: "rgb(40, 167, 69)",
              cursor: "pointer",
            }}
          ></i>
        </a>
      </div>
    ),
  }));
};

const header = [
  { id: "id", label: "", align: "center" },
  { id: "companyName", label: "Shop Name", align: "left" },
  { id: "phoneNumber", label: "Shop Phone No.", align: "left" },
  { id: "email", label: "Email", align: "left" },
  { id: "state", label: "City", align: "left" },
  { id: "SSMID", label: "Company ID", align: "left" },
  { id: "isBeautician", label: "Beautician", align: "left" },
  { id: "status", label: "Status", align: "left" },
  { id: "edit", label: "", align: "center" },
];

const ShopVerifyList = () => {
  const [query, setQuery] = useQuery();
  const history = useHistory();
  const { _handleChange } = useProps();

  const [mainState, setMainState] = useState({
    list: [],
    page: query.page ? parseInt(query.page) : 1,
    limit: query.limit ? parseInt(query.limit) : 10,
    total: 10,
    order: query.order || "desc",
    orderBy: query.orderBy || "createdAt",
    shopStatus: query.shopStatus || 0,
    search: query.search || "",
    refreshData: false,
  });

  const handleOpenEdit = (ID) => {
    history.push(`/shop/verify-details-shop/${ID}`);
  };

  const _handleGetShopVerifyList = () => {
    const queryObj = {
      page: mainState.search ? 1 : mainState.page,
      limit: mainState.limit,
      order: mainState.order,
      orderBy: mainState.orderBy,
      search: mainState.search,
      shopStatus: mainState.shopStatus
    };
    const param = {
      ...queryObj,
      changeContext: _handleChange,
      setMainState: setStateHelper(setMainState),
    };
    setQuery(queryObj);
    handleGetShopVerifyListAPI(param);
  };

  const body = generateBody(
    mainState.list,
    mainState.page,
    mainState.limit,
    handleOpenEdit
  );

  const _handleChangeStatus = (e) => {
    let index = e;
    if (e > 3) {
      index = e + 1
    }
    setStateHelper(setMainState)({ shopStatus: index });
  };

  useEffect(() => {
    _handleGetShopVerifyList();
  }, [
    mainState.page,
    mainState.order,
    mainState.orderBy,
    mainState.limit,
    mainState.shopStatus,
    mainState.search,
    mainState.refreshData
  ]);

  return (
    <Fragment>
      <Breadcrumb title="Shop Verify List"/>
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <h5>List of Shop Verify</h5>
          </CardHeader>
          <CardBody>
            <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
            <Tabs
                defaultIndex={mainState.orderStatus}
                onSelect={(e) => {
                  _handleChangeStatus(e)
                }}>
              <TabList className="nav nav-tabs customize-filter-tab-header">
                  <Tab className="nav-link f-w-600">All</Tab>
                  {STATIC_VARIABLE.shopStatusArray.map((e) => {
                    if (e.value > 3) return
                    return <Tab className="nav-link f-w-600">{e.label}</Tab>;
                  })}
                </TabList>
                <Col xl="6">
                  <FormGroup className="row">
                    <SearchInput
                      onChange={(e) => {
                        setStateHelper(setMainState)({ search: e });
                      }}
                      value={mainState.search}
                    />
                  </FormGroup>
                </Col>
              <Datatable
                // multiSelectOption={true}
                multiSelectOption={false}
                header={header}
                myData={body}
                // myData={data}
                order={mainState.order}
                orderBy={mainState.orderBy}
                setState={setStateHelper(setMainState)}
                rowsPerPage={mainState.limit}
                count={mainState.total}
                page={mainState.page}
                total={mainState.total}
                pagination={true}
                class="-striped -highlight"
              />
            </Tabs>
            </div>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default ShopVerifyList;
