import React, {Fragment, useEffect, useRef, useState} from "react";
import Breadcrumb from "../common/breadcrumb";
import data from "../../assets/data/listCoupons";
import Datatable from "../common/datatable";
import { Card, CardBody, CardHeader, Col, Container, Row,Button } from "reactstrap";
import useQuery from "../../hooks/useQuery";
import { useProps } from "../../contexts";
import { useHistory } from "react-router-dom";
import {handleDeleteVoucherAPI, handleGetVoucherListAPI} from "../../controller/voucher";
import {setProductStatusTag} from "../../utils";
import DeletePopUp from "../common/modal/DeletePopUp";

const generateBody = (body, page, rowsPerPage, handleEdit, handleDelete = () => {}) => {
  return body.map((e, i) => ({
    id: <p style={{ fontWeight: "bold" }}>{(page - 1) * rowsPerPage + i + 1}.</p>,
    title: e.title,
    description: e.description,
    balance: e.balance,
    total: e.total,
    status: setProductStatusTag(e.status),
    createdAt: e.createdAt,
    expiredAt: e.expiredAt,
    edit: (
      <div>
        <span onClick={() => handleDelete(e.ID, e.title)}>
          <i
            className="fa fa-trash"
            style={{
              width: 35,
              fontSize: 20,
              padding: 11,
              color: "#e4566e",
              cursor: "pointer",
            }}
          />
        </span>

        <a href={`/voucher/create-voucher/${e.ID}`}>
          <i
            className="fa fa-pencil"
            style={{
              width: 35,
              fontSize: 20,
              padding: 11,
              color: "rgb(40, 167, 69)",
              cursor: "pointer",
            }}
          />
        </a>
      </div>
    ),
  }));
};

const header = [
  { id: "id", label: "", align: "center" },
  // { id: "photo", label: "Images", align: "center" },
  { id: "title", label: "Voucher Title", align: "left" },
  { id: "description", label: "Description", align: "left" },
  { id: "balance", label: "Balance", align: "center" },
  { id: "total", label: "Total Quantity", align: "center" },
  { id: "status", label: "Status", align: "center" },
  { id: "createdAt", label: "Created Date", align: "center" },
  { id: "expiredAt", label: "Expired Date", align: "center" },
  { id: "edit", label: "Action", align: "center" },
];

const ListVoucher = () => {
  const [query, setQuery] = useQuery();
  const { _handleChange } = useProps();
  const deleteProductPopUpRef = useRef();
  const [mainState, _setMainState] = useState({
    list: [],
    page: query.page ? parseInt(query.page) : 1,
    limit: query.limit ? parseInt(query.limit) : 10,
    total: 10,
    order: query.order || "desc",
    orderBy: query.orderBy || "date",
    search: "",
    status: 0,
    type: 0,
    refreshData: false,
  });
  const history = useHistory();

  const setMainState = (newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleRefreshData = () => {
    setMainState({ refreshData: !mainState.refreshData });
  };

  const handleEdit = (event, id) => {
    history.push(`/voucher/create-voucher/${id}`)
  };

  const handleOpenDeletePopUp = (id, itemName) => {
    deleteProductPopUpRef.current.setMainState({ isOpen: true, deleteID: id, itemName: itemName });
  }

  const handleDelete = (id) => {
    handleDeleteVoucherAPI(id, _handleChange).then((isSuccess) => {
      if (isSuccess) {
        handleRefreshData();
        deleteProductPopUpRef.current.handleClose();
      }
    })
  }

  const body = generateBody(mainState.list, mainState.page, mainState.limit, handleEdit, handleOpenDeletePopUp);

  const _handleGetVoucherList = () => {
    const queryObj = {
      page: mainState.search ? 1 : mainState.page,
      limit: mainState.limit,
      order: mainState.order,
      orderBy: mainState.orderBy,
      search: mainState.search,
      status: mainState.status,
      type: mainState.type,
    };
    const param = {
      ...queryObj,
      changeContext: _handleChange,
      setMainState: setMainState,
    };
    setQuery(queryObj);
    handleGetVoucherListAPI(param);
  };

  useEffect(() => {
    _handleGetVoucherList();
  }, [mainState.page, mainState.order, mainState.orderBy,mainState.status,mainState.refreshData]);

  return (
    <Fragment>
      <Breadcrumb title="List Voucher" parent="Vouchers" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>List Vouchers</h5>
              </CardHeader>
              <CardBody>
              <div className="btn-popup pull-right">
              {/* <Link to="/users/create-user" className="btn btn-secondary">
                Create User
              </Link> */}
              <Button type="button" color="primary" onClick={() =>history.push("/voucher/create-voucher")}>
                Create Voucher
              </Button>
            </div>
                <div id="batchDelete" className="category-table order-table coupon-list-delete">
                  <Datatable
                    // multiSelectOption={true}
                    multiSelectOption={false}
                    header={header}
                    myData={body}
                    // myData={data}
                    order={mainState.order}
                    orderBy={mainState.orderBy}
                    setState={setMainState}
                    rowsPerPage={mainState.limit}
                    count={mainState.total}
                    page={mainState.page}
                    total={mainState.total}
                    pagination={true}
                    class="-striped -highlight"
                  />
                  <DeletePopUp ref={deleteProductPopUpRef} title="Product" handleDelete={handleDelete} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ListVoucher;
