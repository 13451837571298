import React, { Fragment, useEffect, useRef, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, FormGroup, Row } from "reactstrap";
import useQuery from "../../hooks/useQuery";
import { useProps } from "../../contexts";
import { saveAs } from 'file-saver';
//Components
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../common/datatable";
import { Tabs, TabList, Tab } from "react-tabs";
import {
  handleGetShopOrderListAPI,
  handleGetShopOrderListExportAPI, handleGetUserOrderDetailsExportAPI,
} from "../../controller/order";
import { setOrderStatusTag, setPriceFormat, setStateHelper } from "../../utils";
import { useHistory } from "react-router";
import { STATIC_VARIABLE } from "../../constants/static-data";
import SearchInput from "../common/search-input";
import moment from "moment";
import { Grid, Autocomplete, TextField, Stack, IconButton } from "@mui/material";
import { handleGetAllUserListAPI } from "../../controller/user";
import DateFilter from "../common/datepicker";
import LoadingButton from "@mui/lab/LoadingButton";
import IosShareIcon from "@mui/icons-material/IosShare";
import ExcelShopOrderList from "../../export/excel/ExcelShopOrderList";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ShopReceiptPDF from "../../export/pdf/ShopOrderPDF";
import { pdf } from '@react-pdf/renderer';

const generateBody = (body, page, rowsPerPage, handleEdit, handleDownloadPDF) => {
  return body.map((e, i) => ({
    id: <p style={{ fontWeight: "bold" }}>{(page - 1) * rowsPerPage + i + 1}.</p>,
    ID: e.ID,
    username: e.userName ? (
      <a className={"customize-a"} href={`/users/details-user/${e.memberID}`}>
        #{e.memberID}-{e.userName}
      </a>
    ) : (
      "-"
    ),
    totalAmount: "RM " + setPriceFormat(e.totalAmount),
    orderStatus: setOrderStatusTag(e.orderStatus),
    createdAt: e.createdAt,
    edit: (
      <Stack direction='row' justifyContent='center' alignItems='center'>
        <a
        href={`/sales/orders-details-shop/${e.ID}`}
        >
          <i
            className="fa fa-pencil"
            style={{
              width: 35,
              fontSize: 20,
              padding: 11,
              color: "rgb(40, 167, 69)",
              cursor: "pointer",
            }}
          />
        </a>

        <IconButton onClick={handleDownloadPDF(e.ID)}>
          <PictureAsPdfIcon sx={{ fontSize: '18px' }} />
        </IconButton>
      </Stack>
    ),
  }));
};

const header = [
  { id: "id", label: "", align: "center" },
  { id: "ID", label: "Order Id", align: "center" },
  { id: "username", label: "User", align: "left" },
  { id: "totalAmount", label: "Total Price", align: "left" },
  { id: "orderStatus", label: "Order Status", align: "left" },
  { id: "createdAt", label: "Created Date", align: "center" },
  { id: "edit", label: "Action", align: "center" },
];

const ShopOrders = () => {
  const [query, setQuery] = useQuery();
  const history = useHistory();
  const { _handleChange } = useProps();
  const [user, setUser] = useState();
  const [userList, setAllUserList] = useState([]);
  const [excelFormat, setExcelFormat] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mainState, setMainState] = useState({
    list: [],
    page: query.page ? parseInt(query.page) : 1,
    limit: query.limit ? parseInt(query.limit) : 10,
    total: 10,
    order: query.order || "desc",
    orderBy: query.orderBy || "date",
    orderStatus: query.orderStatus || 0,
    search: query.search || "",
    status: 0,
    categoryID: 0,
    refreshData: false,
    startDate: moment().subtract("3", "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const handleExport = async () => {
    setLoading(true)

    const queryObj = {
      page: mainState.search ? 1 : mainState.page,
      limit: mainState.limit,
      order: mainState.order,
      orderBy: mainState.orderBy,
      search: mainState.search,
      orderStatus: mainState.orderStatus,
      startDate: mainState.startDate,
      endDate: mainState.endDate,
      memberID: user ? user.value : '',
      isAdminPurchase: 2,
      changeContext: _handleChange,
    };

    const { data, success } = await handleGetShopOrderListExportAPI(queryObj)

    if (success) {
      const targetID = document.getElementById('shop-order-export')
      setExcelFormat(data)
      setTimeout(function() {
        targetID.click()
      }, 1000)
    }

    setTimeout(function() {
      setLoading(false)
    }, 1000)
  }

  const setDownloadPDF = (ID: any) => async () => {
    const {success, data} = await  handleGetUserOrderDetailsExportAPI(ID, _handleChange)

    if (success) {
      const blob = await pdf((
        <ShopReceiptPDF details={data} paymentMethod='Billplz' />
      )).toBlob();

      saveAs(blob, `shop-order-${ID}.pdf`);
    }
  }

  const _handleGetShopOrderList = (isSearch=false) => {
    const queryObj = {
      page: isSearch ? 1 : mainState.page,
      limit: mainState.limit,
      order: mainState.order,
      orderBy: mainState.orderBy,
      search: isSearch ? isSearch : mainState.search,
      orderStatus: mainState.orderStatus,
      startDate: mainState.startDate,
      endDate: mainState.endDate,
      memberID: user ? user.value : '',
      isAdminPurchase: 2,
    };
    const param = {
      ...queryObj,
      changeContext: _handleChange,
      setMainState: setStateHelper(setMainState),
    };
    setQuery(queryObj);
    handleGetShopOrderListAPI(param);
  };

  const _handleOpenEdit = (orderID) => {
    history.push(`/sales/orders-details-shop/${orderID}`);
  };

  const _handleChangeStatus = (e) => {
    setStateHelper(setMainState)({ orderStatus: e });
  };

  const handleOnChangeSearch = (e) => {
    setStateHelper(setMainState)({ search: e });
    _handleGetShopOrderList(e)
  }

  const _handleGetAllUser = () => {
    const param = {
      changeContext: _handleChange,
      setMainState: setAllUserList,
    };
    handleGetAllUserListAPI(param);
  };

  useEffect(() => {
    _handleGetShopOrderList();
    _handleGetAllUser();
  }, [
    mainState.page,
    mainState.order,
    mainState.orderBy,
    mainState.limit,
    mainState.orderStatus,
    mainState.search,
    mainState.refreshData,
    mainState.startDate,
    mainState.endDate,
    user,
  ]);
  const body = generateBody(mainState.list, mainState.page, mainState.limit, _handleOpenEdit, setDownloadPDF);

  return (
    <Fragment>
      <Breadcrumb title="Shop Orders" parent="Sales" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Stack alignItems="flex-end" sx={{ width: '100%', mb: 2 }}>
                  <LoadingButton
                    onClick={handleExport}
                    startIcon={<IosShareIcon />}
                    loading={loading}
                    loadingPosition="center"
                    variant="outlined"
                  >
                    Export
                  </LoadingButton>
                </Stack>

                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid items sx={12} md={6}>
                    <h5>List of Shop Orders</h5>
                  </Grid>
                  <Grid items sx={12} md={6}>
                    <Stack alignItems="flex-end">
                      <SearchInput
                        onChange={handleOnChangeSearch}
                        value={mainState.search}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </CardHeader>
              <CardBody className="order-datatable">
                <Tabs
                  defaultIndex={mainState.orderStatus}
                  onSelect={(e) => {
                    _handleChangeStatus(e);
                  }}
                >
                  <TabList className="nav nav-tabs customize-filter-tab-header">
                    <Tab className="nav-link f-w-600">All</Tab>
                    {STATIC_VARIABLE.orderStatusArray.map((e) => {
                      return <Tab className="nav-link f-w-600">{e.label}</Tab>;
                    })}
                  </TabList>
                  <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
                    <Grid items sx={6}>
                      <Autocomplete
                        disablePortal
                        value={user}
                        id="combo-box-demo"
                        onChange={(event, newValue) => {
                          setUser(newValue);
                        }}
                        options={userList}
                        sx={{
                          width: 250,
                          ".MuiInputLabel-root": {
                            fontSize: 14,
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            InputProps={{
                              style: {
                                fontSize: 12,
                              },
                            }}
                            {...params}
                            label="Search User Member Line Order"
                            />
                        )}
                      />
                    </Grid>
                    <Grid items sx={6}>
                      <DateFilter
                        startDate={mainState.startDate}
                        endDate={mainState.endDate}
                        handleSearch={(startDate, endDate) => {
                          const _startDate = moment(startDate).format("YYYY-MM-DD");
                          const _endDate = moment(endDate).format("YYYY-MM-DD");
                          setStateHelper(setMainState)({ startDate: _startDate, endDate: _endDate });
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Datatable
                    multiSelectOption={false}
                    header={header}
                    myData={body}
                    order={mainState.order}
                    orderBy={mainState.orderBy}
                    setState={setStateHelper(setMainState)}
                    rowsPerPage={mainState.limit}
                    count={mainState.total}
                    page={mainState.page}
                    total={mainState.total}
                    pagination={true}
                    class="-striped -highlight"
                  />
                </Tabs>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ExcelShopOrderList items={excelFormat}/>
    </Fragment>
  );
};

export default ShopOrders;
