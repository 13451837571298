import {apiMiddleware} from "../../utils/middleware";
import {getDashboard} from "../../services/dashboard";


const contextParams = {
  snackbar: true,
  snackbarType: 1,
  snackbarContent: "",
};

export const handleGetDashboardAPI = async ({ setMainState, changeContext, }) => {
  const {data, success} = await apiMiddleware(changeContext, getDashboard);

  if (success) {
    const orderOnline = {
      chart: data.chartOrderOnline.map(e => [e.state, e.total]),
      list: data.chartOrderOnline.map(e => ({state: e.state, total: e.total}))
    }
    const orderLocal = {
      chart: data.chartOrderLocal.map(e => [e.state, e.total]),
      list: data.chartOrderLocal.map(e => ({state: e.state, total: e.total}))
    }

    orderOnline.chart.unshift(["Task", "Hours per Day"])
    orderLocal.chart.unshift(["Task", "Hours per Day"])
    // orderLocal.push(['Johor', '123'])

    setMainState({
      totals: data.totals,
      orderOnline, orderLocal,
      lineChartData: data.weekRevenue,
      topMember: data.topMember,
      claimList: data.unClaimList,
      orderList: data.orderList,
      shopList: data.shopList,
    })
  }
  return success;
}
