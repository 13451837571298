import React from 'react';
import {useDropzone} from 'react-dropzone';

const CustomDropzone = ({handleGetFile}) => {
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    // Note how this callback is never invoked if drop occurs on the inner dropzone
    onDrop: files => handleGetFile(files),
    accept: "image/*",
  });
  
  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path}
    </li>
  ));

  return (
    <section className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <div className={'customize-divider'}/>
      <aside>
        <h6>Uploaded Files:</h6>
        <ul>{files}</ul>
      </aside>
    </section>
  );
}


export default CustomDropzone;
