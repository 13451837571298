import fetchData from "../../utils/fetchData";

export function createProduct(body) {
  return fetchData({
    url: "/products",
    method: "POST",
    data: body,
  });
}

export function getProductList(params) {
  return fetchData({
    url: "/products",
    method: "GET",
    params: params,
  });
}

export function getProduct(productID) {
  return fetchData({
    url: `/products/${productID}`,
    method: "GET",
  });
}

export function updateProduct(productID, body) {
  return fetchData({
    url: `/products/${productID}`,
    method: "PUT",
    data: body,
  });
}

export function deleteProduct(productID) {
  return fetchData({
    url: `/products/${productID}`,
    method: "DELETE",
  });
}

export function uploadImage(body) {
  return fetchData({
    url: `/products/upload-photo`,
    method: "POST",
    data: body,
    header: {
      "Content-Type": "multipart/form-data",
    },
  });
}
