import React, { Fragment, useState, useEffect } from "react";
import SearchHeader from "./searchHeader";
import Notification from "./notification";
import UserMenu from "./user-menu";
import Language from "./language";
import { AlignLeft, Maximize2, Bell, MessageSquare, MoreHorizontal } from "react-feather";

//images
import logo from "../../../assets/images/dashboard/VOSE.png";

const Header = () => {
  const [sidebar, setSidebar] = useState(true);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [navMenus, setNavMenus] = useState(false);

  const toggle = () => {
    setNavMenus((prevState) => !prevState);
  };

  const openCloseSidebar = () => {
    if (sidebar) {
      setSidebar(false);
      document.querySelector(".page-main-header").classList.add("open");
      document.querySelector(".page-sidebar").classList.add("open");
    } else {
      setSidebar(true);
      document.querySelector(".page-main-header").classList.remove("open");
      document.querySelector(".page-sidebar").classList.remove("open");
    }
  };

	return (
		<Fragment>
			{/* open */}
			<div className="page-main-header ">
				<div className="main-header-right row">
					<div className="main-header-left d-lg-none">
						<div className="logo-wrapper">
							<a href={`${process.env.PUBLIC_URL}/dashboard`}>
								<img className="blur-up lazyloaded" src={logo} alt="" />
							</a>
						</div>
					</div>
					<div className="mobile-sidebar">
						<div className="media-body text-right switch-sm">
							<label className="switch">
								<a href="#javaScript" onClick={openCloseSidebar}>
									<AlignLeft />
								</a>
							</label>
						</div>
					</div>
					<div className="nav-right col">
						<ul className={"nav-menus " + (navMenus ? "open" : "")}>
							{/* <li>
								<SearchHeader />
							</li> */}
							{/* <li>
								<a className="text-dark" href="#javaScript">
									<Maximize2 />s
								</a>
							</li> */}
							{/* <li className="onhover-dropdown">
								<a className="txt-dark" href="#javaScript">
									<h6>EN</h6>
								</a>
								<Language />
							</li> */}

							{/* <li className="onhover-dropdown">
								<Bell />
								<span className="badge badge-pill badge-primary pull-right notification-badge">
									3
								</span>
								<span className="dot"></span>
								<Notification />
							</li> */}
              {/* <li>
								<a href="#javaScript" onClick={showRightSidebar}>
									<MessageSquare />
									<span className="dot"></span>
								</a>
							</li> */}
              <UserMenu />
            </ul>
            <div className="d-lg-none mobile-toggle pull-right" onClick={() => toggle()}>
              <MoreHorizontal />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Header;
