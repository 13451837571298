export default function useCheckIsValidation(validateArr, handleChange) {
  let errMassage = "";
  try {
    validateArr.forEach((i) => {
      if (i) {
        errMassage = i;
        return;
      }
    });
  } catch (err) {
    return;
  }

  if (errMassage) {
    let contextParams = {
      snackbar: true,
      snackbarType: 1,
      snackbarContent: errMassage,
    };
    handleChange(contextParams);
    return false;
  } else {
    return true;
  }
}
