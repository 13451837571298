import fetchData from "../../utils/fetchData";

export function getConfigs() {
  return fetchData({
    url: "/generals/leve-config",
    method: "GET",
  });
}

export function updateConfig(body) {
  return fetchData({
    url: "/generals/leve-config",
    method: "PUT",
    data: body,
  });
}
