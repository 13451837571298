import React, { useEffect, useState } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { User, Settings } from "react-feather";
import { Button, Col, Input, Label, Row, Table } from "reactstrap";

const TabsetProfile = (props) => {
  const { data } = props;

  return (
    <div>
      <Tabs>
        <TabList className="nav nav-tabs tab-coupon">
          <Tab className="nav-link">
            <User className="mr-2" />
            Profile
          </Tab>
          {/* <Tab className="nav-link">
            <Settings className="mr-2" />
            Contact
          </Tab> */}
        </TabList>

        <TabPanel>
          <div className="tab-pane fade show active">
            <h5 className="f-w-600 f-16">Profile</h5>
            <div className="table-responsive profile-table">
              <Table className="table-responsive">
                <tbody>
                  <tr>
                    <td>Username:</td>
                    <td>
                      <Input
                        type="text"
                        name="username"
                        value={data.username}
                        onChange={(e) => props.handleTextInput(e, "username")}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>First Name:</td>
                    <td>
                      <Input
                        type="text"
                        name="firstName"
                        value={data.firstName}
                        onChange={(e) => props.handleTextInput(e, "firstName")}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Last Name:</td>
                    <td>
                      <Input
                        type="text"
                        name="lastName"
                        value={data.lastName}
                        onChange={(e) => props.handleTextInput(e, "lastName")}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Email:</td>
                    <td>
                      <Input
                        type="text"
                        name="email"
                        value={data.email}
                        onChange={(e) => props.handleTextInput(e, "email")}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Button
                type="button"
                color="primary"
                onClick={props.handleSubmit}
              >
                Update
              </Button>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="account-setting deactivate-account">
            <h5 className="f-w-600 f-16">Deactivate Account</h5>
            <Row>
              <Col>
                <Label className="d-block">
                  <Input className="radio_animated" id="edo-ani" type="radio" name="rdo-ani" defaultChecked />
                  I have a privacy concern
                </Label>
                <Label className="d-block">
                  <Input className="radio_animated" id="edo-ani1" type="radio" name="rdo-ani" />
                  This is temporary
                </Label>
                <Label className="d-block mb-0">
                  <Input
                    className="radio_animated"
                    id="edo-ani2"
                    type="radio"
                    name="rdo-ani"
                    defaultChecked
                  />
                  Other
                </Label>
              </Col>
            </Row>
            <Button type="button" color="primary">
              Deactivate Account
            </Button>
          </div>
          <div className="account-setting deactivate-account">
            <h5 className="f-w-600 f-16">Delete Account</h5>
            <Row>
              <Col>
                <Label className="d-block">
                  <Input
                    className="radio_animated"
                    id="edo-ani3"
                    type="radio"
                    name="rdo-ani1"
                    defaultChecked
                  />
                  No longer usable
                </Label>
                <Label className="d-block">
                  <Input className="radio_animated" id="edo-ani4" type="radio" name="rdo-ani1" />
                  Want to switch on other account
                </Label>
                <Label className="d-block mb-0">
                  <Input
                    className="radio_animated"
                    id="edo-ani5"
                    type="radio"
                    name="rdo-ani1"
                    defaultChecked
                  />
                  Other
                </Label>
              </Col>
            </Row>
            <Button type="button" color="primary">
              Delete Account
            </Button>
          </div>
          {/* </div> */}
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default TabsetProfile;
