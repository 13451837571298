import {apiMiddleware} from "../../utils/middleware";
import {getConfigs, updateConfig} from "../../services/configs";
import {isEmpty} from "../../utils/validation";
import useCheckIsValidation from "../../hooks/useCheckIsValidation";
import moment from "moment";

const contextParams = {
  snackbar: true,
  snackbarType: 1,
  snackbarContent: "",
};

export const handleGetConfigAPI = async ({ setMainState, changeContext, }) => {
  const {data, success} = await apiMiddleware(changeContext, getConfigs);

  if (success) {
    const general = data.general;
    const memberLevel = data.level.member;
    const vipLevel = data.level.vip;
    const vvipLevel = data.level.vvip;
    const shop = data.level.shop;

    const setData = {
      adminCode: general.adminCode,
      coinRate: general.coinRate,
      pvToMoney: general.pvMoneyRate,
      minMemberPoint: general.minMemberPoint,
      minShopPoint: general.minShopPoint,
      memberFee: general.joinMemberFee,
      checkedMemberFee: !!general.freeMemberDate.startDate,
      startDate: general.freeMemberDate.startDate ? general.freeMemberDate.startDate : moment().format("YYYY-MM-DD"),
      endDate: general.freeMemberDate.endDate ? general.freeMemberDate.endDate : moment().format("YYYY-MM-DD"),
      freeMalaysia: general.freeMalaysia === 1,
      freeEastMalaysia: general.freeEastMalaysia === 1,
      freeSingapore: general.freeSingapore === 1,
      memberMinCommission: memberLevel.pvMinRate,
      memberMaxCommission: memberLevel.pvMaxRate,
      memberTargetPV: memberLevel.pvTarget,
      memberCashback: memberLevel.coinPercent,
      memberMissionDay: memberLevel.upgradeDay,
      memberUpgradeRequire: memberLevel.upgradeMember,
      vipMinCommission: vipLevel.pvMinRate,
      vipMaxCommission: vipLevel.pvMaxRate,
      vipTargetPV: vipLevel.pvTarget,
      vipCashback: vipLevel.coinPercent,
      vipMaintainPV: vipLevel.maintainPV,
      vipUpgradeRequire: vipLevel.upgradeMember,
      vipMissionDirect: vipLevel.upgradeDirect,
      vipGroupSales: vipLevel.groupTarget,
      vipGroupBonus: vipLevel.groupBonus,
      vvipMinCommission: vvipLevel.pvMinRate,
      vvipMaxCommission: vvipLevel.pvMaxRate,
      vvipTargetPV: vvipLevel.pvTarget,
      vvipSecondMinCommission: vvipLevel.pvChildMinRate,
      vvipSecondMaxCommission: vvipLevel.pvChildMaxRate,
      vvipSecondTargetPV: vvipLevel.pvChildTarget,
      vvipCashback: vvipLevel.coinPercent,
      vvipMaintainPV: vvipLevel.maintainPV,
      vvipMissionDirect: vvipLevel.upgradeDirect,
      vvipGroupSales: vvipLevel.groupTarget,
      vvipGroupBonus: vvipLevel.groupBonus,
      shopMinCommission: shop.pvMinRate,
      shopMaxCommission: shop.pvMaxRate,
      shopTargetPV: shop.pvTarget,
      shopMaintainPV: shop.maintainPV,
    }

    setMainState(setData)
  }

  return success;
}

export const handleUpdateAPI = async (mainState, changeContext) => {
  const freeMemberDate = mainState.checkedMemberFee
    ? `${mainState.startDate}, ${mainState.endDate}`
    : ''

  const body = {
    general: {
      adminCode: mainState.adminCode,
      coinRate: mainState.coinRate,
      pvMoneyRate: mainState.pvToMoney,
      minMemberPoint: mainState.minMemberPoint,
      minShopPoint: mainState.minShopPoint,
      joinMemberFee: mainState.memberFee,
      freeMemberDate,
      freeMalaysia: mainState.freeMalaysia,
      freeEastMalaysia: mainState.freeEastMalaysia,
      freeSingapore: mainState.freeSingapore,
    },
    member: {
      pvMinRate: mainState.memberMinCommission,
      pvMaxRate: mainState.memberMaxCommission,
      pvTarget: mainState.memberTargetPV,
      coinPercent: mainState.memberCashback,
      upgradeDay: mainState.memberMissionDay,
      upgradeMember: mainState.memberUpgradeRequire
    },
    vip: {
      pvMinRate: mainState.vipMinCommission,
      pvMaxRate: mainState.vipMaxCommission,
      pvTarget: mainState.vipTargetPV,
      coinPercent: mainState.vipCashback,
      maintainPV: mainState.vipMaintainPV,
      upgradeMember: mainState.vipUpgradeRequire,
      upgradeDirect: mainState.vipMissionDirect,
      groupSales: mainState.vipGroupSales,
      groupBonus: mainState.vipGroupBonus,
    },
    vvip: {
      pvMinRate: mainState.vvipMinCommission,
      pvMaxRate: mainState.vvipMaxCommission,
      pvTarget: mainState.vvipTargetPV,
      pvChildMinRate: mainState.vvipSecondMinCommission,
      pvChildMaxRate: mainState.vvipSecondMaxCommission,
      pvChildTarget: mainState.vvipSecondTargetPV,
      coinPercent: mainState.vvipCashback,
      maintainPV: mainState.vvipMaintainPV,
      upgradeDirect: mainState.vvipMissionDirect,
      groupSales: mainState.vvipGroupSales,
      groupBonus: mainState.vvipGroupBonus,
    },
    shop: {
      pvMinRate: mainState.shopMinCommission,
      pvMaxRate: mainState.shopMaxCommission,
      pvTarget: mainState.shopTargetPV,
      maintainPV: mainState.shopMaintainPV
    }
  }

  const verifyArr = [
    isEmpty(body.general.coinRate, "Coin Rate"),
    isEmpty(body.general.pvMoneyRate, "PV Point To Money Rate"),
    isEmpty(body.general.minMemberPoint, "Minimum Join Member PV Point"),
    isEmpty(body.general.minShopPoint, "Minimum Join Shop PV Point"),
    isEmpty(body.general.joinMemberFee, "Member Fee"),
    isEmpty(body.member.coinPercent, "Member Cashback"),
    isEmpty(body.member.upgradeDay, "Member Upgrade Mission Day"),
    isEmpty(body.member.upgradeMember, "Member Require Upgrade Member"),
    isEmpty(body.vip.pvMinRate, "VIP Min Commission"),
    isEmpty(body.vip.pvMaxRate, "VIP Max Commission"),
    isEmpty(body.vip.pvTarget, "VIP Commission Target"),
    isEmpty(body.vip.coinPercent, "VIP Cashback"),
    isEmpty(body.vip.maintainPV, "VIP Monthly Maintain PV"),
    isEmpty(body.vip.upgradeMember, "VIP Require Upgrade VIP"),
    isEmpty(body.vip.upgradeDirect, "VIP Direct Account Target"),
    isEmpty(body.vvip.pvMinRate, "VVIP Min Commission"),
    isEmpty(body.vvip.pvMaxRate, "VVIP Max Commission"),
    isEmpty(body.vvip.pvTarget, "VVIP Commission Target"),
    isEmpty(body.vvip.pvChildMinRate, "VVIP 2nd Min Commission"),
    isEmpty(body.vvip.pvChildMaxRate, "VVIP 2nd Max Commission"),
    isEmpty(body.vvip.pvChildTarget, "VVIP 2nd Commission Target"),
    isEmpty(body.vvip.coinPercent, "VVIP Cashback"),
    isEmpty(body.vvip.maintainPV, "VVIP Monthly Maintain PV"),
    isEmpty(body.vvip.upgradeDirect, "VVIP Direct Account Target"),
    isEmpty(body.shop.pvMinRate, "Shop Min Commission"),
    isEmpty(body.shop.pvMaxRate, "Shop Max Commission"),
    isEmpty(body.shop.pvTarget, "Shop Commission Target"),
    isEmpty(body.shop.maintainPV, "Shop Monthly Maintain PV"),
  ];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isValidate = useCheckIsValidation(verifyArr.reverse(), changeContext);
  if (!isValidate) return false;

  const { success } = await apiMiddleware(changeContext, updateConfig, body)
  if (success) {
    contextParams.snackbarType = 0;
    contextParams.snackbarContent = `Update Successful!`;
  }
  changeContext(contextParams);
  return success;
}
