import React, { Fragment, useState, useEffect, useRef } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useProps } from "../../contexts";
import { handleGetUserDetailsAPI, handleUpdateUserAPI } from "../../controller/user";
import useQuery from "../../hooks/useQuery";
import { getArrayObjByLabel, setBenefitStatusTag, setStateHelper, setYesNoTag } from "../../utils";
import SSMModal from "../common/modal/ssm-modal";
import { Stack } from "@mui/material";
import { useHistory } from "react-router-dom";
import { STATIC_VARIABLE } from "../../constants/static-data";

const TabsetUser = ({ userID }) => {
  const [query, setQuery] = useQuery();
  const history = useHistory();
  const { _handleChange } = useProps();
  const ssmModalRef = useRef(null);
  const [profileMainState, setProfileMainState] = useState({
    tabIndex: 0,
    ID: 1,
    username: null,
    firstName: "",
    lastName: "",
    icNumber: null,
    email: "",
    phoneNumber: "",
    birthday: "",
    totalSpend: 0,
    points: 0,
    totalPoints: 0,
    shopPoints: 0,
    totalShopPoints: 0,
    coins: 0,
    totalCoin: 0,
    level: 1,
    pureLevel: 1,
    bank: "",
    bankNo: "",
    bankName: "",
    icImg: "",
    maintainTarget: {
      monthPV: 0,
      monthShopPV: 0,
      maintainPV: 0,
      status: 1,
      shopMaintainPV: 0,
      shopStatus: 1,
    },
    status: 1,
    type: 1,
    isShop: 2,
    isBeautician: 2,
    verifyEmail: 2,
    createdAt: "",
    levelUpAt: "",
    inviteBy: "",
    ReferralCode: "",
    companyName: "",
    companyEmail: "",
    companyPhone: "",
    shopID: "",
    shopCode: "",
    SSM: "",
    shopAddress: "",
  });

  const _handleGetUserDetails = () => {
    handleGetUserDetailsAPI(setStateHelper(setProfileMainState), userID, _handleChange);
  };

  const _handleChangeInput = (e) => {
    if(e){
      setStateHelper(setProfileMainState)({[e.target.id]: e.target.value})
    }
  }

  const _handleUpdateUserAPI = () => {
    handleUpdateUserAPI(
      profileMainState.username,
      getArrayObjByLabel(STATIC_VARIABLE.userStatusArray, profileMainState.status).value,
      userID,
      _handleChange,
    )
  };

  useEffect(() => {
    switch (parseInt(profileMainState.tabIndex)) {
      case 0:
        _handleGetUserDetails();
        break;
      case 1:
        _handleGetUserDetails();
        break;
      default:
        break;
    }
  }, []);

  const FormDiv = ({ label, type, id, disabled = false, handleChange=null }) => {
    let value = profileMainState[id] != null ? profileMainState[id] : "-";
    if (id === "address") {
      value = _addressCombine();
    }
    let input = (
      <Input
        className="form-control col-xl-8 col-md-7"
        id={id}
        type={type}
        disabled={disabled}
        required=""
        value={value}
        onChange={handleChange}
      />
    );
    if (type === "link") {
      let _href = "#";

      if (id === "inviteBy") {
        value = profileMainState.inviteByID
          ? "#" + profileMainState.inviteByID + "-" + profileMainState.inviteBy
          : "-";
        _href = profileMainState.inviteByID ? `/users/details-user/${profileMainState.inviteByID}` : "#";
      }

      if (id === "SSM") {
        value = profileMainState.shopCode;
        _href = profileMainState.shopID ? `/shop/details-shop/${profileMainState.shopID}` : "#";
      }

      input = (
        <a className={"customize-a SSMID-padding"} href={_href}>
          {value}
        </a>
      );
      if (value == "-") {
        input = <div className="empty-label">{value}</div>;
      }
    }

    return (
      <FormGroup className="row">
        <Label className="col-xl-3 col-md-4 col-6">
          <span>*</span> {label}
        </Label>
        {input}
      </FormGroup>
    );
  };

  const _addressCombine = () => {
    let address1 = profileMainState.address1 ? profileMainState.address1 + "," : "";
    let address2 = profileMainState.address2 ? profileMainState.address2 + "," : "";
    let address =
      address1 +
      address2 +
      "\n" +
      profileMainState.postalCode +
      " " +
      profileMainState.city +
      " " +
      profileMainState.state?.name +
      " " +
      profileMainState.country;
    return address;
  };

  return (
    <Fragment>
      <Tabs
        defaultIndex={profileMainState.tabIndex}
        onSelect={(e) => {
          setStateHelper(setProfileMainState)({ tabIndex: e });
        }}
      >
        <TabList className="nav nav-tabs customize-filter-tab-header">
          <Tab className="nav-link">Profile</Tab>
          {/* <Tab className="nav-link">Shop</Tab> */}
          {profileMainState.isShop == 1 && <Tab className="nav-link">Shop</Tab>}
        </TabList>
        <TabPanel>
          <Form className="needs-validation user-add" noValidate="">
            <Row>
              <Col xl="6">
                <FormGroup className="row">
                  <Label className="col-xl-3 col-md-4 col-6">
                    <span>*</span> Username
                  </Label>
                <Input
                  className="form-control col-xl-8 col-md-7"
                  id={'username'}
                  type={'text'}
                  required=""
                  value={profileMainState['username'] != null ? profileMainState['username'] : "-"}
                  onChange={_handleChangeInput}
                />
                </FormGroup>
                <FormDiv label={"First Name"} id={"firstName"} type={"text"} disabled={true} />
                <FormDiv label={"Last Name"} id={"lastName"} type={"text"} disabled={true} />
                <FormDiv label={"Email"} id={"email"} type={"email"} disabled={true} />
                <FormDiv label={"IC Number"} id={"icNumber"} type={"text"} disabled={true} />
                <FormDiv label={"Phone"} id={"phoneNumber"} type={"phone"} disabled={true} />
              </Col>
              <Col xl="6">
                <FormDiv label={"Birthday"} id={"birthday"} type={"date"} disabled={true} />
                <FormDiv label={"Tier"} id={"level"} type={"text"} disabled={true} />
                <FormDiv label={"Current Total Point"} id={"points"} type={"text"} disabled={true} />
                <FormGroup className="row">
                  <Label className="col-xl-3 col-md-4 col-6">
                    <span>*</span> First Line Point
                  </Label>
                  <Stack
                    className="form-control col-xl-8 col-md-7"
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <div>{profileMainState.totalFirstLinePoint}</div>
                    {profileMainState.firstLineActive ? setBenefitStatusTag(1) : setBenefitStatusTag(2)}
                  </Stack>
                </FormGroup>
                <FormGroup className="row">
                  <Label className="col-xl-3 col-md-4 col-6">
                    <span>*</span> Second Line Point
                  </Label>
                  <Stack
                    className="form-control col-xl-8 col-md-7"
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <div>{profileMainState.totalSecondLinePoint}</div>
                    {profileMainState.secondLineActive ? setBenefitStatusTag(1) : setBenefitStatusTag(2)}
                  </Stack>
                </FormGroup>
                <FormDiv label={"Shopping Dollar"} id={"coins"} type={"text"} disabled={true} />
              </Col>
            </Row>
          </Form>
          <Form className="needs-validation user-add" noValidate="">
            <Row>
              <Col xl="6">
                <FormDiv label={"Referral Code"} id={"ReferralCode"} type={"text"} disabled={true} />
                <FormDiv label={"Refer By"} id={"inviteBy"} type={"link"} disabled={true} />
              </Col>
              <Col xl="6">
                {/* {profileMainState.isShop === 2 && */}
                <FormGroup className="row">
                  <Label className="col-xl-3 col-md-4 col-6">
                    <span>*</span> Benefit Status
                  </Label>
                  <Stack
                    className="form-control col-xl-8 col-md-7"
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <div>
                      {profileMainState.pureLevel === 1
                        ? "-"
                        : `${
                            profileMainState.isShop === 2
                              ? profileMainState.maintainTarget.monthPV
                              : profileMainState.points > profileMainState.maintainTarget.maintainPV
                              ? profileMainState.maintainTarget.maintainPV
                              : "0"
                          } / ${profileMainState.maintainTarget.maintainPV}`}
                    </div>
                    {profileMainState.isShop === 2
                      ? setBenefitStatusTag(profileMainState.maintainTarget.status)
                      : profileMainState.points > profileMainState.maintainTarget.maintainPV
                      ? setBenefitStatusTag(1)
                      : setBenefitStatusTag(2)}
                  </Stack>
                </FormGroup>
                {/* <p style={{ height: "18px", marginTop: "-18px" , color: 'red'}}>
                Noted: If benefit status "Active" will extra add 25 point. (Only applied at SHOP ACCOUNT)
                </p> */}
                {/* } */}

                <FormDiv label={"Account Status"} id={"status"} type={"text"} disabled={true} />
              </Col>
            </Row>
          </Form>
          <Form className="needs-validation user-add" noValidate="">
            <Row>
              <Col xl="6">
                <FormDiv label={"Address"} id={"address"} type={"textarea"} disabled={true} />
              </Col>
            </Row>
          </Form>

          <Form className="needs-validation user-add" noValidate="">
            <Row>
              <Col xl="6">
                <FormDiv label={"Bank"} id={"bank"} type={"text"} disabled={true} />
                <FormDiv label={"Bank Account"} id={"bankNo"} type={"text"} disabled={true} />
              </Col>

              <Col xl="6">
                <FormDiv label={"Bank Name"} id={"bankName"} type={"text"} disabled={true} />
                <FormGroup className="row">
                  <Label className="col-xl-3 col-md-4 col-6" style={{ height: "18px" }}>
                    IC Image
                  </Label>
                  {profileMainState.icImg ? (
                    <img
                      className="col-xl-8 col-md-7"
                      src={profileMainState.icImg}
                      style={{ maxWidth: 180 }}
                    />
                  ) : (
                    <p style={{ height: "18px", paddingTop: "2px" }}>No Upload IC</p>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </TabPanel>
        <TabPanel>
          <Form className="needs-validation user-add" noValidate="">
            <Row>
              <Col xl="6">
                <FormDiv label={"Company Name"} id={"companyName"} type={"text"} disabled />
                <FormDiv label={"Company Phone"} id={"companyPhone"} type={"text"} disabled />
                <FormDiv label={"Company Email"} id={"companyEmail"} type={"text"} disabled />
                <FormDiv label={"Shop Code"} id={"shopCode"} type={"text"} disabled />
                <FormDiv label={"SSM"} id={"SSM"} type={"link"} disabled />
              </Col>
              <Col xl="6">
                <FormGroup className="row">
                  <Label className="col-xl-3 col-md-4 col-6">
                    <span>*</span> Is Beautician
                  </Label>

                  {setYesNoTag(profileMainState.isBeautician)}
                </FormGroup>
                <FormDiv label={"Shop Address"} id={"shopAddress"} type={"textarea"} disabled={true} />
                <FormDiv label={"Current Shop Points"} id={"shopPoints"} type={"text"} disabled={true} />
                <FormGroup className="row">
                  <Label className="col-xl-3 col-md-4 col-6">
                    <span>*</span> Shop Benefit Status
                  </Label>
                  <Stack
                    className="form-control col-xl-8 col-md-7"
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <div>
                      {profileMainState.maintainTarget.monthShopPV} /{" "}
                      {profileMainState.maintainTarget.shopMaintainPV}
                    </div>
                    {setBenefitStatusTag(profileMainState.maintainTarget.shopStatus)}
                  </Stack>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </TabPanel>
      </Tabs>
      <div className="pull-right">
        <Button type="button" color="primary" onClick={() => _handleUpdateUserAPI()}>
          Save
        </Button>
      </div>
      <SSMModal ref={ssmModalRef} handleSubmit={() => {}} />
    </Fragment>
  );
};

export default TabsetUser;
