import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

const SearchInput = ({onChange, onSearch}) => {
  const [ text, setText ] = React.useState()
  return (
    <Paper
      elevation={3}
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: {xs: '100%', md: '400px'} }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search..."
        inputProps={{ 'aria-label': 'search' }}
        onChange={(e) => {
          setText(e.target.value);
        }}
        onKeyDown={(e) => {
          if(e.key == 'Enter'){
            onChange(text);
         }
        }}
      />
      <IconButton type="submit" sx={{ p: '10px' }} aria-label="search" onClick={() => {onChange(text)}}>
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

export default SearchInput
