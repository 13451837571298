import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CustomSnackbar = (props) => {
  const { open, content = "NetWorkError", type = 1, handleIsOpen } = props;

  const handleMessage = () => {
    if (type === 0) {
      toast.success(content);
    } else if (type === 1) {
      toast.error(content);
    } else if (type === 2) {
      toast.warn(content);
    } else {
      toast.success(content);
    }
  };
  useEffect(() => {
    if (open) {
      handleMessage();
      setTimeout(() => {
        handleIsOpen({ snackbar: false });
      }, [3000]);
    }
  }, [open]);

  return <ToastContainer autoClose={5000} />;
};

export default CustomSnackbar;
