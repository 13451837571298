import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, FormGroup } from "reactstrap";
import { useProps } from "../../contexts";
import { Tabs, TabList, Tab } from "react-tabs";

//hooks
import useQuery from "../../hooks/useQuery";
//Component
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../common/datatable";
//Controller
import {setShopVerifyStatusTag, setStateHelper, setStockStatusTag, setYesNoTag} from "../../utils";
import {handleGetShopListAPI, handleGetShopListExportAPI} from "../../controller/shop";
import { STATIC_VARIABLE } from "../../constants/static-data";
import SearchInput from "../common/search-input";
import {Stack} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import IosShareIcon from "@mui/icons-material/IosShare";
import ExcelShopList from "../../export/excel/ExcelShopList";

const generateBody = (body, page, rowsPerPage, handleOpen) => {
  return body.map((e, i) => ({
    id: <p style={{ fontWeight: "bold" }}>{(page - 1) * rowsPerPage + i + 1}.</p>,
    companyName: e.isBeautician === 1 ? <span style={{ color: 'rgb(117, 126, 101)' }}>{e.companyName}</span> : e.companyName,
    phoneNumber: e.phoneNumber,
    // email: e.email,
    state: e.state,
    shopCode: e.shopCode,
    isBeautician: setYesNoTag(e.isBeautician),
    status: setShopVerifyStatusTag(e.status),
    stock: setStockStatusTag(e.stockStatus),
    edit: (
      <div>
        <a href={`/shop/details-shop/${e.ID}`}>
          <i
            className="fa fa-pencil"
            style={{
              width: 35,
              fontSize: 20,
              padding: 11,
              color: "rgb(40, 167, 69)",
              cursor: "pointer",
            }}
          ></i>
        </a>
      </div>
    ),
  }));
};

const header = [
  { id: "id", label: "", align: "center" },
  { id: "companyName", label: "Shop Name", align: "left" },
  { id: "phoneNumber", label: "Shop Number", align: "left" },
  // { id: "email", label: "Email", align: "left" },
  { id: "state", label: "Location", align: "left" },
  { id: "shopCode", label: " Shop Code", align: "left" },
  { id: "isBeautician", label: "Beautician", align: "left" },
  { id: "stock", label: "Stock Status", align: "left" },
  { id: "status", label: "Status", align: "left" },
  { id: "edit", label: "", align: "center" },
];

const ShopList = () => {
  const [query, setQuery] = useQuery();
  const history = useHistory();
  const { _handleChange } = useProps();
  const [excelFormat, setExcelFormat] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mainState, setMainState] = useState({
    list: [],
    page: query.page ? parseInt(query.page) : 1,
    limit: query.limit ? parseInt(query.limit) : 10,
    total: 10,
    order: query.order || "desc",
    orderBy: query.orderBy || "createdAt",
    search: query.search || "",
    active: query.active || 0,
    refreshData: false,
  });

  const handleOpenEdit = (ID) => {
    history.push(`/shop/details-shop/${ID}`);
  };

  const handleExport = async () => {
    setLoading(true)

    const queryObj = {
      order: mainState.order,
      orderBy: mainState.orderBy,
      search: mainState.search,
      active: mainState.active,
      changeContext: _handleChange,
    };
    const { data, success } = await handleGetShopListExportAPI(queryObj)

    if (success) {
      const targetID = document.getElementById('shop-export')
      setExcelFormat(data)
      setTimeout(function() {
        targetID.click()
      }, 1000)
    }

    setTimeout(function() {
      setLoading(false)
    }, 1000)
  }

  const _handleGetShopVerifyList = () => {
    const queryObj = {
      page: mainState.search ? 1 : mainState.page,
      limit: mainState.limit,
      order: mainState.order,
      orderBy: mainState.orderBy,
      search: mainState.search,
      active: mainState.active,
    };
    const param = {
      ...queryObj,
      changeContext: _handleChange,
      setMainState: setStateHelper(setMainState),
    };
    setQuery(queryObj);
    handleGetShopListAPI(param);
  };

  const _handleChangeStatus = (e) => {
    setStateHelper(setMainState)({ active: e });
  };

  const body = generateBody(mainState.list, mainState.page, mainState.limit, handleOpenEdit);

  useEffect(() => {
    _handleGetShopVerifyList();
  }, [
    mainState.page,
    mainState.order,
    mainState.orderBy,
    mainState.limit,
    mainState.active,
    mainState.search,
    mainState.refreshData,
  ]);

  return (
    <Fragment>
      <Breadcrumb title="Shop List" />
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <Stack direction='row' justifyContent='space-between' alignItems="center" sx={{ width: '100%', mb: 2 }}>
              <h5>List of Shops</h5>
              <LoadingButton
                onClick={handleExport}
                startIcon={<IosShareIcon />}
                loading={loading}
                loadingPosition="center"
                variant="outlined"
              >
                Export
              </LoadingButton>
            </Stack>
          </CardHeader>
          <CardBody>
            <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
              <Tabs
                defaultIndex={mainState.orderStatus}
                onSelect={(e) => {
                  _handleChangeStatus(e);
                }}
              >
                <TabList className="nav nav-tabs customize-filter-tab-header">
                  <Tab className="nav-link f-w-600">All</Tab>
                  <Tab className="nav-link f-w-600">Active</Tab>
                  <Tab className="nav-link f-w-600">Suspend</Tab>
                </TabList>
                <Col xl="6">
                  <FormGroup className="row">
                    <SearchInput
                      onChange={(e) => {
                        setStateHelper(setMainState)({ search: e });
                      }}
                      value={mainState.search}
                    />
                  </FormGroup>
                </Col>
                <Datatable
                  // multiSelectOption={true}
                  multiSelectOption={false}
                  header={header}
                  myData={body}
                  // myData={data}
                  order={mainState.order}
                  orderBy={mainState.orderBy}
                  setState={setStateHelper(setMainState)}
                  rowsPerPage={mainState.limit}
                  count={mainState.total}
                  page={mainState.page}
                  total={mainState.total}
                  pagination={true}
                  class="-striped -highlight"
                />
              </Tabs>
            </div>
          </CardBody>
        </Card>
      </Container>
      <ExcelShopList items={excelFormat} />
    </Fragment>
  );
};

export default ShopList;
