import useCheckIsValidation from "../../../hooks/useCheckIsValidation";
import {
  createCategory,
  deleteCategory,
  getCategoryList, getCategoryListPagination,
  updateCategory,
} from "../../../services/product/category";
import { apiMiddleware } from "../../../utils/middleware";
import { isEmpty } from "../../../utils/validation";

const contextParams = {
  snackbar: true,
  snackbarType: 1,
  snackbarContent: "",
};

export const handleGetCategoryListAPI = async ({
  page,
  limit,
  order,
  orderBy,
  search,
  setMainState,
  changeContext,
}) => {
  const apiParams = {
    page,
    limit,
    order,
    orderBy,
    search,
  };

  const { data, success } = await apiMiddleware(changeContext, getCategoryListPagination, apiParams);
  if (success) {
    setMainState({ list: data.list, total: data.total });
  }
  return success;
};

export const handleCreateCategoryAPI = async (mainState, changeContext) => {
  const body = {
    title: mainState.title,
    content: mainState.content,
    showInFilter: mainState.showInFilter,
  };
  const verifyArr = [isEmpty(body.title, "title")];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isValidate = useCheckIsValidation(verifyArr.reverse(), changeContext);
  if (!isValidate) return false;

  const { data, success } = await apiMiddleware(changeContext, createCategory, body);
  if (success) {
    contextParams.snackbarType = 0;
    contextParams.snackbarContent = `Add Successful!`;
  }
  changeContext(contextParams);
  return success;
};

export const handleUpdateCategoryAPI = async (mainState, categoryID, changeContext) => {
  const body = {
    title: mainState.title,
    content: mainState.content,
    showInFilter: mainState.showInFilter,
  };
  const verifyArr = [isEmpty(body.title, "title")];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isValidate = useCheckIsValidation(verifyArr.reverse(), changeContext);
  if (!isValidate) return false;

  const { data, success } = await apiMiddleware(changeContext, updateCategory, categoryID, body);
  if (success) {
    contextParams.snackbarType = 0;
    contextParams.snackbarContent = `Update Successful!`;
  }
  changeContext(contextParams);
  return success;
};

export const handleDeleteCategoryAPI = async (id, changeContext) => {
  const { data, success } = await apiMiddleware(changeContext, deleteCategory, id);
  if (success) {
    contextParams.snackbarType = 0;
    contextParams.snackbarContent = `Delete Successful!`;
  }
  changeContext(contextParams);
  return success;
};
