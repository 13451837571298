export const validEmail = (email) => {
  let errMessage;
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!re.test(String(email))) {
    errMessage = 'Wrong email format.';
  }
  return errMessage;
};
export const isEmpty = (string, label='field') => {
  let errMessage;

  if ((!string || string === '') && string !== 0) {
    errMessage = label + " can't be blank.";
  }
  return errMessage;
};

export const isEmptyNumber = (number, label='field') => {
  let errMessage;

  if (parseFloat(number) === 0 || isNaN(number)) {
    errMessage = label + " can't be blank.";
  }
  return errMessage;
};

export const isEmptySelected= (number, label='selections') => {
  let errMessage;

  if (number === 0) {
    errMessage = " Please choose a " + label;
  }
  return errMessage;
};

export const isArrayEmpty= (arr, label='selections') => {
  let errMessage;

  if ((!Array.isArray(arr) || arr.length === 0)) {
    errMessage = label +  " can't be blank." ;
  }
  return errMessage;
}

export const comparePassword = (password, confirmPassword, isSame = false ) => {
  let errMessage;
  if (!password || password === '' || password.length === 0) {
    errMessage = "Password can't be blank.";
  }

  if (!confirmPassword || confirmPassword === ''|| password.length === 0) {
    errMessage = "Confirm Password can't be blank.";
  }

  if (isSame) {
    if (password === confirmPassword ) {
      errMessage = "Your new password can not be the same as current password.";
    }
  }
  else {
    if (password !== confirmPassword ) {
      errMessage = "Password are different.";
    }
  }

  return errMessage
}
