import React from 'react'
import Dropzone from 'react-dropzone-uploader'
import { useDropzone } from "react-dropzone";


const MyUploader = () => {
  // specify upload params and url for your files
  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }
  
  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => { console.log(status, meta, file) }
  
  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    console.log(files.map(f => f.meta))
    allFiles.forEach(f => f.remove())
  }

  return (
    <div className="dropzone">
      <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      onSubmit={handleSubmit}
      accept="image/*,audio/*,video/*"
    />
    </div>
    
  )
}
// const MyUploader = () =>{

//     // DropZone functions
//     const handleOnDrop = (acceptedFiles: any) => {
//       if (!acceptedFiles.length) return;
//       const tmpData = acceptedFiles.map((file: any) =>
//         Object.assign(file, {
//           preview: URL.createObjectURL(file),
//           remarks: "",
//         })
//       );
//       setFile(tmpData[0]);
//       handleUpdateIconAPI(tmpData[0], user, _handleChange);
//     };
  
//     const handleDropRejected = (files: any) => {
//       if (!files.length) return;
//       const file = files[0];
//       if (file.size >= FILE_UPLOAD_SIZE) {
//         _handleChange &&
//           _handleChange({
//             snackbar: true,
//             snackbarType: 2,
//             snackbarContent: "The chosen file is too large (5MB maximum)", //TODO
//           });
//       }
//     };

//   const { getRootProps, getInputProps, open } = useDropzone({
//     accept: "image/*",
//     onDrop: handleOnDrop,
//     onDropRejected: handleDropRejected,
//     multiple: false,
//   });

//   return ()
// }

export default MyUploader