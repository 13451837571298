import { Input } from "reactstrap";

const CustomTextInput = ({
  statusKey,
  type,
  value,
  placeholder,
  disabled,
  handleChange,
  handleKeyPress,
}) => {
  return (
    <Input
      type={type}
      className="form-control"
      value={value}
      disabled={disabled}
      placeholder={placeholder}
      onKeyPress={(event) => handleKeyPress && handleKeyPress(event)}
      onChange={(event) => {
        handleChange && handleChange(event, statusKey ? statusKey : "");
      }}
    />
  );
};
export default CustomTextInput;
