import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


//  Internally, customStyles will deep merges your customStyles with the default styling.
const customStyles = {
  // rows: {
  //   style: {
  //     minHeight: '72px', // override the row height
  //   },
  // },
  // headCells: {
  //   style: {
  //     paddingLeft: '8px', // override the cell padding for head cells
  //     paddingRight: '8px',
  //   },
  // },
  cells: {
    style: {
      // paddingLeft: '8px', // override the cell padding for data cells
      // paddingRight: '8px',
      '& div': {
        whiteSpace: 'normal !important',
        overflow: 'visible !important',
      }
    },
  },
};

const Datatable = ({
  myData,
  header,
  myClass,
  order,
  orderBy,
  pagination,
  rowsPerPage,
  count,
  page,
  total,
  setState = () => {} }) => {
  const [open, setOpen] = useState(false);
  const [checkedValues, setCheckedValues] = useState([]);
  // const [data, setData] = useState(myData);
  const selectRow = (e, i) => {
    if (!e.target.checked) {
      setCheckedValues(checkedValues.filter((item, j) => i !== item));
    } else {
      checkedValues.push(i);
      setCheckedValues(checkedValues);
    }
  };
  const handleRemoveRow = () => {
    // const updatedData = myData.filter(function (el) {
    //   return checkedValues.indexOf(el.id) < 0;
    // });
    // setData([...updatedData]);
    toast.success("Successfully Deleted !");
  };

  const handleDelete = (index) => {
    // if (window.confirm("Are you sure you wish to delete this item?")) {
    //   const del = data;
    //   del.splice(index, 1);
    //   setData([...del]);
    // }
    toast.success("Successfully Deleted !");
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    let newOrder = isAsc ? "desc" : "asc";
    let orderKey = "order";
    let orderByKey = "orderBy";

    setState({ [orderKey]: newOrder, [orderByKey]: property });
  };

  const columns = [];
  header.map((key, index) => {
    let obj = {
      name: <b>{key.label}</b>,
      header: <b>{key.label}</b>,
      selector: key.id,
      Cell: null,
      sortable: key.sortable != null ? key.sortable : true,
      headerStyle: (selector, id) => {
        return { textAlign: key.align };   // removed partial line here
      },
    }
    if (key.id == 'id') {
      obj.width = '3rem'
    }

    if (key.id == 'photos') {
      obj.width = '25rem'
    }

    if (key.id == 'img') {
      obj.width = '15rem'
    }

    if (key.id == 'title') {
      obj.width = '15rem'
    }

    if (key.id == 'edit') {
      obj.minWidth = '8rem'
    }

    if (key.id == 'subTotalSum') {
      obj.minWidth = '8rem'
    }


    columns.push(obj);
  });

  const handleSort = async (column, sortDirection) => {
    handleRequestSort(column.selector)
  };

  const handleChangePage = (newPage) => {
    let key = "page";
    setState({ [key]: newPage });
  };

  const handleChangeLimit = (rolePerPage) => {
    let key = "limit";
    setState({ [key]: rolePerPage });
  };

  return (
      <Fragment>
        <DataTable
          // data={data}
          onColumnOrderChange={cols => console.log(cols)}
          noHeader
          data={myData}
          columns={columns}
          onSort={handleSort}
          defaultSortAsc={order === "asc" ? true : false}
          defaultSortField={orderBy}
          className={myClass}
          sortServer
          pagination={pagination}
          paginationDefaultPage={page}
          paginationPerPage={rowsPerPage}
          paginationTotalRows={total}
          paginationServer
          onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
            handleChangePage(currentPage);
            handleChangeLimit(currentRowsPerPage);
          }}
			    onChangePage={handleChangePage}
          striped={true}
          center={true}
          customStyles={customStyles}
        />
        <ToastContainer />
      </Fragment>
  );
};

export default Datatable;
