import React, {Fragment, useCallback, useState, useEffect} from "react";
import {setStateHelper} from "../../utils";
import {Tabs, TabList, TabPanel, Tab} from "react-tabs";
import {FormGroup, Input, Label, Col, Row, Form, Button} from "reactstrap";
import TooltipIcon from "../common/tooltips-icon";
import {handleGetConfigAPI, handleUpdateAPI} from "../../controller/configs";
import {useProps} from "../../contexts";
import DateFilter from "../common/datepicker";
import Switch from '@mui/material/Switch';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import moment from "moment";

const ConfigTabs = () => {
  const { _handleChange } = useProps();
  const [tab, setTab] = useState(0);
  const [mainState, setMainState] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    adminCode: '',
    coinRate: 0,
    pvToMoney: 0,
    minMemberPoint: 0,
    minShopPoint: 0,
    memberFee: 0,
    checkedMemberFee: false,
    freeMalaysia: false,
    freeEastMalaysia: false,
    freeSingapore: false,
    // Member
    memberMinCommission: 0,
    memberMaxCommission: 0,
    memberTargetPV: 0,
    memberCashback: 0,
    memberMissionDay: 0,
    memberUpgradeRequire: 0,
    // VIP
    vipMinCommission: 0,
    vipMaxCommission: 0,
    vipTargetPV: 0,
    vipCashback: 0,
    vipMaintainPV: 0,
    vipUpgradeRequire: 0,
    vipMissionDirect: 0,
    vipGroupSales: 0,
    vipGroupBonus: 0,
    // VVIP
    vvipMinCommission: 0,
    vvipMaxCommission: 0,
    vvipTargetPV: 0,
    vvipSecondMinCommission: 0,
    vvipSecondMaxCommission: 0,
    vvipSecondTargetPV: 0,
    vvipCashback: 0,
    vvipMaintainPV: 0,
    vvipMissionDirect: 0,
    vvipGroupSales: 0,
    vvipGroupBonus: 0,
    // Shop
    shopMinCommission: 0,
    shopMaxCommission: 0,
    shopTargetPV: 0,
    shopMaintainPV: 0,
  });

  const handleChecked = (event) => {
    setStateHelper(setMainState)({ checkedMemberFee: event.target.checked });
  };

  const handleCheckedShipping = (key) =>  (event) => {
    setStateHelper(setMainState)({ [key]: event.target.checked });
  };

  const _handleGetConfigData = () => {
    handleGetConfigAPI({
      changeContext: _handleChange,
      setMainState: setStateHelper(setMainState),
    })
  }

  const handleUpdate = () => {
    handleUpdateAPI(mainState, _handleChange)
  }

  useEffect(() => {
    _handleGetConfigData()
  }, [])

  const FormDiv = useCallback(({value, label, type, id, disabled=false, tooltip, setState}) => {
    let input =  <Input
      className="form-control col-xl-6 col-md-5"
      id={id}
      type={type}
      disabled={disabled}
      required=""
      value={value}
      min="0"
      onChange={(e) => {
        setStateHelper(setMainState)({[e.target.id]: e.target.value})
      }}
    />

    return (
      <FormGroup className="row">
        <Label className="col-xl-5 col-md-6">
          <span>*</span>{label}
          {tooltip && <TooltipIcon text={tooltip} />}
        </Label>
        {input}
      </FormGroup>
    );
  },[])

  return (
    <Fragment>
      <Tabs
        defaultIndex={tab}
        onSelect={(e) => setTab(e)}>
        <TabList className="nav nav-tabs tab-coupon">
          <Tab className="nav-link">Generals</Tab>
          <Tab className="nav-link">Level Setting</Tab>
        </TabList>

        <TabPanel>
          <FormDiv value={mainState.adminCode} label={`Admin Code`} id={'adminCode'} />
          <FormDiv value={mainState.coinRate} label={`MYR to Coin Rate (%) (1 MYR = ${(mainState.coinRate)/100} Coin)`} id={'coinRate'} type={'number'} />
          <FormDiv value={mainState.pvToMoney} label={`Point To Money Rate (%) (1 Point = ${(mainState.pvToMoney)/100} MYR)`} id={'pvToMoney'} type={'number'} />
          <FormDiv value={mainState.minMemberPoint} label={'Minimum Join Member Point'} id={'minMemberPoint'} type={'number'} />
          <FormDiv value={mainState.minShopPoint} label={'Minimum Join Shop'} id={'minShopPoint'} type={'number'} />
          <FormDiv value={mainState.memberFee} label={'Member Fee (RM)'} id={'memberFee'} type={'number'} />

          <FormGroup className="row">
            <Label className="col-xl-5 col-md-6">
              Free Register Member Fee Period
            </Label>

            <div className="col-xl-6 col-md-6 p-0">
              <Stack direction="row" alignItems='center' justifyContent='space-between' sx={{ width: '100%' }}>
                <Switch
                  onChange={handleChecked}
                  checked={mainState.checkedMemberFee}
                  className="react-switch"
                />
                <Collapse in={mainState.checkedMemberFee} orientation="horizontal">
                  <DateFilter
                    startDate={mainState.startDate}
                    endDate={mainState.endDate}
                    handleSearch={(startDate, endDate) => {
                      const _startDate = moment(startDate).format("YYYY-MM-DD");
                      const _endDate = moment(endDate).format("YYYY-MM-DD");
                      setStateHelper(setMainState)({ startDate: _startDate, endDate: _endDate });
                    }}
                  />
                </Collapse>
              </Stack>
            </div>
          </FormGroup>

          <FormGroup className="row">
            <Label className="col-xl-5 col-md-6">
              Free Shipping Area
            </Label>

            <div className="col-xl-6 col-md-6 p-0">
              <Stack alignItems='flex-start' justifyContent='center' sx={{ width: '100%', p: {xs: 2, sm: 0} }}>
                <div>
                  Peninsular Malaysia
                  <Switch
                    onChange={handleCheckedShipping('freeMalaysia')}
                    checked={mainState.freeMalaysia}
                    className="react-switch"
                  />
                </div>

                <div>
                  East Malaysia
                  <Switch
                    onChange={handleCheckedShipping('freeEastMalaysia')}
                    checked={mainState.freeEastMalaysia}
                    className="react-switch"
                  />
                </div>

                <div>
                  Singapore
                  <Switch
                    onChange={handleCheckedShipping('freeSingapore')}
                    checked={mainState.freeSingapore}
                    className="react-switch"
                  />
                </div>


                {/*    <Collapse in={mainState.checkedMemberFee} orientation="horizontal">*/}
            {/*      <DateFilter*/}
            {/*        startDate={mainState.startDate}*/}
            {/*        endDate={mainState.endDate}*/}
            {/*        handleSearch={(startDate, endDate) => {*/}
            {/*          const _startDate = moment(startDate).format("YYYY-MM-DD");*/}
            {/*          const _endDate = moment(endDate).format("YYYY-MM-DD");*/}
            {/*          setStateHelper(setMainState)({ startDate: _startDate, endDate: _endDate });*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    </Collapse>*/}
              </Stack>
            </div>
          </FormGroup>

        </TabPanel>

        <TabPanel>
          <Form className="needs-validation user-add" noValidate="">
            <h4>Member Level Setting</h4>
            <Row>
              {/*<Col xl="6">*/}
              {/*  <FormDiv value={mainState.memberMinCommission} label={'Min Commission (%)'} id={'memberMinCommission'} type={'number'} />*/}
              {/*  <FormDiv value={mainState.memberMaxCommission} label={'Max Commission (%)'} id={'memberMaxCommission'} type={'number'} />*/}
              {/*  <FormDiv value={mainState.memberTargetPV} label={'Commission Target (PV)'} id={'memberTargetPV'} type={'number'} />*/}
              {/*</Col>*/}

              <Col xl="6">
                <FormDiv value={mainState.memberCashback} label={'Cashback Rate (%)'} id={'memberCashback'} type={'number'} />
                <FormDiv
                  id={'memberMissionDay'}
                  type={'number'}
                  value={mainState.memberMissionDay}
                  label={'Upgrade Mission Day'}
                  tooltip={`In order to promote to VIP, member need to get min ${mainState.memberUpgradeRequire} customers within ${mainState.memberMissionDay} days,
                   If sucessful to achieve this short term mission, this VIP can enttiled to have a Reward. If member fail to achieve during ${mainState.memberMissionDay} days , he/she also be VIP if he/she get ${mainState.memberUpgradeRequire} members.`}
                />
                <FormDiv
                  id={'memberUpgradeRequire'}
                  type={'number'}
                  value={mainState.memberUpgradeRequire}
                  label={'Require Upgrade Member'}
                />
              </Col>
            </Row>
          </Form>

          <Form className="needs-validation user-add form-gap" noValidate="">
            <h4>VIP Level Setting</h4>
            <Row>
              <Col xl="6">
                <FormDiv value={mainState.vipMinCommission} label={'Min Commission (%)'} id={'vipMinCommission'} type={'number'} />
                <FormDiv value={mainState.vipMaxCommission} label={'Max Commission (%)'} id={'vipMaxCommission'} type={'number'} />
                <FormDiv value={mainState.vipTargetPV} label={'Commission Target (Point)'} id={'vipTargetPV'} type={'number'} />
                <FormDiv
                  id={'vipUpgradeRequire'}
                  type={'number'}
                  value={mainState.vipUpgradeRequire}
                  label={'Require Upgrade VIP'}
                />
              </Col>
              <Col xl="6">
                <FormDiv value={mainState.vipCashback} label={'Cashback Rate (%)'} id={'vipCashback'} type={'number'} />
                <FormDiv value={mainState.vipMaintainPV} label={'Monthly Maintain (Point)'} id={'vipMaintainPV'} type={'number'} />
                <FormDiv
                  id={'vipMissionDirect'}
                  type={'number'}
                  value={mainState.vipMissionDirect}
                  label={'Direct Account Target'}
                  tooltip={`${mainState.vipMissionDirect} direct accounts individual purchase 1 sets as individual sales within 1 month, will enttile for additional Incentive`}
                />
                <FormDiv value={'-'} label={'Group Sales Target (Point)'} id={'vipGroupSales'} type={'number'} disabled={true}/>
                <FormDiv value={'-'} label={'Group Sales Bonus'} id={'vipGroupBonus'} type={'number'} disabled={true}/>
              </Col>
            </Row>
          </Form>

          <Form className="needs-validation user-add form-gap" noValidate="">
            <h4>VVIP Level Setting</h4>
            <Row>
              <Col xl="6">
                <FormDiv value={mainState.vvipMinCommission} label={'1st Min Commission (%)'} id={'vvipMinCommission'} type={'number'} />
                <FormDiv value={mainState.vvipMaxCommission} label={'1st Max Commission (%)'} id={'vvipMaxCommission'} type={'number'} />
                <FormDiv value={mainState.vvipTargetPV} label={'1st Commission Target (Point)'} id={'vvipTargetPV'} type={'number'} />
                <FormDiv value={mainState.vvipSecondMinCommission} label={'2nd Min Commission (%)'} id={'vvipSecondMinCommission'} type={'number'} />
                <FormDiv value={mainState.vvipSecondMaxCommission} label={'2nd Max Commission (%)'} id={'vvipSecondMaxCommission'} type={'number'} />
                <FormDiv value={mainState.vvipSecondTargetPV} label={'2nd Commission Target (Point)'} id={'vvipSecondTargetPV'} type={'number'} />
              </Col>

              <Col xl="6">
                <FormDiv value={mainState.vvipCashback} label={'Cashback Rate (%)'} id={'vvipCashback'} type={'number'} />
                <FormDiv value={mainState.vvipMaintainPV} label={'Monthly Maintain (Point)'} id={'vvipMaintainPV'} type={'number'} />
                <FormDiv
                  id={'vvipMissionDirect'}
                  type={'number'}
                  value={mainState.vvipMissionDirect}
                  label={'Direct Account Target'}
                  tooltip={`${mainState.vvipMissionDirect} direct accounts individual purchase 1 sets as individual sales within 1 month, will enttile for additional Incentive`}
                />
                <FormDiv value={'-'}label={'Group Sales Target (Point)'} id={'vvipGroupSales'} type={'number'} disabled={true}/>
                <FormDiv value={'-'} label={'Group Sales Bonus'} id={'vvipGroupBonus'} type={'number'} disabled={true}/>
              </Col>
            </Row>
          </Form>

          <Form className="needs-validation user-add form-gap" noValidate="">
            <Stack flexDirection='row'>
            <h4>Shop Setting </h4>
            <Stack sx={{ml: 2}}> (if vip or vvip is shop then monthly maintain will follow shop setting)</Stack>
            </Stack>
            <Row>
              <Col xl="6">
                <FormDiv value={mainState.shopMinCommission} label={'Min Commission (%)'} id={'shopMinCommission'} type={'number'} />
                <FormDiv value={mainState.shopMaxCommission} label={'Max Commission (%)'} id={'shopMaxCommission'} type={'number'} />
                {/* <FormDiv value={mainState.shopTargetPV} label={'Commission Target (PV)'} id={'shopTargetPV'} type={'number'} /> */}
                <FormDiv value={mainState.shopMaintainPV} label={'Monthly Maintain (Point)'} id={'shopMaintainPV'} type={'number'} />
              </Col>
            </Row>
          </Form>
        </TabPanel>
      </Tabs>

      <div className="pull-right">
        <Button type="button" color="primary" onClick={handleUpdate}>
          Save
        </Button>
      </div>


    </Fragment>
  )
}

export default ConfigTabs;
