import { createContext, useContext } from "react";
import { variables } from "../config/variables";

let defaultStatus = {
  snackbar: false,
  snackbarType: 0,
  snackbarContent: "",
  openBackdrop: false,
  isLogin: false,
  user: {
    username: "Tester Wong",
    name: "",
    email: "",
    role: 0,
    status: 0,
  },
  _handleChange: () => {},
};

try {
  const json = window.localStorage.getItem(variables.local_storage_version);
  if (json) {
    defaultStatus = JSON.parse(json);
  }
} catch (error) {
  console.log("error get local storage");
}

const utilsContext = createContext(defaultStatus);
const useProps = () => useContext(utilsContext);

export { defaultStatus, utilsContext, useProps };
