export const apiMiddleware = async (
  changeContext,
  callbackFunctions,
  data = undefined,
  data2 = undefined,
  data3 = undefined
) => {
  const contextParams = {
    snackbar: true,
    snackbarType: 1,
    snackbarContent: "",
  };

  changeContext({ openBackdrop: true });
  try {
    const result = await callbackFunctions(data, data2, data3);
    if (!result) {
      contextParams.snackbarContent = result.data.message;
      changeContext(contextParams);
    }
    if (result && (result.success === false || result.status === (400 || 401 || 403))) {
      contextParams.snackbarContent = result.data.message;
      changeContext(contextParams);
    }

    changeContext({ openBackdrop: false });
    return result;
  } catch (error) {
    contextParams.snackbarContent = error;
    changeContext({ ...contextParams, openBackdrop: false });
  }
};
