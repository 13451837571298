import React, {useState, Fragment} from "react";
import {HelpCircle} from "react-feather";
import Tooltip from '@mui/material/Tooltip';

const TooltipIcon = (props) => {
  const {text} = props;

  return (
    <Tooltip title={text}>
      <HelpCircle className="tooltip-icon" />
    </Tooltip>
  )
}

export default TooltipIcon;
