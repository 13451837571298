import React, { useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Popover } from '@mui/material';
import moment from "moment";
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  filter_title: {
    fontSize: 13,
  },
  filter_selected: {
    marginLeft: 5,
    fontSize: 13,
  },
  filter_container: {
    cursor: "pointer",
    display: "flex",
    border: "1.6px solid",
    borderColor: 'rgba(0, 0, 0, 0.54)',
    padding: "0px 0px 0px 10px",
    justifyContent: "space-between",
    // alignItems: "center",
    backgroundColor: "white",
    borderRadius: 8,
    minWidth: 270,
  },
  filter_inner_container: {
    display: "flex",
    padding: "10px 0px 10px 0px",
    borderColor: 'black',
    alignItems: "center",
  },
  icon_container: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    fontSize: 25,
    padding: "0px 2px",
    color: 'rgba(0, 0, 0, 0.54)'
  },
  icon_reverse: {
    transform: "rotate(180deg)",
  },
}));

const DateFilter = ({ handleSearch, startDate, endDate }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = useState([
    {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      key: "selection",
    },
  ]);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setState([
      {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        key: "selection",
      }
    ])
  }, [startDate, endDate])

  return (
    <div>
      <div
        className={classes.filter_container}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        <div className={classes.filter_inner_container}>
          {/* <div className={classes.filter_title}>Date Range:</div> */}
          <div className={classes.filter_selected}>
            {moment(state[0].startDate).format("DD/MM/YYYY")} -{" "}
            {moment(state[0].endDate).format("DD/MM/YYYY")}
          </div>
        </div>
        <div className={classes.icon_container}>
          <EventOutlinedIcon className={classes.icon} />
        </div>
      </div>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <DateRange
          editableDateInputs={true}
          onChange={(item) => {
            handleSearch(item.selection.startDate, item.selection.endDate);
            setState([item.selection]);
          }}
          moveRangeOnFirstSelection={false}
          ranges={state}
          dateDisplayFormat={"dd-MM-yyyy"}
        />
      </Popover>
    </div>
  );
};

export default DateFilter;
