import React, { useEffect, useState, useImperativeHandle, useCallback } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useProps } from "../../../contexts";
import {handleModifyShopProductAPI, handleGetAllProductAPI, handleGetAllProductShopAPI} from "../../../controller/shop";
import { getArrayObjByValue, setStateHelper } from "../../../utils";
import CustomSelector from "../custom-selector";
import Datatable from "../datatable";
import {useParams} from "react-router-dom";

const header = [
  { id: "id", label: "", align: "center" },
  { id: "photos", label: "Item(s)", align: "center", sortable: false },
  { id: "price", label: "Unit Price", align: "center", sortable: false },
  { id: "pvPoint", label: "Unit PV", align: "center", sortable: false },
  { id: "quantity", label: "Quantity", align: "center", sortable: false },
  { id: "edit", label: "Action", align: "center" },
];


const InputDiv = ({data, selectedItemsArr, setSelectItemArr}) => {
  return <Input
    className="form-control col-xl-8 col-md-7"
    id={''}
    type={'number'}
    required=""
    max={data.stockLimit}
    defaultValue={data.quantity}
    onChange={(event) => {
      selectedItemsArr.map((i) => {
        if (i.ID === data.ID) {
          i.quantity = event.target.value;
        }
      })
    }}
    onBlur={() => {
      setSelectItemArr([...selectedItemsArr]);
    }}
/>
}



const generateBody = (body, handleAddQty, handleDelete, setSelectItemArr) => {
  if (!body) return [];
  return body.map((e, i) => ({
    id: <p style={{ fontWeight: "bold" }}>{i + 1}.</p>,
    // photo: <img alt="" src={e.avtar} style={{ width: 50, height: 50 }} />,
    photos: (
      <div className={"customize-order-table-image"}>
        <img
          src={e.photo.length > 0 ? e.photo[0].URL : null}
          style={{ width: 60, height: 60, marginRight: 10 }}
          alt="W3Schools.com"
        />
        {e.title}
      </div>
    ),
    price: 'RM ' + e.price,
    pvPoint: e.pvPoint + ' PV',
    quantity: <InputDiv data={e} selectedItemsArr={body} setSelectItemArr={setSelectItemArr}/>,
    edit: (
      <div>
        <span onClick={handleDelete(e.ID)}>
          <i
            className="fa fa-trash"
            style={{
              width: 35,
              fontSize: 20,
              padding: 11,
              color: "#e4566e",
              cursor: "pointer",
            }}
          ></i>
        </span>
      </div>
    ),
  }));
};

const AddStockModal = React.forwardRef((props, ref) => {
  const { handleSubmit } = props;
  const { shopID } = useParams();
  const { _handleChange } = useProps();
  const [selectedIndex, setSelectIndex] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedItemsArr, setSelectItemArr] = useState([]);
  const [customSelectorArr , setCustomSelectorArr] = useState([]);
  const [mainState, setMainState] = useState({
    shopId: 0,
    isOpen: false,
  });


  // const products = [
  //   {
  //     id: 1,
  //     title: 'product 1',
  //     picPath: '',
  //     unitPrice: 10,
  //     unitPV: 12,
  //   },
  //   {
  //     id: 2,
  //     title: 'product 2',
  //     picPath: '',
  //     unitPrice: 10,
  //     unitPV: 12,
  //   },
  //   {
  //     id: 3,
  //     title: 'product 3',
  //     picPath: '',
  //     unitPrice: 10,
  //     unitPV: 12,
  //   }
  // ]

  useEffect(() => {
    handleGetAllProductShopAPI(shopID, setProducts,_handleChange).then((e) => {
      if (e.isSuccess) {
        let arr = e.list.map((e) => {
          return {
            label: e.title,
            value: e.ID
          }
        })
        setCustomSelectorArr(arr);
      }
    })
  }, [mainState.isOpen])

  const handleOpen = () => {
    setStateHelper(setMainState)({ isOpen: true });
  };

  const handleClose = () => {
    setMainState({
      shopID: 0,
      isOpen: false,
    });
    setSelectItemArr([]);
    setCustomSelectorArr([]);
  };

  const _handleAddProduct = () => {
    if(selectedIndex) {
      let obj = {
        ...getArrayObjByValue(products, selectedIndex, 'ID'),
        quantity: 1
      }
      selectedItemsArr.push(obj)
      setSelectItemArr([...selectedItemsArr])
      const removeIndex = customSelectorArr.map((e) => {return e.value}).indexOf(selectedIndex);
      customSelectorArr.splice(removeIndex, 1);
      setCustomSelectorArr(customSelectorArr);
      setSelectIndex(null);
    }
  };

  const _handleAddQty = (id) => (event) => {
    selectedItemsArr.map((e) => {
      if (e.id === id) {
        e.quantity = event.target.value;
      }
    })
    setSelectItemArr([...selectedItemsArr]);
  };


  const _handleDeleteProduct = (id) => (event) => {
    const selectedIdArr = selectedItemsArr.map((e) => {return e.ID});
    const removeIndex = selectedIdArr.indexOf(id);
    selectedItemsArr.splice(removeIndex, 1);
    setSelectItemArr([...selectedItemsArr])

    const newSelectedIdArr = [];
    selectedItemsArr.map((e) => {
      if (e.ID !== id) {
        newSelectedIdArr.push(e.ID)
      }
    })
    let arr = [];
    for (let index = 0; index < products.length; index++) {
      let isSameID = false;
      for (let index_1 = 0; index_1 < newSelectedIdArr.length; index_1++) {
        if (newSelectedIdArr[index_1] === products[index].ID) {
          isSameID = true
        }
      }
      if (!isSameID) {
        let obj = {
          label: products[index].title,
          value: products[index].ID
        }
        arr.push(obj);
      }
    }

    setCustomSelectorArr([...arr])
  };

  const _handleSubmit = () => {
    const arr = selectedItemsArr.map((e) => {
      return {
        productID: e.ID,
        quantity: parseInt(e.quantity)
      }
    })
    handleSubmit(arr);
    // handleModifyShopProductAPI(arr, mainState.shopID, _handleChange).then((isSuccess) => {
    //   if (isSuccess) {
    //     handleClose();
    //   }
    // })
  }

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleClose,
    setMainState,
  }));

  let body = generateBody(
    selectedItemsArr,
    _handleAddQty,
    _handleDeleteProduct,
    setSelectItemArr
  );

  return (
    <Modal isOpen={mainState.isOpen} size={'xl'} toggle={handleClose}>
      <ModalHeader toggle={handleClose}>
        <h5 className="modal-title f-w-600">
          Adjust Products Stock
        </h5>
      </ModalHeader>
      <ModalBody>
        <Form className="needs-validation user-add" noValidate="">
          <Label className="f-w-700">
            {'Select a product add to the list and (+)ADD / (-)REMOVE the stock unit.'}
          </Label>

          <FormGroup className="row">

            <div className="col-xl-7 col-md-6">
              <CustomSelector
                initStatus={customSelectorArr[selectedIndex]}
                options={customSelectorArr}
                value={customSelectorArr[selectedIndex]}
                handleChange={(e) => {
                  setSelectIndex(e.value)
                }}
              />
            </div>
            <div className="col-xl-2 col-md-2">
            <Button
              type="button"
              color="primary"
              onClick={_handleAddProduct}
            >
              Add
              <i
              className="fa fa-plus"
              style={{
                fontSize: 15,
                color: "white",
                cursor: "pointer",
                marginLeft: 10
              }}
            ></i>
            </Button>
            </div>
          </FormGroup>
          <Datatable
            // multiSelectOption={true}
            multiSelectOption={false}
            header={header}
            myData={body}
            // myData={data}
            pageSize={10}
            pagination={false}
            class="-striped -highlight"
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="primary" onClick={() => {_handleSubmit()}}>
          {"Submit"}
        </Button>
      </ModalFooter>
    </Modal>
  );
});
export default AddStockModal;
