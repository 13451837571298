import React, { Fragment, useState, useEffect } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useProps } from "../../contexts";
import { handleGetUserLineDetailsAPI, handleGetUserSecondLineDetailsAPI } from "../../controller/user";
import useQuery from "../../hooks/useQuery";
import { setStateHelper, setTierStatusTag } from "../../utils";
import Datatable from "../common/datatable";
const header = [
  { id: "id", label: "", align: "center" },
  // { id: "photo", label: "Images", align: "center" },
  { id: "username", label: "User", align: "left" },
  { id: "firstName", label: "Name", align: "left" },
  // { id: "lastName", label: "Last Name", align: "left" },
  { id: "email", label: "Email", align: "left" },
  { id: "tier", label: "Tier", align: "left" },
];

const header_2 = [
  { id: "id", label: "", align: "center" },
  // { id: "photo", label: "Images", align: "center" },
  { id: "username", label: "User", align: "left" },
  { id: "firstName", label: "Name", align: "left" },
  // { id: "lastName", label: "Last Name", align: "left" },
  { id: "email", label: "Email", align: "left" },
  { id: "tier", label: "Tier", align: "left" },
  { id: "upline", label: "Up-Line", align: "left" },
];

const generateBody = (body, page, rowsPerPage) => {
  return body.map((e, i) => ({
    id: <p style={{ fontWeight: "bold" }}>{(page - 1) * rowsPerPage + i + 1}.</p>,
    username: <a className={'customize-a'} href={`/users/details-user/${e.ID}`}>#{e.ID}-{e.username}</a>,
    firstName: e.firstName + " " + e.lastName,
    lastName: e.lastName,
    email: e.email,
    tier: setTierStatusTag(e.tier)
  }));
};

const generateBody_2 = (body, page, rowsPerPage) => {
  return body.map((e, i) => ({
    id: <p style={{ fontWeight: "bold" }}>{(page - 1) * rowsPerPage + i + 1}.</p>,
    username: <a className={'customize-a'} href={`/users/details-user/${e.ID}`}>#{e.ID}-{e.username}</a>,
    firstName: e.firstName + " " + e.lastName,
    lastName: e.lastName,
    email: e.email,
    tier: setTierStatusTag(e.tier),
    upline: <a className={'customize-a'} href={`/users/details-user/${e.ID}`}>#{e.ID}-{e.upLine.username}</a>,
  }));
};

const TabsetUser = ({userID}) => {
  const [query, setQuery] = useQuery();
  const { _handleChange } = useProps();
  const [tabIndex, setTabIndex] = useState(0);
  const [downLineState, setDownLineState] = useState({
    list: [],
    page: query.page ? parseInt(query.page) : 1,
    limit: query.limit ? parseInt(query.limit) : 10,
    total: 10,
    order: query.order || "desc",
    orderBy: query.orderBy || "createdAt",
    search: "",
    refreshData: false,
    tabIndex: query.tabIndex || 0
  });

  const [secondLineState, setSecondLineState] = useState({
    list: [],
    page: query.page ? parseInt(query.page) : 1,
    limit: query.limit ? parseInt(query.limit) : 10,
    total: 10,
    order: query.order || "desc",
    orderBy: query.orderBy || "createdAt",
    search: "",
    refreshData: false,
    tabIndex: query.tabIndex || 0
  });

  const body = generateBody(
    downLineState.list,
    downLineState.page,
    downLineState.limit,
    () => {},
    () => {}
  );

  const body_2 = generateBody_2(
    secondLineState.list,
    secondLineState.page,
    secondLineState.limit,
    () => {},
    () => {}
  );

  const _handleGetUserDownLineList = () => {

    const queryObj = {
      userID: userID,
      page: downLineState.page,
      limit: downLineState.limit,
      order: downLineState.order,
      orderBy: downLineState.orderBy,
      tabIndex: tabIndex
    };
    const param = {
      ...queryObj,
      changeContext: _handleChange,
      setMainState: setStateHelper(setDownLineState),
    };
    // setQuery(queryObj);

    handleGetUserLineDetailsAPI(param);
  };

  const _handleGetUserSecondDownLineList = () => {
    const queryObj = {
      userID: userID,
      page: secondLineState.page,
      limit: secondLineState.limit,
      order: secondLineState.order,
      orderBy: secondLineState.orderBy,
      tabIndex: tabIndex
    };
    const param = {
      ...queryObj,
      changeContext: _handleChange,
      setMainState: setStateHelper(setSecondLineState),
    };
    // setQuery(queryObj);

    handleGetUserSecondLineDetailsAPI(param);
  };

  useEffect(() => {
    switch (parseInt(tabIndex)) {
      case 0:
        _handleGetUserDownLineList();
      break;
      case 1:
        _handleGetUserSecondDownLineList();
        break;
      default:
      break;
    }
  }, [tabIndex, secondLineState.limit, secondLineState.page, secondLineState.order, secondLineState.orderBy, JSON.stringify(...secondLineState.list), downLineState.limit,downLineState.page, downLineState.order, downLineState.orderBy, JSON.stringify(...downLineState.list)]);

	return (
		<Fragment>
			<Tabs 
      defaultIndex={tabIndex}
      onSelect={(e) => {
        setTabIndex(e);
      }}>
				<TabList className="nav nav-tabs customize-filter-tab-header">
					<Tab className="nav-link">First Layer</Tab>
					<Tab className="nav-link">Second Layer</Tab>
				</TabList>
				<TabPanel>
					<Form className="needs-validation user-add" noValidate="">
						<div className="permission-block">
							<div className="attribute-blocks">
                <Datatable
                // multiSelectOption={true}
                  multiSelectOption={false}
                  header={header}
                  myData={body}
                  // myData={data}
                  total={downLineState.total}
                  pageSize={10}
                  pagination={true}
                  class="-striped -highlight"
                  setState={setStateHelper(setDownLineState)}
                />
						
							</div>
						</div>
					</Form>
				</TabPanel>
        <TabPanel>
					<Form className="needs-validation user-add" noValidate="">
						<div className="permission-block">
							<div className="attribute-blocks">
                <Datatable
                // multiSelectOption={true}
                  multiSelectOption={false}
                  header={header_2}
                  myData={body_2}
                  // myData={data}
                  total={downLineState.total}
                  pageSize={10}
                  pagination={true}
                  class="-striped -highlight"
                  setState={setStateHelper(setSecondLineState)}
                />
							</div>
						</div>
					</Form>
				</TabPanel>
			</Tabs>
		</Fragment>
	);
};

export default TabsetUser;
