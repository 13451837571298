import React, { Fragment } from "react";
import { Link } from "react-router-dom";
//images import
import man from "../../../assets/images/dashboard/man.png";
import { useHistory } from "react-router-dom";
import { handleLogoutAPI } from "../../../controller/login";
import { useProps } from "../../../contexts";
import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';

const UserMenu = () => {
  const history = useHistory();
  const { _handleChange, user } = useProps();
  const firstUsername = user ? user.username[0].toUpperCase() : 'A'

  const handleLogout = () => {
    
    handleLogoutAPI(_handleChange).then((isSuccess) => {
      if (isSuccess) {
        history.push("/");
      }
    });
  };
  return (
    <Fragment>
      <li className="onhover-dropdown">
        <div className="media align-items-center">
          <Avatar sx={{ bgcolor: deepOrange[500]  }}>{firstUsername}</Avatar>
          {/*<img*/}
          {/*  className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"*/}
          {/*  src={man}*/}
          {/*  alt="header-user"*/}
          {/*/>*/}
          <div className="dotted-animation">
            <span className="animate-circle"></span>
            <span className="main-circle"></span>
          </div>
        </div>
        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
          <li>
            <Link to={`${process.env.PUBLIC_URL}/settings/profile`}>
              <i data-feather="user"></i>Edit Profile
            </Link>
          </li>
          {/* <li>
            <a href="#javaScript">
              <i data-feather="mail"></i>Inbox
            </a>
          </li>
          <li>
            <a href="#javaScript">
              <i data-feather="lock"></i>Lock Screen
            </a>
          </li>
          <li>
            <a href="#javaScript">
              <i data-feather="settings"></i>Settings
            </a>
          </li> */}
          <li>
            <Link onClick={() => handleLogout()}>
              {/* <Link to={`${process.env.PUBLIC_URL}/`}> */}
              <i data-feather="log-out"></i>Logout
            </Link>
          </li>
        </ul>
      </li>
    </Fragment>
  );
};

export default UserMenu;
