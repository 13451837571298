import React, { Fragment, useState, useEffect, useRef } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import {Form, Input, Button} from "reactstrap";
import { useProps } from "../../contexts";
import TextField from '@mui/material/TextField';
import { handleGetShopOrderListAPI } from "../../controller/order";
import {handleGetShopProductListAPI, handleUpdateProductStockLimit} from "../../controller/shop";
import { handleGetUserCommissionLineDetailsAPI, handleGetUserDetailsAPI } from "../../controller/user";
import useQuery from "../../hooks/useQuery";
import { setStateHelper, setTargetStatusTag, setClaimStatusTag, setStockStatusTag } from "../../utils";
import Datatable from "../common/datatable";
import CommissionModal from "../common/modal/commission-modal";
import ProductListImage from "../common/product-list-img";
import {Stack} from "@mui/material";

const header = [
  { id: "id", label: "", align: "center" },
  { id: "photos", label: "Item(s)", align: "center", sortable: true },
  { id: "unitPrice", label: "Unit Price", align: "center", sortable: true },
  { id: "unitPV", label: "Unit Point", align: "center", sortable: true },
  { id: "totalQuantity", label: "Total Quantity", align: "center", sortable: true },
  // { id: "status", label: "Stock Status", align: "center", sortable: true },
  { id: "stockLimit", label: "Limit", align: "center", sortable: false },
  { id: "action", label: "", align: "center", sortable: false },
];

const generateBody = (body, page, rowsPerPage, handleChangeInput, handleSetChange, handleUpdateLimit) => {
  return body.map((e, i) => ({
    id: <p style={{ fontWeight: "bold" }}>{(page - 1) * rowsPerPage + i + 1}.</p>,
    photos: (
      <ProductListImage photoPath={e.product.photo.length > 0 ? e.product.photo[0].URL : null} label={e.product.title} point={e.product.pvPoint}/>
    ),
    unitPrice: "RM " + e.product.price,
    unitPV: e.product.pvPoint,
    totalQuantity: e.stock,
    // status: setStockStatusTag(e.stockStatus),
    stockLimit: <div style={{ minWidth: '100px'}}>
      {/*<TextField*/}
      {/*  value={e.stockLimit}*/}
      {/*  onChange={handleChangeInput(i)}*/}
      {/*/>*/}
      <Input
        className="form-control col-xl-8 col-md-7"
        id={''}
        type={'number'}
        required=""
        defaultValue={e.stockLimit}
        min={0}
        // value={e.stockLimit}
        onChange={handleChangeInput(i)}
        onBlur={handleSetChange}
      />
    </div>,
    action: <Button
      type="button"
      color="secondary"
      outline
      style={{marginRight: 10, fontSize: '12px', padding: '5px'}}
      onClick={handleUpdateLimit(e, i)}
    >Update</Button>,
  }));
};

const ProductListShop = ({ shopID, isRefresh }) => {
  const { _handleChange } = useProps();
  const commissionModelRef = useRef();
  const [limitList, setLimitList] = useState([])
  const [mainState, setMainState] = useState({
    list: [],
    page: 1,
    limit: 10,
    total: 10,
    order: "desc",
    orderBy: "createdAt",
    search: "",
    refreshData: false,
    startDate: "",
    endDate: "",
  });

  const handleChangeInput = (index) => (event) => {
    let value = parseInt(event.target.value) ? parseInt(event.target.value) : 0;
    if (value < 0 ) {
      value = 0;
    }
    limitList[index] = value;
  }

  const handleSetChange = () => {
    setLimitList(limitList)
  }

  const handleUpdateLimit = (item, index) => async () => {
    const params = {
      stockID: item.stockID,
      value: limitList[index],
      changeContext: _handleChange
    }
    await handleUpdateProductStockLimit(params)
    setStateHelper(setMainState)({ refreshData: !mainState.refreshData })
  }

  const _handleGetShopProductListAPI = () => {
    const queryObj = {
      page: mainState.search ? 1 : mainState.page,
      limit: mainState.limit,
      order: mainState.order,
      orderBy: mainState.orderBy,
      search: mainState.search,
    };

    const param = {
      ...queryObj,
      shopID,
      setLimitList,
      changeContext: _handleChange,
      setMainState: setStateHelper(setMainState),
    };
    handleGetShopProductListAPI(param);
  };

  useEffect(() => {
    _handleGetShopProductListAPI();
  }, [
    mainState.page,
    mainState.order,
    mainState.orderBy,
    mainState.tabIndex,
    mainState.refreshData,
    JSON.stringify(...mainState.list),
    isRefresh,
  ]);

  const body = generateBody(mainState.list, mainState.page, mainState.limit, handleChangeInput, handleSetChange, handleUpdateLimit);

  return (
    <Fragment>
      <Tabs
        defaultIndex={mainState.tabIndex}
        onSelect={(e) => {
          setStateHelper(setMainState)({ tabIndex: e });
        }}
      >
        <TabList className="nav nav-tabs tab-coupon">
          <Tab className="nav-link">Product Details</Tab>
        </TabList>
        <TabPanel>
          <Form className="needs-validation user-add" noValidate="">
            <div className="permission-block">
              <div className="attribute-blocks">
                <Datatable
                  // multiSelectOption={true}
                  header={header}
                  myData={body}
                  order={mainState.order}
                  orderBy={mainState.orderBy}
                  setState={setStateHelper(setMainState)}
                  // myData={data}
                  rowsPerPage={mainState.limit}
                  count={mainState.total}
                  page={mainState.page}
                  total={mainState.total}
                  pagination={true}
                  class="-striped -highlight"
                />
              </div>
            </div>
          </Form>
        </TabPanel>
      </Tabs>
      <CommissionModal ref={commissionModelRef} handleSubmit={() => {}} />
    </Fragment>
  );
};

export default ProductListShop;
