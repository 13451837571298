import React, { useState, Fragment, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {Button, Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import { useProps } from "../../contexts";
//hooks
import useQuery from "../../hooks/useQuery";
//Components
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../common/datatable";
import DeletePopUp from "../common/modal/DeletePopUp";
//Controller
import { handleDeleteProductAPI, handleGetProductListAPI } from "../../controller/product";
import {plainTextWithoutHTML, setPriceFormat, setProductStatusTag} from "../../utils";

const generateBody = (body, page, rowsPerPage, handleEdit, handleDelete = () => {}) => {
  return body.map((e, i) => ({
    id: <p style={{ fontWeight: "bold" }}>{(page - 1) * rowsPerPage + i + 1}.</p>,
    img: (
      <div>
        {e.photos.length !== 0 && e.photos.map((item, index) => {
          return <img alt="" src={item} style={{ width: 50, height: 50 }} />;
        })}
      </div>
    ),
    title: <div style={{minWidth: 200, overflow: 'initial', whiteSpace: 'break-spaces'}}>{e.title}</div>,
    // description: plainTextWithoutHTML(e.description),
    stock: e.stock,
    price: 'RM ' + setPriceFormat(e.price),
    discountPrice: 'RM ' + setPriceFormat(e.discountPrice),
    pvPoint: e.pvPoint,
    status: setProductStatusTag(e.status),
    createdAt: e.createdAt,
    edit: (
      <div>
        {e.type === 1 &&
          <span onClick={() => handleDelete(e.ID, e.title)}>
            <i
              className="fa fa-trash"
              style={{
                width: 35,
                fontSize: 20,
                padding: 11,
                color: "#e4566e",
                cursor: "pointer",
              }}
            />
          </span>
        }

        <a href={`/products/add-product/${e.ID}`}>
          <i
            className="fa fa-pencil"
            style={{
              width: 35,
              fontSize: 20,
              padding: 11,
              color: "rgb(40, 167, 69)",
              cursor: "pointer",
            }}
          />
        </a>
      </div>
    ),
  }));
};

const header = [
  { id: "id", label: "", align: "center" },
  { id: "img", label: "Images", align: "center" },
  { id: "title", label: "Product Title", align: "left" },
  // { id: "description", label: "Description", align: "left" },
  { id: "stock", label: "Stock", align: "center" },
  { id: "price", label: "Price", align: "center" },
  { id: "discountPrice", label: "Discount", align: "center" },
  { id: "pvPoint", label: "Point", align: "center" },
  { id: "status", label: "Status", align: "center" },
  { id: "createdAt", label: "Created Date", align: "center" },
  { id: "edit", label: "Action", align: "center" },
];

const Digital_pro_list = () => {
  const [query, setQuery] = useQuery();
  const { _handleChange } = useProps();
  const deleteProductPopUpRef = useRef();
  const [mainState, _setMainState] = useState({
    list: [],
    page: query.page ? parseInt(query.page) : 1,
    limit: query.limit ? parseInt(query.limit) : 10,
    total: 10,
    order: query.order || "desc",
    orderBy: query.orderBy || "date",
    search: "",
    status:0,
    categoryID:0,
    refreshData: false,
  });
  const history = useHistory();

  const setMainState = (newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleRefreshData = () => {
    setMainState({ refreshData: !mainState.refreshData });
  };

  const _handleGetProductList = () => {
    const queryObj = {
      page: mainState.search ? 1 : mainState.page,
      limit: mainState.limit,
      order: mainState.order,
      orderBy: mainState.orderBy,
      search: mainState.search,
      status:mainState.status,
      categoryID:mainState.categoryID,
    };
    const param = {
      ...queryObj,
      changeContext: _handleChange,
      setMainState: setMainState,
    };
    setQuery(queryObj);
    handleGetProductListAPI(param);
  };

  const handleEdit = (event,id) => {
    history.push(`/products/add-product/${id}`);
  };

  const handleOpenDeletePopUp = (id, itemName) => {
    deleteProductPopUpRef.current.setMainState({ isOpen: true, deleteID: id, itemName: itemName });
  };

  const handleDelete = (id) => {
    handleDeleteProductAPI(id, _handleChange).then((isSuccess) => {
      if (isSuccess) {
        handleRefreshData();
        deleteProductPopUpRef.current.handleClose();
      }
    });
  };

  const body = generateBody(
    mainState.list,
    mainState.page,
    mainState.limit,
    handleEdit,
    handleOpenDeletePopUp
  );

  useEffect(() => {
    _handleGetProductList();
  }, [
    mainState.page,
    mainState.order,
    mainState.limit,
    mainState.orderBy,
    mainState.refreshData,
  ]);

  return (
    <Fragment>
      <Breadcrumb title="Product List" parent="Products" />
      {/* <!-- Container-fluid starts--> */}
      <Container fluid="true" style={{ padding: '0 15px' }}>
        <Row className="product-adding">
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Product Lists</h5>
              </CardHeader>
              <CardBody>
                <div className="btn-popup pull-right">
                  <Button type="button" color="primary" onClick={() =>history.push("/products/add-product")}>
                    Create Product
                  </Button>
                </div>
                <div className="clearfix"></div>
                <div id="basicScenario" className="product-physical">
                  <Datatable
                    // multiSelectOption={true}
                    // multiSelectOption={false}
                    header={header}
                    myData={body}
                    // myData={data}
                    order={mainState.order}
                    orderBy={mainState.orderBy}
                    setState={setMainState}
                    rowsPerPage={mainState.limit}
                    count={mainState.total}
                    page={mainState.page}
                    total={mainState.total}
                    pagination={true}
                    // class="-striped -highlight"
                  />
                  <DeletePopUp ref={deleteProductPopUpRef} title="Product" handleDelete={handleDelete} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* <!-- Container-fluid Ends--> */}
    </Fragment>
  );
};

export default Digital_pro_list;
