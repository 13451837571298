import React, { Fragment, useEffect, useRef, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, FormGroup, Row } from "reactstrap";
import useQuery from "../../hooks/useQuery";
import { useProps } from "../../contexts";
import { useHistory } from "react-router-dom";
import { saveAs } from 'file-saver';
//Components
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../common/datatable";
import {
  handleGetOrderListExportAPI,
  handleGetUserOrderDetailsExportAPI,
  handleGetUserOrderListAPI
} from "../../controller/order";
import { setOrderStatusTag, setIsJoinShopStatusTag, setStateHelper, setPriceFormat } from "../../utils";
import { Tabs, TabList, Tab } from "react-tabs";
import { STATIC_VARIABLE } from "../../constants/static-data";
import SearchInput from "../common/search-input";
import DateFilter from "../common/datepicker";
import moment from "moment";
import { Grid, Autocomplete, TextField, Stack, Button, IconButton } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import IosShareIcon from '@mui/icons-material/IosShare';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { handleGetAllUserListAPI } from "../../controller/user";
import ExcelOrderList from "../../export/excel/ExcelOrderList";
import ReceiptPDF from "../../export/pdf/OrderPDF";
import { pdf } from '@react-pdf/renderer';

const generateBody = (body, page, rowsPerPage, handleOpen, handleDownloadPDF) => {
  return body.map((e, i) => ({
    id: <p style={{ fontWeight: "bold" }}>{(page - 1) * rowsPerPage + i + 1}.</p>,
    ID: <div style={{ overflow: "initial", whiteSpace: "break-spaces" }}>{e.ID}</div>,
    username: e.userName ? (
      <a className={"customize-a"} href={`/users/details-user/${e.memberID}`}>
        #{e.memberID}-{e.userName}
      </a>
    ) : (
      "-"
    ),
    totalAmount: "RM " + setPriceFormat(e.totalAmount),
    orderStatus: setOrderStatusTag(e.orderStatus),
    type: setIsJoinShopStatusTag(e.type),
    createdAt: <div style={{ overflow: "initial", whiteSpace: "break-spaces" }}>{e.createdAt}</div>,
    edit: (
      <Stack direction='row' justifyContent='center' alignItems='center'>
        <a href={`/sales/orders-details/${e.ID}`}>
          <i
            className="fa fa-pencil"
            style={{
              width: 35,
              fontSize: 20,
              padding: 11,
              color: "rgb(40, 167, 69)",
              cursor: "pointer",
            }}
          />
        </a>

        <IconButton onClick={handleDownloadPDF(e.ID)}>
          <PictureAsPdfIcon sx={{ fontSize: '18px' }} />
        </IconButton>
      </Stack>
    ),
  }));
};

const header = [
  { id: "id", label: "", align: "center" },
  { id: "ID", label: "Order Id", align: "center" },
  { id: "username", label: "User", align: "left" },
  { id: "totalAmount", label: "Total Price", align: "left" },
  { id: "orderStatus", label: "Order Status", align: "left" },
  { id: "type", label: "Shop Activate Order", align: "left" },
  { id: "createdAt", label: "Created Date", align: "center" },
  { id: "edit", label: "Action", align: "center" },
];

const Orders = () => {
  const [query, setQuery] = useQuery();
  const history = useHistory();
  const { _handleChange } = useProps();
  const [userList, setAllUserList] = useState([]);
  const [user, setUser] = useState();
  const [excelFormat, setExcelFormat] = useState([]);
  const [pdfData, setPDFData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mainState, setMainState] = useState({
    list: [],
    page: query.page ? parseInt(query.page) : 1,
    limit: query.limit ? parseInt(query.limit) : 10,
    total: 10,
    order: query.order || "desc",
    orderBy: query.orderBy || "date",
    orderStatus: query.orderStatus || 0,
    search: "",
    status: 0,
    categoryID: 0,
    refreshData: false,
    startDate: moment().subtract("3", "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const handleExport = async () => {
    setLoading(true)

    const queryObj = {
      order: mainState.order,
      orderBy: mainState.orderBy,
      search: mainState.search,
      orderStatus: mainState.orderStatus,
      startDate: mainState.startDate,
      endDate: mainState.endDate,
      memberID: user ? user.value : '',
      changeContext: _handleChange,
    };

    const { data, success } = await handleGetOrderListExportAPI(queryObj)

    if (success) {
      const targetID = document.getElementById('order-export')
      setExcelFormat(data)
      setTimeout(function() {
        targetID.click()
      }, 1000)
    }

    setTimeout(function() {
      setLoading(false)
    }, 1000)
  }

  const setDownloadPDF = (ID: any) => async () => {
    const {success, data} = await  handleGetUserOrderDetailsExportAPI(ID, _handleChange)

    if (success) {
      // setPDF(data)
      const blob = await pdf((
        <ReceiptPDF details={data} paymentMethod='Billplz' />
      )).toBlob();

      saveAs(blob, `order-${ID}.pdf`);
    }
  }

  const _handleGetOrderList = () => {
    const queryObj = {
      page: mainState.search ? 1 : mainState.page,
      limit: mainState.limit,
      order: mainState.order,
      orderBy: mainState.orderBy,
      search: mainState.search,
      orderStatus: mainState.orderStatus,
      startDate: mainState.startDate,
      endDate: mainState.endDate,
      memberID: user ? user.value : ''
    };

    const param = {
      ...queryObj,
      changeContext: _handleChange,
      setMainState: setStateHelper(setMainState),
    };
    setQuery(queryObj);
    handleGetUserOrderListAPI(param);
  };

  const _handleOpenEdit = (orderID) => {
    history.push(`/sales/orders-details/${orderID}`);
  };

  const _handleChangeStatus = (e) => {
    setStateHelper(setMainState)({ orderStatus: e });
  };

  const _handleGetAllUser = () => {
    const param = {
      changeContext: _handleChange,
      setMainState: setAllUserList,
    };
    handleGetAllUserListAPI(param);
  }

  useEffect(() => {
    _handleGetOrderList();
    _handleGetAllUser();
  }, [
    mainState.page,
    mainState.order,
    mainState.orderBy,
    mainState.limit,
    mainState.search,
    mainState.orderStatus,
    mainState.refreshData,
    mainState.startDate,
    mainState.endDate,
    user
  ]);

  const body = generateBody(mainState.list, mainState.page, mainState.limit, _handleOpenEdit, setDownloadPDF);

  return (
    <Fragment>
      <Breadcrumb title="Orders" parent="Sales" />

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Stack alignItems="flex-end" sx={{ width: '100%', mb: 2 }}>
                  <LoadingButton
                    onClick={handleExport}
                    startIcon={<IosShareIcon />}
                    loading={loading}
                    loadingPosition="center"
                    variant="outlined"
                  >
                    Export
                  </LoadingButton>
                </Stack>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid items sx={12} md={6}>
                    <h5>List of Orders</h5>
                    </Grid>
                  <Grid items sx={12} md={6}>
                    <Stack alignItems='flex-end'>
                      <SearchInput
                        onChange={(e) => {
                          setStateHelper(setMainState)({ search: e });
                        }}
                        value={mainState.search}
                      />
                    </Stack>
                    </Grid>
                </Grid>
              </CardHeader>
              <CardBody className="order-datatable">
                <Tabs
                  defaultIndex={mainState.orderStatus}
                  onSelect={(e) => {
                    _handleChangeStatus(e);
                  }}
                >
                  <TabList className="nav nav-tabs customize-filter-tab-header">
                    <Tab className="nav-link f-w-600">All</Tab>
                    {STATIC_VARIABLE.orderStatusArray.map((e) => {
                      return <Tab className="nav-link f-w-600">{e.label}</Tab>;
                    })}
                  </TabList>
                  {/* <Row> */}
                  <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
                    <Grid items sx={6}>
                    <Autocomplete
                      disablePortal
                      value={user}
                      id="combo-box-demo"
                      onChange={(event, newValue) => {
                        setUser(newValue);
                      }}
                      options={userList}
                      sx={{ width: 250,
                        '.MuiInputLabel-root': {
                          fontSize: 14,
                        }
                        }}
                      renderInput={(params) => (
                        <TextField
                          InputProps={{
                            style: {
                              fontSize: 12,
                            },
                          }}
                          {...params}
                          label="Search User Member Line Order"
                        />
                      )}
                    />
                    </Grid>
                    <Grid items sx={6}>
                      <DateFilter
                        startDate={mainState.startDate}
                        endDate={mainState.endDate}
                        handleSearch={(startDate, endDate) => {
                          const _startDate = moment(startDate).format("YYYY-MM-DD");
                          const _endDate = moment(endDate).format("YYYY-MM-DD");
                          setStateHelper(setMainState)({ startDate: _startDate, endDate: _endDate });
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Datatable
                    multiSelectOption={false}
                    header={header}
                    myData={body}
                    order={mainState.order}
                    orderBy={mainState.orderBy}
                    setState={setStateHelper(setMainState)}
                    rowsPerPage={mainState.limit}
                    count={mainState.total}
                    page={mainState.page}
                    total={mainState.total}
                    pagination={true}
                    class="-striped -highlight"
                  />
                </Tabs>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <ExcelOrderList items={excelFormat}/>
    </Fragment>
  );
};

export default Orders;
