import React, { useEffect, useState, useImperativeHandle } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { setStateHelper, handleDownloadImage } from "../../../utils";
import Paper from "@mui/material/Paper";
import DownloadButton from "../download-btn";

const SSMModal = React.forwardRef((props, ref) => {
  const { handleSubmit = () => {} } = props;
  const [mainState, setMainState] = useState({
    fileObj: [
      {
        path: "",
        name: "",
      },
    ],
    partnerArr: [
      {
        ID: 83,
        name: "",
        IC: "",
        photo: [
          {
            path: "",
            name: "",
          },
          {
            path: "",
            name: "",
          },
        ],
      },
    ],
    shopPhotos: [],
    isOpen: false,
  });

  const handleOpen = () => {
    setStateHelper(setMainState)({ isOpen: true });
  };

  const handleClose = () => {
    setStateHelper(setMainState)({
      fileArr: [],
      partnerArr: [],
      isOpen: false,
    });
  };

  const _handleSubmit = () => {
    const returnDetails = {};
    handleSubmit(returnDetails);
  };

  let _setMainState = setStateHelper(setMainState);
  useImperativeHandle(ref, () => ({
    handleOpen,
    handleClose,
    setMainState: _setMainState,
  }));

  const FormDiv = ({ label, type, id, disabled = false, value }) => {
    // let value = profileMainState[id] != null ? profileMainState[id] : '-';
    let input = (
      <Input
        className="form-control col-xl-8 col-md-7"
        id={id}
        type={type}
        disabled={disabled}
        required=""
        value={value}
        onChange={(e) => {
          console.log(e.target.id);
        }}
      />
    );
    if (type == "link") {
      input = (
        <div className={"customize-a"} style={{ padding: "0.48rem 0rem" }} onClick={() => {}}>
          {value}
        </div>
      );
    }
    return (
      <FormGroup className="row">
        <Label className="col-xl-3 col-md-4">{label}:</Label>
        {input}
      </FormGroup>
    );
  };

  return (
    <Modal size="xl" isOpen={mainState.isOpen} toggle={handleClose}>
      <ModalHeader toggle={handleClose}>
        <h5 className="modal-title f-w-600">SSM Details</h5>
      </ModalHeader>
      <ModalBody>
        <h7 className="modal-title f-w-600">Company SSM / Beautician Certificate</h7>
        <Form className="needs-validation user-add" noValidate="">
          <Row>
            {mainState.fileObj.map((e, index) => {
              return (
                <DownloadButton path={e.path} name={`Document ${index+1}`}/>
              );
            })}
          </Row>
        </Form>
        <h7 className="modal-title f-w-600">Shop Photos</h7>
        <Form className="needs-validation user-add" noValidate="">
          <Row>
            {mainState.shopPhotos.map((e) => {
              return (
                <Col xl={3}>
                  <img style={{ width: 150, height: 'auto' }} src={e.path} alt={e.name} />
                </Col>
              );
            })}
          </Row>
        </Form>
        <h7 className="modal-title f-w-600">Partner Details</h7>
        <Form className="needs-validation user-add" noValidate="">
          <div className="customize-divider" />
          <div style={{ overflowY: "scroll", overflowX: "hidden", height: 400 }}>
            {mainState.partnerArr.map((e) => {
              return (
                <>
                  <Row>
                    <Col xl="6">
                      <FormDiv label={"Name"} id={"Name"} type={"text"} value={e.name} />
                    </Col>
                    <Col xl="6">
                      <FormDiv label={"IC"} id={"IC"} type={"text"} value={e.IC} />
                    </Col>
                    <Row style={{marginLeft: 10}}>
                      {e.photo.map((e) => {
                        return (
                          // <img src={"https://www.w3schools.com/images/w3schools_green.jpg"} alt="W3Schools.com"/>
                          <Col xl="6">
                            <img style={{ width: 150, height: 'auto' }} src={e.path} alt={e.name} />
                          </Col>
                        );
                      })}
                    </Row>
                  </Row>
                  <div className="customize-divider" />
                </>
              );
            })}
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
      </ModalFooter>
    </Modal>
  );
});
export default SSMModal;
