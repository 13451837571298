import React from "react";
import ReactExport from "react-data-export";
import moment from "moment";
import {getArrayObjByValue} from "../../utils";
import {STATIC_VARIABLE} from "../../constants/static-data";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const titleStyle = {
  fill: {
    patternType: "solid",
    fgColor: {rgb: "C0C0C0"}
  },
  alignment: {
    vertical: 'center',
    horizontal: 'center',
  },
  border: {
    top: { style: 'thin', color: '000000' },
    bottom: { style: 'thin', color: '000000' },
    left: { style: 'thin', color: '000000' },
    right: { style: 'thin', color: '000000' }
  }
};

const columnStyle = {
  border: {
    top: { style: 'thin', color: '000000' },
    bottom: { style: 'thin', color: '000000' },
    left: { style: 'thin', color: '000000' },
    right: { style: 'thin', color: '000000' }
  }
}

function ExcelClaimList(props) {
  const {items} = props;

  const dataItem = items.map((e, i) => ([
    {value: i+1, style: columnStyle},
    {value: `#${e.memberID}-${e.username}`, style: columnStyle},
    {value: e.firstLayer, style: columnStyle},
    {value: e.secondLayer, style: columnStyle},
    {value: e.shopLayer, style: columnStyle},
    {value: `RM ${e.total}`, style: columnStyle},
    {value: getArrayObjByValue(STATIC_VARIABLE.claimStatusArray, e.claimStatus).label, style: columnStyle},
    {value: e.targetComplete, style: columnStyle},
    {value: e.bankName, style: columnStyle},
    {value: e.bank, style: columnStyle},
    {value: e.bankNo, style: columnStyle},
    {value: e.createdAt, style: columnStyle},
    {value: e.memberCreatedAt, style: columnStyle},
  ]))

  const multiDataSet = [
    {
      columns: [
        {title: "No", style: titleStyle, width: {wpx: 30}},
        {title: "User", style: titleStyle, width: {wpx: 270}},
        {title: "First Layer Point", style: titleStyle, width: {wpx: 150}},
        {title: "Second Layer Point", style: titleStyle, width: {wpx: 150}},
        {title: "Shop Layer Point", style: titleStyle, width: {wpx: 150}},
        {title: "Total Amount", style: titleStyle, width: {wpx: 150}},
        {title: "Claim Status", style: titleStyle, width: {wpx: 150}},
        {title: "Target Complete", style: titleStyle, width: {wpx: 150}},
        {title: "Bank Name", style: titleStyle, width: {wpx: 150}},
        {title: "Bank", style: titleStyle, width: {wpx: 150}},
        {title: "Bank Account", style: titleStyle, width: {wpx: 150}},
        {title: "Created Date", style: titleStyle, width: {wpx: 150}},
        {title: "Member Join Date", style: titleStyle, width: {wpx: 150}},
      ],
      data: dataItem
    }
  ];

  return (
    <ExcelFile
      element={<button id='claim-export' style={{ display: 'none' }}>Download Data</button>}
      filename={`Claim-List-${moment().unix()}`}
    >
      <ExcelSheet dataSet={multiDataSet} name="Organization"/>
    </ExcelFile>
  )
}

export default ExcelClaimList;
