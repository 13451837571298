import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {Stack} from "@mui/material";
import { useProps } from "../../contexts";
//Component
import Breadcrumb from "../common/breadcrumb";
import TabsetShop from "./tabset-shop";
import HorizontalLinearStepper from "../common/stapper";
import ProductListShop from "./list-product-shop";
import AddStockModal from "../common/modal/add-stock-modal";
import DeleteShopPopUp from "../common/modal/delete-shop-modal";
import StockTransactionList from "./list-stock-transaction";
import {
  handleAddShopProductAPI,
  handleCompleteShopAPI,
  handleDeleteShopAPI,
  handleDeliveredShopAPI,
  handleGetShopAvailableICAPI,
  handleGetShopDetailsAPI,
  handleModifyShopProductAPI,
  handleSuspendShopAPI,
  handleVerifyShopAPI
} from "../../controller/shop";
import {setStateHelper, setShopVerifyStatusTag, setStockStatusTag} from "../../utils";
import DeletePopUp from "../common/modal/DeletePopUp";
//Controller


const ShopDetails = () => {
  const { shopID } = useParams();
  const history = useHistory();
  const { pathname } = useLocation();
  const { _handleChange } = useProps();
  const addStockModalRef = useRef(null);
  const deleteModalRef = useRef(null);
  const deleteShopPopUpRef = useRef(null);
  const [ refreshData, setRefreshData ] = useState(null);
  const [ mainState, setMainState ] = useState({
    shop: null,
    SSMPartner: null,
    SSM: null,
    shopPhotos: null
  })
  const isVerifyShop = pathname.includes('verify-details-shop')

  const _handleGetShopDetails = () => {
    handleGetShopDetailsAPI(setStateHelper(setMainState), shopID, _handleChange)
  };

  const _handleVerifyShop = () => {
    handleVerifyShopAPI(shopID, _handleChange).then((isSuccess) => {
      if (isSuccess) {
        setRefreshData(Math.random())
      }
    });
  }

  const _handleCompleteShop = () => {
    handleDeliveredShopAPI(shopID, _handleChange).then(() => {
      handleCompleteShopAPI(shopID, _handleChange).then((isSuccess) => {
        if (isSuccess) {
          setRefreshData(Math.random())
        }
      });
    })
  }

  const handleOpenSuspendPopUp = async () => {

    const {data, success} = await handleGetShopAvailableICAPI({
      ID: shopID,
      changeContext: _handleChange
    })

    if (success) {
      const list = data.map((e, i) => ({ ID: i+1, icNumber: e }))
      deleteModalRef.current.handleOpen();
      deleteModalRef.current.handleSetICList(list);
    }
  }

  const _handleSuspendShop = () => {

    handleSuspendShopAPI(shopID, deleteModalRef.current.newIC, _handleChange).then((isSuccess) => {
      if (isSuccess) {
        history.push('/shop/list-shop')
      }
    });
  }

  const handleOpenDeletePopUp = () => {
    deleteShopPopUpRef.current.setMainState({ isOpen: true, deleteID: 0, itemName: "" });
  };

  const _handleDeleteShop = () => {
    handleDeleteShopAPI(shopID, _handleChange).then((isSuccess) => {
      if (isSuccess) {
        deleteShopPopUpRef.current.setMainState({ isOpen: false})
        history.goBack();
      }
    });
  }


  const _handleAddShopProductAPI = (e) => {
    handleModifyShopProductAPI(e, shopID, _handleChange).then((isSuccess) => {
      if (isSuccess) {
        addStockModalRef.current.handleClose();
        setRefreshData(Math.random());
      }
    })
  };


  useEffect(() => {
    _handleGetShopDetails();
  }, [refreshData]);

  const verifyDiv = () => {
    return (
      <Card>
        <CardHeader>
          <h5>Verify Status</h5>
        </CardHeader>
        <CardBody>
           <HorizontalLinearStepper props={mainState.shop} handleVerify={_handleVerifyShop} handleCompleteShop={_handleCompleteShop}/>
        </CardBody>
      </Card>
    )
  }

  return (
    <Fragment>
      <Breadcrumb title={"Edit Shop"} parent="Shop" />
      <Container fluid={true}>
        {
          isVerifyShop && verifyDiv()
        }
        <Card>
          <CardHeader>
            <div className="customize-header-bottom-container">
              <h5>Account Details</h5>
              {mainState.shop?.status !== 6 &&
                <Button
                  type="button"
                  color="secondary"
                  outline
                  style={{marginRight: 10}}
                  onClick={() => {
                    isVerifyShop ? handleOpenDeletePopUp() : handleOpenSuspendPopUp();
                  }}
                >
                  { isVerifyShop ? 'Delete Shop' : 'Suspend Shop'}
                </Button>
              }
            </div>
          </CardHeader>
          <CardBody>
            <TabsetShop
              shopID={shopID}
              shopGeneralDetails={mainState}
              isVerifyShop={isVerifyShop}
            />
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <div className="customize-header-bottom-container">
              <Stack direction='row' alignItems='center'>
                <h5>Shop Products</h5>
                <div style={{ marginLeft: 15 }}>
                  {setStockStatusTag(mainState.shop?.stockStatus)}
                </div>
              </Stack>
              {mainState.shop?.status !== 6 &&
                <Button
                  type="button"
                  color="primary"
                  disabled={isVerifyShop}
                  onClick={() => {
                    addStockModalRef.current.setMainState({ shopID: shopID, isOpen: true });
                  }}
                >
                  Adjust Stock
                </Button>
              }
            </div>
          </CardHeader>
          <CardBody>
            <ProductListShop
              isRefresh={refreshData}
              shopID={shopID}
            />
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <h5>Stock Transaction History</h5>
          </CardHeader>
          <CardBody>
            {/* <PVPointUser
              userID={userID}
            /> */}
            <StockTransactionList
              isRefresh={refreshData}
              shopID={shopID}
            />
          </CardBody>
        </Card>
        {
          !isVerifyShop && verifyDiv()
        }
      </Container>
      <AddStockModal ref={addStockModalRef} handleSubmit={(e) => {_handleAddShopProductAPI(e)}} />
      <DeletePopUp ref={deleteShopPopUpRef} handleDelete={()=>_handleDeleteShop()} />
      <DeleteShopPopUp ref={deleteModalRef} title={'Shop'} handleDelete={_handleSuspendShop}/>
    </Fragment>
  );
};

export default ShopDetails;
