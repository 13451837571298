import useCheckIsValidation from "../../hooks/useCheckIsValidation";
import { createAdmin, deleteAdmin, getAdminList, updateAdmin } from "../../services/admin";
import { apiMiddleware } from "../../utils/middleware";
import { comparePassword, isEmpty } from "../../utils/validation";

const contextParams = {
  snackbar: true,
  snackbarType: 1,
  snackbarContent: "",
};

export const handleCreateAdminAPI = async (mainState, changeContext) => {
  const body = {
    firstName: mainState.firstName,
    lastName: mainState.lastName,
    email: mainState.email,
    username: mainState.username,
    password: mainState.password,
    // confirmPassword: mainState.confirmPassword,
    // status: mainState.status,
    role: mainState.isSuperAdmin ? 1 : 2,
  };
  const verifyArr = [
    isEmpty(body.firstName, "First Name"),
    isEmpty(body.lastName, "Last Name"),
    isEmpty(body.email, "Email"),
    isEmpty(body.username, "Username"),
    // comparePassword(body.password, mainState.confirmPassword, false),
  ];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isValidate = useCheckIsValidation(verifyArr.reverse(), changeContext);
  if (!isValidate) return false;

  const { data, success } = await apiMiddleware(changeContext, createAdmin, body);
  if (success) {
    contextParams.snackbarType = 0;
    contextParams.snackbarContent = `Create Successful!`;
  }
  changeContext(contextParams);
  return success;
};

export const handleGetAdminListAPI = async ({
  page,
  limit,
  order,
  orderBy,
  search,
  setMainState,
  changeContext,
}) => {
  const apiParams = {
    page,
    limit,
    order,
    orderBy,
    search,
  };

  const { data, success } = await apiMiddleware(changeContext, getAdminList, apiParams);
  if (success) {
    setMainState({ list: data.list, total: data.total });
  }
  return success;
};

export const handleUpdateAdminAPI = async (mainState, adminID, changeContext) => {
  const body = {
    firstName: mainState.firstName,
    lastName: mainState.lastName,
    email: mainState.email,
    username: mainState.username,
    password: mainState.password,
    isResetPassword: mainState.isResetPassword,
    // confirmPassword: mainState.confirmPassword,
    status: parseInt(mainState.status),
    role: parseInt(mainState.role),
  };

  const verifyArr = [
    isEmpty(body.firstName, "First Name"),
    isEmpty(body.lastName, "Last Name"),
    isEmpty(body.email, "Email"),
    body.isResetPassword ? comparePassword(body.password, mainState.confirmPassword, false) : null,
  ];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isValidate = useCheckIsValidation(verifyArr.reverse(), changeContext);

  if (!isValidate) return false;
  const { data, success } = await apiMiddleware(changeContext, updateAdmin, adminID, body);
  if (success) {
    contextParams.snackbarType = 0;
    contextParams.snackbarContent = `Update Successful!`;
  }
  changeContext(contextParams);
  return success;
};

export const handleDeleteAdminAPI = async (id, changeContext) => {
  const { data, success } = await apiMiddleware(changeContext, deleteAdmin, id);
  if (success) {
    contextParams.snackbarType = 0;
    contextParams.snackbarContent = `Delete Successful!`;
  }
  changeContext(contextParams);
  return success;
};
