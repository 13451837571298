import useCheckIsValidation from "../../hooks/useCheckIsValidation";
import {
  getProduct,
  createProduct,
  deleteProduct,
  getProductList,
  updateProduct, uploadImage,
} from "../../services/product";
import { apiMiddleware } from "../../utils/middleware";
import {isArrayEmpty, isEmpty} from "../../utils/validation";
import {getCategoryList} from "../../services/product/category";

const contextParams = {
  snackbar: true,
  snackbarType: 1,
  snackbarContent: "",
};

export const handleCreateProductAPI = async (mainState, changeContext) => {
  const body = {
    title: mainState.title,
    description: mainState.description,
    details: mainState.details,
    price: parseFloat(mainState.price),
    discountPrice: parseFloat(mainState.discountPrice),
    productCode: mainState.code,
    pvPoint: parseFloat(mainState.pvPoint),
    stock: parseFloat(mainState.stock),
    weight: mainState.weight,
    height: mainState.height,
    width: mainState.width,
    length: mainState.length,
    returnGolden: mainState.returnGolden,
    returnPlatinum: mainState.returnPlatinum,
    status: mainState.status,
    photos: mainState.photos.map(e => e.ID),
    category: mainState.category,
    discountMethod: parseInt(mainState.discountMethod),
    discountQuantity: parseFloat(mainState.discountQuantity),
    discountType: parseInt(mainState.discountType),
    discountAmount: parseFloat(mainState.discountAmount),
    discountPV: parseFloat(mainState.discountPV)
  };

  const verifyArr = [
    isEmpty(body.title, "Title"),

    isEmpty(body.price, "Price"),
    isEmpty(body.stock, "Stock"),
    isEmpty(body.productCode, "Product Code"),
    isArrayEmpty(body.category, "Category"),
  ];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isValidate = useCheckIsValidation(verifyArr.reverse(), changeContext);
  if (!isValidate) return false;

  const { success } = await apiMiddleware(changeContext, createProduct, body);
  if (success) {
    contextParams.snackbarType = 0;
    contextParams.snackbarContent = `Create Successful!`;
  }
  changeContext(contextParams);
  return success;
};

export const handleGetProductListAPI = async ({
  page,
  limit,
  order,
  orderBy,
  search,
  status,
  categoryID,
  setMainState,
  changeContext,
}) => {
  const apiParams = {
    page,
    limit,
    order,
    orderBy,
    search,
    // status,
    // categoryID,
  };

  const { data, success } = await apiMiddleware(changeContext, getProductList, apiParams);
  console.log(data)
  if (success) {
    setMainState({ list: data.list, total: data.total });
  }
  return success;
};

export const handleGetProductAPI = async (productID, setMainState, changeContext) => {
  const { data, success } = await apiMiddleware(changeContext, getProduct, productID);

  if (success) {
    setMainState({
      ...data,
      category: data.category.map(e => e.ID),
      initialDetails: data.details,
      initialDescription: data.description,
      initialCategory: data.category,
      checked: !!data.discountAmount
    });
  }
  return success;
};

export const handleGetCategoriesAPI = async (setMainState, changeContext) => {
  const { data, success } = await apiMiddleware(changeContext, getCategoryList);

  if (success) {
    setMainState({ categoryList: data.map(e => ({ID: e.ID, title: e.title}))});
  }
  return success;
}

export const handleUpdateProductAPI = async (mainState, productID, changeContext) => {
  const body = {
    title: mainState.title,
    details: mainState.details,
    description: mainState.description,
    price: parseFloat(mainState.price),
    discountPrice: parseFloat(mainState.discountPrice),
    productCode: mainState.code,
    pvPoint: parseFloat(mainState.pvPoint),
    stock: parseFloat(mainState.stock),
    weight: mainState.weight,
    height: mainState.height,
    width: mainState.width,
    length: mainState.length,
    returnGolden: mainState.returnGolden,
    returnPlatinum: mainState.returnPlatinum,
    status: mainState.status,
    photos: mainState.photos.map(e => e.ID),
    category: mainState.category,
    discountMethod: parseInt(mainState.discountMethod),
    discountQuantity: parseFloat(mainState.discountQuantity),
    discountType: parseInt(mainState.discountType),
    discountAmount: parseFloat(mainState.discountAmount),
    discountPV: parseFloat(mainState.discountPV)
  };
  const verifyArr = [
    isEmpty(body.title, "Title"),
    // isEmpty(body.description, "Product Information"),
    isEmpty(body.price, "Price"),
    isEmpty(body.stock, "Stock"),
    isArrayEmpty(body.category, "Category"),
  ];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isValidate = useCheckIsValidation(verifyArr.reverse(), changeContext);

  if (!isValidate) return false;

  const { success } = await apiMiddleware(changeContext, updateProduct, productID, body);
  if (success) {
    contextParams.snackbarType = 0;
    contextParams.snackbarContent = `Update Successful!`;
  }
  changeContext(contextParams);
  return success;
};

export const handleDeleteProductAPI = async (id, changeContext) => {
  const { success } = await apiMiddleware(changeContext, deleteProduct, id);
  if (success) {
    contextParams.snackbarType = 0;
    contextParams.snackbarContent = `Delete Successful!`;
  }
  changeContext(contextParams);
  return success;
};

export const handleUploadProductImage = async (files, mainState, setMainState, changeContext) => {
  const formData = new FormData();

  files.forEach(file => {
    formData.append("files", file);
  });

  const { data, success } = await apiMiddleware(changeContext, uploadImage, formData);

  if (success) {
    const newPhotos = [...mainState.photos]
    for (const item of data) {
      newPhotos.push(item)
    }
    setMainState({ photos: newPhotos })
  }

  return success;
}
