import React, { Fragment, useState, useEffect, useRef } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Form } from "reactstrap";
import { useProps } from "../../contexts";
import { handleGetUserCommissionLineDetailsAPI } from "../../controller/user";
import useQuery from "../../hooks/useQuery";
import { setStateHelper, setTargetStatusTag, setClaimStatusTag } from "../../utils";
import MonthlyDataPicker from "../common/data-month-picker";
import Datatable from "../common/datatable";
import TooltipIcon from "../common/tooltips-icon";
import moment from 'moment';

// const header = [
//   { id: "id", label: "", align: "center" },
//   // { id: "photo", label: "Images", align: "center" },
//   { id: "date", label: "Date", align: "left" },
//   { id: "firstLayerCommission", label: "First Layer Point", align: "left" },
//   { id: "secondLayerCommission", label: "Second Layer Point", align: "left" },
//   { id: "shopLayerCommission", label: "Shop Point", align: "left" },
//   { id: "totalCommission", label: "Total Amount", align: "left" },
//   { id: "claimStatus", label: "Claim Status", align: "left" },
//   { id: "targetComplete", label:
//       <div className="table-header-tooltip-wrapper">Target Complete
//         <TooltipIcon text={
//           <div>
//             <div>✅ First Layer downline's personal sales more than 10 and total point hits target (green tag)</div>
//             <div>❌ First Layer downline's personal sales less than 10 and total point is not hit target (gray tag)</div>
//           </div>
//         }/>
//       </div>
//     , align: "left" },
//   { id: "edit", label: "", align: "center" },
// ];

const generateBody = (body, page, rowsPerPage) => {
  // const href = "/claims/details-claim/" ;
  return body.map((e, i) => {
    const _href = "/claims/details-claim/" + e.ID;
    return({
      id: <p style={{ fontWeight: "bold" }}>{(page - 1) * rowsPerPage + i + 1}.</p>,
      // photo: <img alt="" src={e.avtar} style={{ width: 50, height: 50 }} />,
      date: e.generateMonth,
      firstLayerCommission: e.firstLayerCommission,
      secondLayerCommission: e.secondLayerCommission,
      shopLayerCommission: e.shopLayerCommission,
      totalCommission: 'RM '+ e.totalCommission,
      claimStatus: setClaimStatusTag(e.claimStatus),
      targetComplete: setTargetStatusTag(e.targetComplete ? 1 : 2),
      edit: (
        <div>
          <a className="customize-a" href={_href}>
            <i
              className="fa fa-pencil"
              style={{
                width: 35,
                fontSize: 20,
                padding: 11,
                color: "rgb(40, 167, 69)",
                cursor: "pointer",
              }}
            />
          </a>
        </div>
      ),
    })
  });
};

const PVPointUser = ({userID, upgradeDirect}) => {
  const [query, setQuery] = useQuery();
  const { _handleChange } = useProps();
  const commissionModelRef = useRef();
  const [mainState, setMainState] = useState({
    list: [],
    page: query.page ? parseInt(query.page) : 1,
    limit: query.limit ? parseInt(query.limit) : 10,
    total: 10,
    order: query.order || "desc",
    orderBy: query.orderBy || "createdAt",
    search: "",
    refreshData: false,
    startDate: '2002-01',
    endDate: moment().format('YYYY-MM'),
  });


  const _handleGetCommissionList = () => {

    const queryObj = {
      page: mainState.search ? 1 : mainState.page,
      limit: mainState.limit,
      order: mainState.order,
      orderBy: mainState.orderBy,
      startDate: mainState.startDate,
      endDate: mainState.endDate,
    };
    const param = {
      ...queryObj,
      userID,
      changeContext: _handleChange,
      setMainState: setStateHelper(setMainState),
    };
    setQuery(queryObj);

    handleGetUserCommissionLineDetailsAPI(param);
  };

  const _handleSetDate = (e) => {
    const fromMonth = e.from.month < 10 ? '0' + e.from.month : e.from.month;
    const toMonth =e.to.month < 10 ? '0' + e.to.month : e.to.month;
    let from = e.from.year + "-" + fromMonth;
    let to = e.to.year + "-" + toMonth;
    setStateHelper(setMainState)({startDate: from, endDate: to});
  }


  useEffect(() => {
    _handleGetCommissionList();
  }, [mainState.page, mainState.order, mainState.limit, mainState.orderBy, mainState.startDate, mainState.endDate,JSON.stringify(...mainState.list)]);

  const body = generateBody(
    mainState.list,
    mainState.page,
    mainState.limit,
  );

  let header = [
    { id: "id", label: "", align: "center" },
    // { id: "photo", label: "Images", align: "center" },
    { id: "date", label: "Date", align: "left" },
    { id: "firstLayerCommission", label: "First Layer Point", align: "left" },
    { id: "secondLayerCommission", label: "Second Layer Point", align: "left" },
    { id: "shopLayerCommission", label: "Shop Point", align: "left" },
    { id: "totalCommission", label: "Total Amount", align: "left" },
    { id: "claimStatus", label: "Claim Status", align: "left" },
    { id: "targetComplete", label:
        <div className="table-header-tooltip-wrapper">Target Complete
          <TooltipIcon text={
            <div>
              <div>✅ First Layer downline's personal sales more than {upgradeDirect} and total point hits target (green tag)</div>
              <div>❌ First Layer downline's personal sales less than {upgradeDirect} and total point is not hit target (gray tag)</div>
            </div>
          }/>
        </div>
      , align: "left" },
    { id: "edit", label: "", align: "center" },
  ];

	return (
		<Fragment>
      <Tabs
      defaultIndex={mainState.tabIndex}
      onSelect={(e) => {
        setStateHelper(setMainState)({tabIndex: e})
      }}>
				<TabList className="nav nav-tabs customize-filter-tab-header">
					<Tab className="nav-link">Claim Details</Tab>
				</TabList>
				<TabPanel>
        <Form className="needs-validation user-add" noValidate="">
          <div className="permission-block">
            <div className="attribute-blocks">
              <div style={{display:'flex'}}>
                <MonthlyDataPicker
                  onChange={(e) => {_handleSetDate(e)}}
                  />
              </div>
              <Datatable
              // multiSelectOption={true}
                multiSelectOption={false}
                header={header}
                myData={body}
                order={mainState.order}
                orderBy={mainState.orderBy}
                setState={setStateHelper(setMainState)}
                // myData={data}
                rowsPerPage={mainState.limit}
                count={mainState.total}
                page={mainState.page}
                total={mainState.total}
                pagination={true}
                class="-striped -highlight"
              />
            </div>
          </div>
        </Form>
				</TabPanel>
			</Tabs>
      {/* <CommissionModal ref={commissionModelRef} handleSubmit={() => {}}/> */}
		</Fragment>
	);
};

export default PVPointUser;
