import fetchData from "../../utils/fetchData";

export function getClaimsList(params) {
  return fetchData({
    url: "/pv-point",
    method: "GET",
    params: params,
  });
}

export function getClaimListExcel(params) {
  return fetchData({
    url: "/pv-point/export",
    method: "GET",
    params: params,
  });
}

export function getClaim(claimID) {
  return fetchData({
    url: `/pv-point/${claimID}`,
    method: "GET",
  });
}

export function setClaim(formData, pointID) {
  return fetchData({
    url: `/pv-point/${pointID}/status`,
    method: "PUT",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
