import React, { Fragment, useState, useEffect, useRef } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useProps } from "../../contexts";
import useQuery from "../../hooks/useQuery";
import { setPriceFormat, setStateHelper } from "../../utils";
import Datatable from "../common/datatable";

const header_rate = [
  { id: "layer", label: "", align: "center", sortable: false },
  { id: "normalRate", label: "Commission Rate(%)", align: "left", sortable: false },
  { id: "targetPV", label: "Point Target", align: "left", sortable: false },
  { id: "targetRate", label: "Commission Target Rate(%)", align: "left", sortable: false },
];

const header = [
  { id: "layer", label: "", align: "center", sortable: false },
  { id: "point", label: "Total Point", align: "left", sortable: false },
  { id: "rate", label: "Commission Rate", align: "left", sortable: false },
  { id: "commission", label: "Amount(RM)", align: "left", sortable: false },
];

const generateBodyRate = (body) => {
  let arr = body.map((e, i) => ({
    layer: e.id,
    normalRate: e.normalRate + "%",
    targetPV: e.targetPV,
    targetRate: e.targetRate + "%",
  }));

  return arr;
};

const generateBody = (body, totalCommission) => {
  let arr = body.map((e, i) => ({
    layer: e.id,
    point: e.point,
    rate: e.rate + "%",
    commission: "RM " +  (e.commission === '-' ? '-' : setPriceFormat(e.commission)),
  }));

  arr.push({
    id: "",
    point: "",
    rate: <div className="f-w-600">Total:</div>,
    commission: <div className="f-w-600">RM {totalCommission}</div>,
  });

  return arr;
};

const DetailsSetCommission = ({ commissionDetails, rateDetails }) => {
  const [query, setQuery] = useQuery();
  const { _handleChange } = useProps();
  const [tabIndex, setTabIndex] = useState(0);
  const [mainState, setMainState] = useState({
    list: [],
    rateList: [],
    id: 0,
  });

  useEffect(() => {
    if (commissionDetails) {
      let list = [];
      list.push({ id: "1st Layer", ...commissionDetails.firstLayer });
      list.push({ id: "2nd Layer", ...commissionDetails.secondLayer });
      list.push({ id: "Shop", ...commissionDetails.shopLayer });

      let listRate = [];
      listRate.push({ id: "1st Layer", ...rateDetails.firstLayer });
      listRate.push({ id: "2nd Layer", ...rateDetails.secondLayer });
      listRate.push({ id: "Shop", ...rateDetails.shopLayer });

      setStateHelper(setMainState)({ list: list, rateList: listRate });
    }
  }, [commissionDetails, rateDetails]);

  const body = generateBody(mainState.list, commissionDetails.totalCommission);

  const bodyRate = generateBodyRate(mainState.rateList);

  return (
    <Fragment>
      <Tabs
        defaultIndex={tabIndex}
        onSelect={(e) => {
          setTabIndex(e);
        }}
      >
        <TabList className="nav nav-tabs customize-filter-tab-header">
          <Tab className="nav-link">Commission</Tab>
          <Tab className="nav-link">{"Rate & Mission"}</Tab>
        </TabList>
        <TabPanel>
          <Form className="needs-validation user-add" noValidate="">
            <Datatable
              header={header}
              myData={body}
              pageSize={10}
              pagination={false}
              class="-striped -highlight"
            />
          </Form>
        </TabPanel>
        <TabPanel>
          <Form className="needs-validation user-add" noValidate="">
            <Datatable
              header={header_rate}
              myData={bodyRate}
              pageSize={10}
              pagination={false}
              class="-striped -highlight"
            />

            <div style={{ marginTop: 20 }}>
              <span className="customize-commission-target-text">
              <div
                  style={{ marginTop: -2, fontSize: 14, color: 'black !important' }}
                >
                    Hit all target below to get Commission Target Rate(%) .
                </div>
                </span>
                </div>
            <div style={{ marginTop: 10, marginLeft: 5 }}>
              <span className="customize-commission-target-text">
                <i
                  className={
                    commissionDetails.downlineTargetHit >= commissionDetails.downlineTarget
                      ? "fa fa-check-circle-o customize-commission-green"
                      : "fa fa-times-circle-o customize-commission-red"
                  }
                  style={{
                    paddingRight: 10,
                    fontSize: 17,
                  }}
                >
                  {" "}
                </i>
                <div
                  className={
                    commissionDetails.downlineTargetHit >= commissionDetails.downlineTarget ? "customize-commission-green" : "customize-commission-red"
                  }
                  style={{ marginTop: -2 }}
                >
                    Total direct accounts order: {commissionDetails.downlineTargetHit} / {commissionDetails.downlineTarget}
                </div>

              </span>
            </div>
            <div style={{ marginTop: 10, marginLeft: 5 }}>
              <span className="customize-commission-target-text">
                <i
                  className={
                    commissionDetails.firstLayer.point >= rateDetails.firstLayer.targetPV
                      ? "fa fa-check-circle-o customize-commission-green"
                      : "fa fa-times-circle-o customize-commission-red"
                  }
                  style={{
                    paddingRight: 10,
                    fontSize: 17,
                  }}
                >
                  {" "}
                </i>
                <div
                  className={
                    commissionDetails.firstLayer.point >= rateDetails.firstLayer.targetPV ? "customize-commission-green" : "customize-commission-red"
                  }
                  style={{ marginTop: -2 }}
                >
                  1st Layer point hit {rateDetails.firstLayer.targetPV} point the target
                </div>

              </span>
            </div>

          </Form>
        </TabPanel>
      </Tabs>
    </Fragment>
  );
};

export default DetailsSetCommission;
