import React, { useEffect, useState, useImperativeHandle } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
//Component
import CustomTextInput from "../CustomTextInput";
import CustomSelector from "../custom-selector";
import { STATIC_VARIABLE } from "../../../constants/static-data";
import { getArrayObjByValue } from "../../../utils";
import { useProps } from "../../../contexts";

const EditAdminModal = React.forwardRef((props, ref) => {
  const { handleSubmit = () => {} } = props;
  const [mainState, _setMainState] = useState({
    id: 0,
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: 1,
    status: 1,
    isOpen: false,
    isResetPassword: false
  });

  const setMainState = (newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleTextInput = (event, key) => {
    setMainState({ [key]: event.target.value });
  };

  const handleClearData = () => {
    setMainState({
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      password: "",
      confirmPassword: "",
    });
  };

  const handleOpen = () => {
    setMainState({ isOpen: true });
  };

  const handleClose = () => {
    setMainState({
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      password: "",
      confirmPassword: "",
      isOpen: false,
    });
  };

  const _handleSubmit = () => {
    const _password = mainState.isResetPassword ? mainState.password : "";
    const _confirmPassword = mainState.isResetPassword ? mainState.confirmPassword : "";
    const returnDetails = {
      firstName: mainState.firstName,
      lastName: mainState.lastName,
      email: mainState.email,
      username: mainState.username,
      status: mainState.status,
      role: mainState.role,
      isResetPassword: mainState.isResetPassword,
      password: _password,
      confirmPassword: _confirmPassword,
    };
    handleSubmit(returnDetails, mainState.id);
  };

  const handleCheck = (e) => {
    setMainState({
      ...mainState,
      isResetPassword: e.target.checked
    })
  }

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleClose,
    setMainState,
  }));
  return (
    <Modal isOpen={mainState.isOpen} toggle={handleClose}>
      <ModalHeader toggle={handleClose}>
        <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          Edit Admin
        </h5>
      </ModalHeader>
      <ModalBody>
        {/* <Form> */}
        <Row>
          <Col xl="6">
            <FormGroup>
              <Label className="col-form-label">First Name :</Label>
              <CustomTextInput
                type="text"
                statusKey="firstName"
                value={mainState.firstName}
                handleChange={handleTextInput}
              />
              {/* <Input
              type="text"
              className="form-control"
              value={mainState.firstName}
              onChange={(event) => {
                handleTextInput(event, "firstName");
              }}
            /> */}
            </FormGroup>
            <FormGroup>
              <Label className="col-form-label">Last Name :</Label>
              <CustomTextInput
                type="text"
                statusKey="lastName"
                value={mainState.lastName}
                handleChange={handleTextInput}
              />
              {/* <Input
              type="text"
              className="form-control"
              value={mainState.lastName}
              onChange={(event) => {
                handleTextInput(event, "lastName");
              }}
            /> */}
            </FormGroup>
            
          </Col>
          <Col xl="6">
            <FormGroup>
              <Label className="col-form-label">Username :</Label>
              <CustomTextInput
                type="text"
                statusKey="username"
                disabled
                value={mainState.username}
                handleChange={handleTextInput}
              />
            </FormGroup>
            <FormGroup>
              <Label className="col-form-label">Email :</Label>
              <CustomTextInput
                type="text"
                statusKey="email"
                value={mainState.email}
                handleChange={handleTextInput}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xl="6">
            <FormGroup>
              <Label className="col-form-label">Role :</Label>
              <CustomSelector
                initStatus={getArrayObjByValue(STATIC_VARIABLE.roleArray, mainState.role)}
                options={STATIC_VARIABLE.roleArray}
                handleChange={(e) => {
                  setMainState({
                    role: e.value
                  })
                }}
              />
            </FormGroup>
          </Col>
          <Col xl="6">
            <FormGroup>
              <Label className="col-form-label">Status :</Label>
              <CustomSelector
                initStatus={getArrayObjByValue(STATIC_VARIABLE.adminStatusArray, mainState.status)}
                options={STATIC_VARIABLE.adminStatusArray}
                handleChange={(e) => {
                  setMainState({
                    status: e.value
                  })
                }}
              />
            </FormGroup>
          </Col>
        </Row>
       
        <div className="customize-divider"/>
        <Row>
          <Col xl="12">
            <FormGroup
              check
              inline
            >
              <Input 
                type="checkbox" 
                onChange={handleCheck}
                // defaultChecked={mainState.isResetPassword}
              />
              <Label check>
                Reset Password
              </Label>
            </FormGroup>
              <FormGroup>
                <Label className="col-form-label">Password :</Label>
                <CustomTextInput
                  disabled={!mainState.isResetPassword}
                  type="text"
                  statusKey="password"
                  value={mainState.password}
                  handleChange={handleTextInput}
                />
            </FormGroup>
            <FormGroup>
              <Label className="col-form-label">Confirm Password :</Label>
              <CustomTextInput
                disabled={!mainState.isResetPassword}
                type="text"
                statusKey="confirmPassword"
                value={mainState.confirmPassword}
                handleChange={handleTextInput}
              />
            </FormGroup>
          </Col>
        </Row>

        {/* </Form> */}
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="primary" onClick={_handleSubmit}>
          {"Save"}
        </Button>
        <Button
          type="button"
          color="secondary"
          outline
          onClick={() => {
            handleClearData();
            handleClose();
          }}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
});
export default EditAdminModal;
