import {
    Home,
    Box,
    DollarSign,
    Tag,
    UserPlus,
    UserCheck,
    BarChart,
    Settings,
    LogIn,
    Trello
} from 'react-feather';

export const MENUITEMS = [
    {
        path: '/dashboard', title: 'Dashboard', icon: Trello, type: 'link', badgeType: 'primary', active: false
    },
    {
        title: 'Products', icon: Box, type: 'sub', active: false, children: [
            { path: '/products/category', title: 'Category', type: 'link' },
            { path: '/products/product-list', title: 'Product List', type: 'link' },
            // { path: '/products/add-product', title: 'Product', type: 'link' },
            // {
            //     title: 'Physical', type: 'sub', active: false, children: [
            //         // { path: '/products/physical/category', title: 'Category', type: 'link' },
            //         // { path: '/products/physical/sub-category', title: 'Sub Category', type: 'link' },
            //         // { path: '/products/physical/product-list', title: 'Product List', type: 'link' },
            //         // { path: '/products/physical/product-detail', title: 'Product Detail', type: 'link' },
            //         { path: '/products/physical/add-product', title: 'Add Product', type: 'link' },
            //     ]
            // }
        ]
    },
    {
        title: 'Sales', icon: DollarSign, type: 'sub', active: false, children: [
            { path: '/sales/orders', title: 'Orders', type: 'link' },
            { path: '/sales/orders-shop', title: 'Shop Orders', type: 'link' },
            { path: '/sales/orders-admin', title: 'Admin Orders', type: 'link' },
            { path: '/sales/transactions', title: 'Transactions', type: 'link' },
        ]
    },
    {
        title: 'Vouchers', icon: Tag, type: 'sub', active: false, children: [
            { path: '/voucher/list-voucher', title: 'List Voucher', type: 'link' },
            // { path: '/voucher/create-voucher', title: 'Voucher', type: 'link' },
        ]
    },
    {
      title: 'Claims', icon: Settings, type: 'link', path: '/claim'
    },
    {
      title: 'Shop', icon: Home, type: 'sub', active: false, children: [
          { path: '/shop/list-verify-shop', title: 'Shop Verify List', type: 'link' },
          { path: '/shop/list-shop', title: 'Shop List', type: 'link' },
          // { path: '/users/create-user', title: 'Create User', type: 'link' },
      ]
    },
    {
        title: 'Users', icon: UserPlus, type: 'sub', active: false, children: [
            { path: '/users/list-user', title: 'User List', type: 'link' },
            // { path: '/users/create-user', title: 'Create User', type: 'link' },
        ]
    },
    {
        title: 'Admins', icon: UserCheck, type: 'sub', active: false, children: [
            { path: '/admins/list-admin', title: 'Admin List', type: 'link' },
            { path: '/admins/create-admin', title: 'Create Admin', type: 'link' },
        ]
    },
    {
        title: 'Settings', icon: Settings, type: 'sub', children: [
            { path: '/settings/profile', title: 'Profile', type: 'link' },
            { path: '/settings/config', title: 'Configuration', type: 'link' },
        ]
    },
    // {
    //     title: 'Logout',path:'/', icon: LogIn, type: 'link', active: false
    // }
]
