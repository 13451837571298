import useCheckIsValidation from "../../hooks/useCheckIsValidation";
import {
  addProduct,
  modifyProduct,
  getProductList,
  getShopDetails,
  getShopList,
  getShopProductList,
  getShopStockTransactionList,
  getShopVerifyList,
  shopCompleted,
  shopDelivered,
  shopRemove,
  shopSuspend,
  shopVerify,
  updateShopDetails,
  getAvailableIC, getShopExportList, updateProductStockLimit, getProductListWithShop
} from "../../services/shop";
import { apiMiddleware } from "../../utils/middleware";
import { isEmpty } from "../../utils/validation";
import async from "async";

const contextParams = {
  snackbar: true,
  snackbarType: 1,
  snackbarContent: "",
};

export const handleGetShopVerifyListAPI = async ({
  page,
  limit,
  order,
  orderBy,
  search,
  shopStatus,
  setMainState,
  changeContext,
}) => {
  const apiParams = {
    page,
    limit,
    order,
    orderBy,
    search,
    status: shopStatus
  };

  const { data, success } = await apiMiddleware(changeContext, getShopVerifyList, apiParams);
  if (success) {
    setMainState({ list: data.data, total: data.total });
  }
  return success;
};

export const handleGetShopListExportAPI = async ({
  order,
  orderBy,
  search,
  active,
  changeContext,
}) => {
  const apiParams = {
    page: 1,
    limit: 1,
    order,
    orderBy,
    search,
    active
  };

  return apiMiddleware(changeContext, getShopExportList, apiParams);
}

export const handleGetShopListAPI = async ({
  page,
  limit,
  order,
  orderBy,
  search,
  active,
  setMainState,
  changeContext,
}) => {
  const apiParams = {
    page,
    limit,
    order,
    orderBy,
    search,
    active
  };

  const { data, success } = await apiMiddleware(changeContext, getShopList, apiParams);
  if (success) {
    setMainState({ list: data.list, total: data.total });
  }
  return success;
};

export const handleAddShopProductAPI = async (products, shopID, changeContext) => {
  const body = {
    products: products,
  };

  const { data, success } = await apiMiddleware(changeContext, addProduct, body, shopID);
  if (success) {
    contextParams.snackbarType = 0;
    contextParams.snackbarContent = `Update Successful!`;
  }
  changeContext(contextParams);
  return success;
};

export const handleModifyShopProductAPI = async (products, shopID, changeContext) => {
  const body = {
    products: products,
  };

  const { data, success } = await apiMiddleware(changeContext, modifyProduct, body, shopID);
  if (success) {
    contextParams.snackbarType = 0;
    contextParams.snackbarContent = `Update Successful!`;
  }
  changeContext(contextParams);
  return success;
};



export const handleGetShopDetailsAPI = async (setMainState, id, changeContext) => {
  const { data, success } = await apiMiddleware(changeContext, getShopDetails, id);
  if (success) {
    setMainState(data);
  }
  return success;
}

export const handleVerifyShopAPI = async (id, changeContext) => {
  const { data, success } = await apiMiddleware(changeContext, shopVerify, id);
  if (success) {
    contextParams.snackbarType = 0;
    contextParams.snackbarContent = `Verify Successful!`;
  }
  changeContext(contextParams);
  return success;
}

export const handleDeliveredShopAPI = async (id, changeContext) => {
  const { data, success } = await apiMiddleware(changeContext, shopDelivered, id);
  if (success) {
    // contextParams.snackbarType = 0;
    // contextParams.snackbarContent = `Update Successful!`;
  }
  // changeContext(contextParams);
  return success;
}

export const handleCompleteShopAPI = async (id, changeContext) => {
  const { success } = await apiMiddleware(changeContext, shopCompleted, id);
  if (success) {
    contextParams.snackbarType = 0;
    contextParams.snackbarContent = `Update Successful!`;
  }
  changeContext(contextParams);
  return success;
}


export const handleSuspendShopAPI = async (id, icNumber, changeContext) => {
  const body = {icNumber}
  const { success } = await apiMiddleware(changeContext, shopSuspend, body, id);
  if (success) {
    contextParams.snackbarType = 0;
    contextParams.snackbarContent = `Suspend Successful!`;
  }
  changeContext(contextParams);
  return success;
}


export const handleDeleteShopAPI = async (id, changeContext) => {
  const { data, success } = await apiMiddleware(changeContext, shopRemove, id);
  if (success) {
    contextParams.snackbarType = 0;
    contextParams.snackbarContent = `Delete Successful!`;
  }
  changeContext(contextParams);
  return success;
}

export const handleUpdateShopAPI = async (mainState, shopID, changeContext) => {
  const body = {
    name: mainState.name,
    email: mainState.email,
    phoneNumber: mainState.phoneNumber,
    // address: mainState.address,
  };

  const verifyArr = [
    isEmpty(body.name, "Shop Name"),
    isEmpty(body.phoneNumber, "phoneNumber"),
    isEmpty(body.email, "Email"),
  ];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isValidate = useCheckIsValidation(verifyArr.reverse(), changeContext);

  if (!isValidate) return false;

  const { success } = await apiMiddleware(changeContext, updateShopDetails, shopID, body);
  if (success) {
    contextParams.snackbarType = 0;
    contextParams.snackbarContent = `Update Successful!`;
  }
  changeContext(contextParams);
  return success;
};


export const handleGetShopProductListAPI = async ({
  page,
  limit,
  order,
  orderBy,
  search,
  setLimitList,
  setMainState,
  changeContext,
  shopID
}) => {
  const apiParams = {
    page,
    limit,
    order,
    orderBy,
  };

  const { data, success } = await apiMiddleware(changeContext, getShopProductList, apiParams, shopID);
  if (success) {
    const limitList = data.list.map(e => e.stockLimit)
    setLimitList(limitList)
    setMainState({ list: data.list, total: data.total });
  }
  return success;
};


export const handleGetAllProductAPI = async (setMainState, changeContext) => {
  const { data, success } = await apiMiddleware(changeContext, getProductList);
  if (success) {
    setMainState(data.list);
  }
  return {isSuccess: success, list: data.list};
}

export const handleGetAllProductShopAPI = async (shopID, setMainState, changeContext) => {
  const { data, success } = await apiMiddleware(changeContext, getProductListWithShop, shopID);
  if (success) {
    setMainState(data.list);
  }
  return {isSuccess: success, list: data.list};
}

export const handleUpdateProductStockLimit = async ({
  stockID,
  value,
  changeContext,
}) => {
  const body = { value }
  const { success } = await apiMiddleware(changeContext, updateProductStockLimit, body, stockID);

  if (success) {
    contextParams.snackbarType = 0;
    contextParams.snackbarContent = `Update Successful!`;
    changeContext(contextParams);
  }
}

export const handleGetShopStockTransactionListAPI = async ({
  page,
  limit,
  order,
  orderBy,
  shopID,
  setMainState,
  changeContext,
}) => {
  const apiParams = {
    page,
    limit,
    order,
    orderBy,
  };

  const { data, success } = await apiMiddleware(changeContext, getShopStockTransactionList, apiParams, shopID);
  if (success) {
    setMainState({ list: data.list, total: data.total });
  }
  return success;
};

export const handleGetShopAvailableICAPI = async ({
  ID,
  changeContext
}) => {
  return apiMiddleware(changeContext, getAvailableIC, ID);
}
