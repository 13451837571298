import fetchData from "../../utils/fetchData";

export function getOrderListExport(params) {
  return fetchData({
    url: "/order/export",
    method: "GET",
    params: params,
  });
}

export function getOrderList(params) {
  return fetchData({
    url: "/order",
    method: "GET",
    params: params,
  });
}

export function getOrder(orderID) {
  return fetchData({
    url: `/order/${orderID}`,
    method: "GET",
  });
}

export function getShopOrderList(params) {
  return fetchData({
    url: "/order/shop",
    method: "GET",
    params: params,
  });
}

export function getShopOrderListExport(params) {
  return fetchData({
    url: "/order/shop/export",
    method: "GET",
    params: params,
  });
}

export function getOrderTransactionList(params) {
  return fetchData({
    url: "/transaction",
    method: "GET",
    params: params,
  });
}

export function updateOrderStatus(body, orderID) {
  return fetchData({
    url: `/order/${orderID}`,
    method: "PUT",
    data: body,
  });
}
