import {
  getOrder,
  getOrderList,
  getOrderListExport,
  getOrderTransactionList,
  getShopOrderList, getShopOrderListExport,
  updateOrderStatus
} from "../../services/order";
import { apiMiddleware } from "../../utils/middleware";

const contextParams = {
  snackbar: true,
  snackbarType: 1,
  snackbarContent: "",
};

export const handleGetOrderListExportAPI = async ({
  order,
  orderBy,
  search,
  orderStatus,
  startDate,
  endDate,
  memberID,
  changeContext
}) => {
  const apiParams = {
    page: 1,
    limit: 1,
    order,
    orderBy,
    search,
    orderStatus,
    startDate,
    endDate,
    memberID
  };
  return apiMiddleware(changeContext, getOrderListExport, apiParams);
}

export const handleGetUserOrderListAPI = async ({
  page,
  limit,
  order,
  orderBy,
  search,
  orderStatus,
  setMainState,
  changeContext,
  startDate,
  endDate,
  memberID
}) => {
  const apiParams = {
    page,
    limit,
    order,
    orderBy,
    search,
    orderStatus,
    startDate,
    endDate,
    memberID
  };

  const { data, success } = await apiMiddleware(changeContext, getOrderList, apiParams);
  if (success) {
    setMainState({ list: data.list, total: data.total });
  }
  return success;
};

export const handleGetUserOrderDetailsAPI = async (setMainState, id, changeContext) => {

  const { data, success } = await apiMiddleware(changeContext, getOrder, id);
  console.log('data', data)
  if (success) {
    setMainState({ ...data });
  }
  return success;
}

export const handleGetUserOrderDetailsExportAPI = async (id, changeContext) => {
  return apiMiddleware(changeContext, getOrder, id);
}

export const handleGetShopOrderListExportAPI = async ({
  order,
  orderBy,
  search,
  orderStatus,
  startDate,
  endDate,
  memberID,
  isAdminPurchase,
  changeContext,
}) => {
  const apiParams = {
    page: 1,
    limit: 1,
    order,
    orderBy,
    search,
    orderStatus,
    startDate,
    endDate,
    memberID,
    isAdminPurchase
  };
  return apiMiddleware(changeContext, getShopOrderListExport, apiParams)
}


export const handleGetShopOrderListAPI = async ({
  page,
  limit,
  order,
  orderBy,
  search,
  orderStatus,
  startDate,
  endDate,
  memberID,
  isAdminPurchase,
  setMainState,
  changeContext,
}) => {
  const apiParams = {
    page,
    limit,
    order,
    orderBy,
    search,
    orderStatus,
    startDate,
    endDate,
    memberID,
    isAdminPurchase
  };
  const { data, success } = await apiMiddleware(changeContext, getShopOrderList, apiParams);
  if (success) {
    setMainState({ list: data.list, total: data.total });
  }
  return success;
};

export const handleGetOrderTransactionListAPI = async ({
  page,
  limit,
  order,
  orderBy,
  search,
  setMainState,
  changeContext,
}) => {
  const apiParams = {
    page,
    limit,
    order,
    orderBy,
    search,
  };

  const { data, success } = await apiMiddleware(changeContext, getOrderTransactionList, apiParams);
  if (success) {
    setMainState({ list: data.data, total: data.total });
  }
  return success;
};


export const handleUpdateStatusAPI = async (mainState, orderID, changeContext) => {
  const body = {
    orderStatus: mainState.selectedOrderStatus,
    trackingID: mainState.trackingID || '',
  };

  const { success } = await apiMiddleware(changeContext, updateOrderStatus, body, orderID);
  if (success) {
    contextParams.snackbarType = 0;
    contextParams.snackbarContent = `Update Successful!`;
    changeContext(contextParams);
    return true;
  }
  else {
    contextParams.snackbarType = 1;
    contextParams.snackbarContent = `Update Fail!`;
    changeContext(contextParams);
    return false
  }

};
