import React, { useState, useEffect, Fragment } from "react";
import { Card, CardBody, Col, Container, Media, Row, Button } from "reactstrap";
import { useProps } from "../../contexts/index";
//Component
import TabsetProfile from "./tabset-profile";
import Breadcrumb from "../common/breadcrumb";
//Controller
import { handleGetUserDetailsAPI, handleUpdateDetailsAPI } from "../../controller/login";
//Assets
import designer from "../../assets/images/dashboard/designer.jpg";
import {deepOrange} from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";
import {Stack} from "@mui/material";

const Profile = () => {
  const { _handleChange } = useProps();
  const [mainState, _setMainState] = useState({
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    role: 0,
    status: 0,
    refreshData: false,
  });

  const firstUsername = mainState.username ? mainState.username[0].toUpperCase() : 'A'

  const setMainState = (newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleTextInput = (e, key) => {
    setMainState({ ...mainState, [key]: e.target.value });
  };

  const handleSubmit = () => {
    handleUpdateDetailsAPI(mainState, _handleChange).then((isSuccess) => {
      if (isSuccess) {
        setMainState({ ["refreshData"]: !mainState.refreshData });
      }
    });
  };

  useEffect(() => {
    handleGetUserDetailsAPI(setMainState, _handleChange);
  }, [mainState.refreshData]);

  return (
    <Fragment>
      <Breadcrumb title="Profile" parent="Settings" />
      <Container fluid={true}>
        <Row>
          <Col xl="4">
            <Card>
              <CardBody>
                <div className="profile-details text-center">
                  {/*<img src={designer} alt="" className="img-fluid img-90 rounded-circle blur-up lazyloaded" />*/}
                  <Stack justifyContent='center' alignItems='center' sx={{ my: 2 }}>
                    <Avatar sx={{ bgcolor: deepOrange[500] }}>{firstUsername}</Avatar>
                  </Stack>
                  <h5 className="f-w-600 f-16 mb-0">{mainState.username}</h5>
                  <span>{mainState.email}</span>
                  <div className="social">
                    <div className="form-group btn-showcase">
                      <Button color="btn social-btn btn-fb d-inline-block">
                        <i className="fa fa-facebook"></i>
                      </Button>
                      <Button color="btn social-btn btn-twitter d-inline-block">
                        <i className="fa fa-google"></i>
                      </Button>
                      <Button color="btn social-btn btn-google d-inline-block mr-0">
                        <i className="fa fa-twitter"></i>
                      </Button>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="project-status">
                  {/* <h5 className="f-w-600 f-16">Employee Status</h5>
									<Media>
										<Media body>
											<h6>
												Performance <span className="pull-right">80%</span>
											</h6>
											<div className="progress sm-progress-bar">
												<div
													className="progress-bar bg-primary"
													role="progressbar"
													style={{ width: "90%" }}
													aria-valuenow="25"
													aria-valuemin="0"
													aria-valuemax="100"
												></div>
											</div>
										</Media>
									</Media>
									<Media>
										<Media body>
											<h6>
												Overtime <span className="pull-right">60%</span>
											</h6>
											<div className="progress sm-progress-bar">
												<div
													className="progress-bar bg-secondary"
													role="progressbar"
													style={{ width: "60%" }}
													aria-valuenow="25"
													aria-valuemin="0"
													aria-valuemax="100"
												></div>
											</div>
										</Media>
									</Media>
									<Media>
										<Media body>
											<h6>
												Leaves taken <span className="pull-right">50%</span>
											</h6>
											<div className="progress sm-progress-bar">
												<div
													className="progress-bar bg-danger"
													role="progressbar"
													style={{ width: "50%" }}
													aria-valuenow="25"
													aria-valuemin="0"
													aria-valuemax="100"
												></div>
											</div>
										</Media>
									</Media> */}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="8">
            <Card className="profile-card">
              <CardBody>
                <TabsetProfile
                  data={mainState}
                  handleTextInput={handleTextInput}
                  handleSubmit={handleSubmit}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Profile;
