import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";
import { useProps } from "../../contexts";
//hooks
import useQuery from "../../hooks/useQuery";
//Component
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../common/datatable";
import DeletePopUp from "../common/modal/DeletePopUp";
import CreateUserModal from "../common/modal/CreateUserModal";
//Controller
import {
  handleCreateAdminAPI,
  handleDeleteAdminAPI,
  handleGetAdminListAPI,
  handleUpdateAdminAPI,
} from "../../controller/admin";

import data from "../../assets/data/listUser";
import EditAdminModal from "../common/modal/EditAdminModal";
import { getArrayObjByValue } from "../../utils";
import { STATIC_VARIABLE } from "../../constants/static-data";

const generateBody = (body, page, rowsPerPage, handleEdit, handleDelete = () => {}) => {
  return body.map((e, i) => ({
    id: <p style={{ fontWeight: "bold" }}>{(page - 1) * rowsPerPage + i + 1}.</p>,
    // photo: <img alt="" src={e.avtar} style={{ width: 50, height: 50 }} />,
    username: e.username,
    firstName: e.firstName,
    lastName: e.lastName,
    email: e.email,
    role: getArrayObjByValue(STATIC_VARIABLE.roleArray, e.role).label,
    edit: (
      <div>
        <span onClick={() => handleDelete(e.ID, e.firstName + " " + e.lastName)}>
          <i
            className="fa fa-trash"
            style={{
              width: 35,
              fontSize: 20,
              padding: 11,
              color: "#e4566e",
              cursor: "pointer",
            }}
          ></i>
        </span>

        <span>
          <i
            onClick={() => handleEdit(e)}
            className="fa fa-pencil"
            style={{
              width: 35,
              fontSize: 20,
              padding: 11,
              color: "rgb(40, 167, 69)",
              cursor: "pointer",
            }}
          ></i>
        </span>
      </div>
    ),
  }));
};

const header = [
  { id: "id", label: "", align: "center" },
  // { id: "photo", label: "Images", align: "center" },
  { id: "email", label: "Email", align: "left" },
  { id: "username", label: "Username", align: "left" },
  { id: "firstName", label: "First Name", align: "left" },
  { id: "lastName", label: "Last Name", align: "left" },
  { id: "role", label: "Role", align: "center" },
  { id: "edit", label: "Action", align: "center" },
];

const ListAdmin = () => {
  const [query, setQuery] = useQuery();
  const history = useHistory();
  const { _handleChange } = useProps();
  const deleteUserPopUpRef = useRef();
  const editAdminPopUpRef = useRef();

  const [mainState, _setMainState] = useState({
    list: [],
    page: query.page ? parseInt(query.page) : 1,
    limit: query.limit ? parseInt(query.limit) : 10,
    total: 10,
    order: query.order || "desc",
    orderBy: query.orderBy || "createdAt",
    search: "",
    refreshData: false,
  });
  const setMainState = (newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleOpenCreateUserPopUp = () => {
    editAdminPopUpRef.current.setMainState({
      id: 0,
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      isOpen: true,
      isCreate: true,
    });
  };

  const handleRefreshData = () => {
    setMainState({ refreshData: !mainState.refreshData });
  };

  const handleOpenEditPopUp = (details) => {
    const detailsParams = {
      id: details.ID,
      username: details.username,
      firstName: details.firstName,
      lastName: details.lastName,
      email: details.email,
      role: details.role,
      status: details.status,
      isOpen: true,
    };
    editAdminPopUpRef.current.setMainState(detailsParams);
  };

  const handleOpenDeletePopUp = (id, itemName) => {
    deleteUserPopUpRef.current.setMainState({ isOpen: true, deleteID: id, itemName: itemName });
  };

  const handleSubmit = (details, adminID) => {
    handleUpdateAdminAPI(details, adminID, _handleChange).then((isSuccess) => {
      if (isSuccess) {
        handleRefreshData();
        editAdminPopUpRef.current.handleClose();
      }
    });
  };

  const handleDelete = (id) => {
    handleDeleteAdminAPI(id, _handleChange).then((isSuccess) => {
      if (isSuccess) {
        handleRefreshData();
        deleteUserPopUpRef.current.handleClose();
      }
    });
  };

  const _handleGetAdminList = () => {
    const queryObj = {
      page: mainState.search ? 1 : mainState.page,
      limit: mainState.limit,
      order: mainState.order,
      orderBy: mainState.orderBy,
      search: mainState.search,
    };
    const param = {
      ...queryObj,
      changeContext: _handleChange,
      setMainState: setMainState,
    };
    setQuery(queryObj);
    handleGetAdminListAPI(param);
  };

  const body = generateBody(
    mainState.list,
    mainState.page,
    mainState.limit,
    handleOpenEditPopUp,
    handleOpenDeletePopUp
  );

  useEffect(() => {
    _handleGetAdminList();
  }, [mainState.page, mainState.order, mainState.orderBy, mainState.refreshData, mainState.limit]);

  return (
    <Fragment>
      <Breadcrumb title="Admin List" parent="Admin" />
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <h5>Admin Details</h5>
          </CardHeader>
          <CardBody>
            <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
              <Datatable
                // multiSelectOption={true}
                multiSelectOption={false}
                header={header}
                myData={body}
                // myData={data}
                pageSize={10}
                pagination={true}
                class="-striped -highlight"
              />
              <DeletePopUp ref={deleteUserPopUpRef} handleDelete={handleDelete} />
            </div>
            <EditAdminModal ref={editAdminPopUpRef} handleSubmit={handleSubmit} />
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default ListAdmin;
