import fetchData from "../../../utils/fetchData";

export function createCategory(body) {
  return fetchData({
    url: "product-category",
    method: "POST",
    data: body,
  });
}

export function getCategoryList(params) {
  return fetchData({
    url: "/product-category",
    method: "GET",
    params: params,
  });
}

export function getCategoryListPagination(params) {
  return fetchData({
    url: "/product-category-pagination",
    method: "GET",
    params: params,
  });
}

export function getCategory(categoryID) {
  return fetchData({
    url: `/product-category/${categoryID}`,
    method: "GET",
  });
}

export function updateCategory(categoryID, body) {
  return fetchData({
    url: `/product-category/${categoryID}`,
    method: "PUT",
    data: body,
  });
}

export function deleteCategory(categoryID) {
  return fetchData({
    url: `/product-category/${categoryID}`,
    method: "DELETE",
  });
}
