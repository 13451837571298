import React, { Fragment, useState, useEffect, useRef, useCallback } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { STATIC_VARIABLE } from "../../constants/static-data";
import { useProps } from "../../contexts";
import { handleGetShopDetailsAPI, handleUpdateShopAPI } from "../../controller/shop";
import useQuery from "../../hooks/useQuery";
import {getArrayObjByValue, setBenefitStatusTag, setStateHelper, setYesNoTag} from "../../utils";
import SSMModal from "../common/modal/ssm-modal";
import {Stack} from "@mui/material";

const TabsetShop = ({ shopID, shopGeneralDetails, isVerifyShop}) => {
  const { _handleChange } = useProps();
  const ssmModalRef = useRef(null);
  const [isFirstTime, setIsFirstTime] = useState(false);
  const [generalMainState, setGeneralMainState] = useState({
    ID: 0,
    shopCode: "",
    userID: 1,
    username: "",
    name: "",
    phoneNumber: "",
    email: "",
    pvPoint: 0,
    maintainTarget: {
      monthPV: 0,
      shopMaintainPV: 0,
      shopStatus: 1,
    },
    SSMID: "",
    isBeautician: 2,
    stock: 0,
    status: 1,
    address: {
      address1: "Shop Address 1",
      address2: "Shop Address 2",
      postalCode: 12344,
      city: "Kulai",
      state: "Johor",
      country: "Malaysia",
    },
  })

  const [docMainState, setDocMainState] = useState({
    SSM: [],
    SSMPartner: [],
    shopPhotos: []
  })

  const _handleUpdateShopDetails = () => {
    handleUpdateShopAPI(generalMainState, shopID, _handleChange)
  }

  useEffect(() => {

    if (!isFirstTime && shopGeneralDetails.shop && shopGeneralDetails.SSM && shopGeneralDetails.SSMPartner) {
      if (shopGeneralDetails.shop) {
        setGeneralMainState(shopGeneralDetails.shop);
      }

      if(shopGeneralDetails.SSM && shopGeneralDetails.SSMPartner && shopGeneralDetails.shopPhotos) {
        setDocMainState({
          SSM: shopGeneralDetails.SSM,
          SSMPartner: shopGeneralDetails.SSMPartner,
          shopPhotos: shopGeneralDetails.shopPhotos,
        })
      }

      setIsFirstTime(true);
    }
  }, [shopGeneralDetails]);

  const _addressCombine = (obj) => {
    let address =
      obj.address1 + "," + obj.address2 + ",\n" + obj.postalCode + " " + obj.city + " " + obj.state + " " + obj.country;
    return address;
  };

  const FormDiv = useCallback(({value, label, type, id, disabled=false, setState, redirectID, docMainState}) => {
    let input = (
      <Input
        className="form-control col-xl-8 col-md-7"
        id={id}
        type={type}
        disabled={disabled}
        required=""
        value={value}
        onChange={(e) => {
          setStateHelper(setState)({[e.target.id]: e.target.value})
        }}
      />
    );
    if (type == "link") {
      let _onClick = () => {};
      if (id === "SSMID") {
        _onClick = () => {
          ssmModalRef.current.setMainState({
            isOpen: true,
            fileObj: docMainState.SSM,
            partnerArr: docMainState.SSMPartner,
            shopPhotos: docMainState.shopPhotos,
          })
        };

        input = (
          <a className={"customize-a SSMID-padding"}  onClick={_onClick}>
            {value}
          </a>
        );
      }

      if(id == 'username') {
        const _href= redirectID ? `/users/details-user/${redirectID}` : '#';
        input = (
          <a className={"customize-a SSMID-padding"}   href={_href}>
            {value}
          </a>
        );
      }

    }
    return (
      <FormGroup className="row">
        <Label className="col-xl-3 col-md-4 col-6">
          <span>*</span> {label}
        </Label>
        {input}
      </FormGroup>
    );
  },[]);
  return (
    <Fragment>
      <Tabs
        defaultIndex={0}
      >
        <TabList className="nav nav-tabs tab-coupon">
          <Tab className="nav-link">General</Tab>
        </TabList>
        <TabPanel>
          <Form className="needs-validation user-add" noValidate="">
            <Row>
              <Col xl="6">
                <FormDiv value={'#' + generalMainState.userID + '-'+ generalMainState.username} label={"Account"} id={"username"} type={"link"} disabled={true} setState={setGeneralMainState} redirectID={generalMainState.userID} />
                <FormDiv value={generalMainState.name} label={"Shop Name"} id={"name"} type={"text"} setState={setGeneralMainState}/>
                <FormDiv value={generalMainState.SSMID} label={"SSM/Cert ID"} id={"SSMID"} type={"link"} disabled={true} docMainState={docMainState}/>
                <FormDiv value={generalMainState.shopCode} label={"Shop Code"} id={"referralCode"} type={"text"} disabled={true} />
                <FormDiv value={generalMainState.pvPoint} label={"Shop Point"} id={"pvPoint"} type={"text"} disabled={true} />

              </Col>
              <Col xl="6">
                <FormGroup className="row">
                  <Label className="col-xl-3 col-md-4 col-6">
                    <span>*</span> Is Beautician
                  </Label>

                  {setYesNoTag(generalMainState.isBeautician)}
                </FormGroup>
                <FormDiv value={getArrayObjByValue(STATIC_VARIABLE.shopStatusArray, generalMainState.status).label} label={"Shop Account Status"} id={"status"} type={"phone"} disabled={true} />
                <FormDiv value={generalMainState.phoneNumber} label={"Phone Number"} id={"phoneNumber"} type={"phone"} disabled={true} />
                <FormDiv value={_addressCombine(generalMainState.address)}  label={"Shop Address"} id={"address"} type={"textarea"} disabled={true} />
                <FormGroup className="row">
                  <Label className="col-xl-3 col-md-4 col-6">
                    <span>*</span> Benefit Status
                  </Label>
                  <Stack className="form-control col-xl-8 col-md-7" direction='row' alignItems='center' justifyContent='space-between'>
                    <div>
                      {generalMainState.maintainTarget.monthPV} / {generalMainState.maintainTarget.shopMaintainPV}
                    </div>
                    {setBenefitStatusTag(generalMainState.maintainTarget.shopStatus)}
                  </Stack>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </TabPanel>
      </Tabs>
      <div className="pull-right">
        {generalMainState.status !== 6 &&
          <Button type="button" color="primary" disabled={isVerifyShop} onClick={_handleUpdateShopDetails}>
            Update
          </Button>
        }
      </div>
      <SSMModal ref={ssmModalRef} handleSubmit={() => {}} />
    </Fragment>
  );
};

export default TabsetShop;
