import React, {useState} from "react";
import moment from "moment";
// import {themeColor} from '../contexts/themeColor';
import { PDFViewer, Font, Page, Text, View, Image, Document, StyleSheet } from '@react-pdf/renderer';

import boldFont from '../../assets/fonts/AnyConv.com__Inter-Bold.ttf';
import regularFont from '../../assets/fonts/AnyConv.com__Inter-Regular.ttf';
import normalFont from '../../assets/fonts/AnyConv.com__Inter-Medium.ttf';
import extraBold from '../../assets/fonts/AnyConv.com__Inter-ExtraBold.ttf';

// Images
import Logo from "../../assets/images/vose-logo.png"
import { setPriceFormat } from "../../utils";

Font.register({ family: 'Inter-ExtraBold', src: extraBold });
Font.register({ family: 'Inter-Bold', src: boldFont });
Font.register({ family: 'Inter-Regular', src: regularFont });
Font.register({ family: 'Inter-Normal', src: normalFont });

const styles = StyleSheet.create({
  body: {
    padding: '40px',
  },
  image: {
    width: '70px',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  marginRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    margin: 0,
    padding: '0 15px'
  },
  paddingRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '0 15px',
  },
  logoWrapper: {
    marginTop: '-15px',
    margin: 0,
    display: 'flex',
    // flexDirection: 'row',
  },
  logo:{
    width: 150,
  },
  addressBox: {
    width: '50%',
    padding: '10px',
    borderColor: 'gray',
    borderRightWidth: 1,
  },
  table: {
    marginTop: 10,
    paddingBottom: 5,
    // padding: '0px 10px',
    borderColor: 'gray',
    borderWidth: 1,
    borderBottomWidth: 0,
  },
  sectionProduct: {
    marginBottom: 10,
    borderColor: 'gray',
    borderBottomWidth: 1,
    borderTopWidth: 1,
  },
  sectionInfo: {
    padding: '0px 10px',
    borderColor: 'gray',
    borderWidth: 1,
  },
  sectionWrapper: {
    marginBottom: 5
  },
  titleText: {
    fontSize: 12,
    fontWeight: '900',
    marginBottom: 5,
    fontFamily: 'Inter-ExtraBold',
  },
  titleTextWidth: {
    fontSize: 12,
    fontWeight: '900',
    fontFamily: 'Inter-ExtraBold',
    width: '20%',
    textAlign: 'center',
    borderColor: 'gray',
    borderRightWidth: 1,
    padding: '5px 0',
  },
  noteText: {
    fontSize: 12,
    marginBottom: 5,
  },
  contentText: {
    fontSize: 12,
    marginBottom: 5,
    fontFamily: 'Inter-Normal',
  },
  tableContent: {
    width: '80px',
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  contentTextWidth: {
    fontSize: 12,
    fontFamily: 'Inter-Normal',
    width: '20%',
    textAlign: 'center',
    borderColor: 'gray',
    borderRightWidth: 1,
    padding: '5px 0',
  },
  addressText: {
    fontSize: 12,
    fontFamily: 'Inter-Normal',
  },
  splitTitle: {
    fontSize: 12,
    flex: .3,
    fontFamily: 'Inter-Normal',
    color: 'gray',
  },
  splitContent: {
    fontSize: 12,
    flex: .7,
    fontFamily: 'Inter-Normal',
  },
  divider: {
    borderBottomColor: 'gray',
    borderBottomWidth: 1,
    margin: '20px 0'
  },
  flexColumn: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  telContentText: {
    fontSize: 12,
    fontFamily: 'Inter-Normal',
  }
});

export default function ReceiptPDF(props) {
  const { details, paymentMethod } = props;
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const courierName = (link) => {
    let newDirectLink = '-'

    switch (link) {
      case 'track.logistika.com.my':
        newDirectLink = `Setel Express`
        break;
      case 'www.fmx.asia':
        newDirectLink = `FMX`
        break;
      case 'www.aramex.com/us/en/track/shipments':
        newDirectLink = `Aramex`
        break;
    }

    return newDirectLink
  }

  return (
    // <PDFViewer style={{ width: '100%', height: '100vh' }}>
    <Document
      onLoadSuccess={onDocumentLoadSuccess}
    >
      <Page size="A4" wrap={true} style={styles.body}>
        <View style={{
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
          {/* <View style={styles.logoWrapper}> */}
            <Image
              style={styles.image}
              src={Logo}
            />
          {/* </View> */}

          {/* <View style={styles.row}>
            <View>
              <Text style={{
                fontSize: 12,
                marginBottom: 5,
                fontFamily: 'Inter-Normal',
                marginLeft: 16,
                textAlign: 'right'
              }}>
                Tel:
              </Text>
              <Text style={{
                fontSize: 14,
                marginBottom: 5,
                fontFamily: 'Inter-Normal',
                textAlign: 'right'
              }}>
                Email:
              </Text>
            </View>
            <View style={{ marginLeft: '5px' }}>
              <Text style={styles.contentText}>019-2231463</Text>
              <Text style={styles.contentText}>vosemalaysia@hotmail.com</Text>
            </View>
          </View> */}
        </View>

        <View style={{
          margin: '0',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}>
          <View>
            <Text style={styles.noteText}>Unit 07-11, Level 7, Block 4</Text>
            <Text style={styles.noteText}>Mines Water Front Business Park</Text>
            <Text style={styles.noteText}>No 3, Jalan Tasik, Mines Resort City,</Text>
            <Text style={styles.noteText}>43300, Seri Kembangan, Selangor</Text>
            <View style={{ marginTop: '2px' }}>
              <Text style={styles.telContentText}>019-2231463</Text>
              <Text style={styles.contentText}>vosemalaysia@hotmail.com</Text>
            </View>
          </View>

          <View style={styles.flexColumn}>
            <View>
              <Text style={styles.titleText}>Order Number:</Text>
              <Text style={styles.noteText}>{details.ID} {moment(details.createdAt).format('(DD/MM/YYYY)')}</Text>
            </View>
          </View>
        </View>

        <View style={styles.table}>
          <View style={styles.marginRow}>
            <Text style={{
              fontSize: 12,
              fontWeight: '900',
              fontFamily: 'Inter-ExtraBold',
              width: '40%',
              textAlign: 'left',
              borderColor: 'gray',
              borderRightWidth: 1,
              padding: '5px 0',
            }}>Products</Text>
            <Text style={styles.titleTextWidth}>Unit Price</Text>
            <Text style={styles.titleTextWidth}>Qty</Text>
            <Text style={{
              fontSize: 12,
              fontWeight: '900',
              fontFamily: 'Inter-ExtraBold',
              width: '20%',
              textAlign: 'right',
              padding: '5px 0',
            }}>Totals</Text>
          </View>

          <View style={styles.sectionProduct}>
            {details.product.map((e, i) => (
              <View key={`pdf-item-${e.ID}`} style={styles.marginRow}>
                <Text style={{
                  fontSize: 12,
                  fontFamily: 'Inter-Normal',
                  width: '40%',
                  textAlign: 'left',
                  borderColor: 'gray',
                  borderRightWidth: 1,
                  padding: i ===  details.product.length - 1 ? '5px 0' : '5px 0 0',
                }}>{e.title}</Text>
                <Text style={{
                  fontSize: 12,
                  fontFamily: 'Inter-Normal',
                  width: '20%',
                  textAlign: 'center',
                  borderColor: 'gray',
                  borderRightWidth: 1,
                  padding: i ===  details.product.length - 1 ? '5px 0' : '5px 0 0',
                }}>
                  {'RM ' + setPriceFormat(e.unitPrice)}
                </Text>
                <Text style={{
                  fontSize: 12,
                  fontFamily: 'Inter-Normal',
                  width: '20%',
                  textAlign: 'center',
                  borderColor: 'gray',
                  borderRightWidth: 1,
                  padding: i ===  details.product.length - 1 ? '5px 0' : '5px 0 0',
                }}>
                  {e.quantity}
                </Text>
                <View style={{
                  width: '20%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: i ===  details.product.length - 1 ? '5px 0' : '5px 0 0',
                }}>
                  <Text style={{
                    fontSize: 12,
                    fontFamily: 'Inter-Normal',
                    paddingLeft: '18px',
                  }}>RM</Text>
                  <Text style={{
                    fontSize: 12,
                    fontFamily: 'Inter-Normal',
                  }}>
                    {e.isBundle ? setPriceFormat(e.discountAmount) : setPriceFormat(e.unitPrice * e.quantity)}
                  </Text>
                </View>
              </View>
            ))}
          </View>

          <View style={styles.paddingRow}>
            <Text style={styles.contentText}>Subtotal:</Text>
            <View style={styles.tableContent}>
              <Text style={styles.contentText}>RM</Text>
              <Text style={styles.contentText}>{setPriceFormat(details.subTotal)}</Text>
            </View>
          </View>

          {details.isMemberFee &&
            <View style={styles.paddingRow}>
              <Text style={styles.contentText}>Register Fee:</Text>
              <View style={styles.tableContent}>
                <Text style={styles.contentText}>RM</Text>
                <Text style={styles.contentText}>{details.registerFee}</Text>
              </View>
            </View>
          }

          <View style={styles.paddingRow}>
            <Text style={styles.contentText}>Shipping Fee:</Text>
            <View style={styles.tableContent}>
              <Text style={styles.contentText}>RM</Text>
              <Text style={styles.contentText}>{setPriceFormat(details.shippingFee)}</Text>
            </View>
          </View>

          <View style={styles.paddingRow}>
            <Text style={styles.contentText}>Coin Discount:</Text>
            <View style={styles.tableContent}>
              <Text style={styles.contentText}>- (RM </Text>
              <Text style={styles.contentText}> {details.coinValue ? setPriceFormat(details.coinValue) : '0.00'})</Text>
            </View>
          </View>

          <View style={styles.paddingRow}>
            <Text style={styles.contentText}>Voucher Discount:</Text>
            <View style={styles.tableContent}>
              <Text style={styles.contentText}>- (RM </Text>
              <Text style={styles.contentText}>{details.voucher.voucherDiscount ? setPriceFormat(details.voucher.voucherDiscount) : '0.00'})</Text>
            </View>
          </View>

          <View style={styles.paddingRow}>
            <Text style={styles.titleText}>(Total:)</Text>
            <View style={styles.tableContent}>
              <Text style={styles.titleText}>RM</Text>
              <Text style={styles.titleText}>{setPriceFormat(details.totalAmount)}</Text>
            </View>
          </View>
        </View>

        {/*<View style={styles.divider} />*/}

        <View style={styles.sectionInfo}>
          <View style={styles.row}>
            <View style={styles.addressBox}>
              <View style={styles.sectionWrapper}>
                <Text style={styles.titleText}>Recipient Name</Text>
                <Text style={styles.contentText}>{details.receiverInfo.firstName} {details.receiverInfo.lastName}</Text>
              </View>

              <View style={styles.sectionWrapper}>
                <Text style={styles.titleText}>Recipient Email</Text>
                <Text style={styles.contentText}>{details.receiverInfo.email}</Text>
              </View>

              <View style={styles.sectionWrapper}>
                <Text style={styles.titleText}>Billing Address</Text>
                <Text style={styles.addressText}>
                  {details.address.billingAddress.address1}
                </Text>
                <Text style={styles.addressText}>
                  {details.address.billingAddress.address2}
                </Text>
                <Text style={styles.addressText}>
                  {details.address.billingAddress.city}
                </Text>
                <Text style={styles.addressText}>
                  {details.address.billingAddress.postalCode}
                </Text>
                <Text style={styles.addressText}>
                  {details.address.billingAddress.state}
                </Text>
                <Text style={styles.addressText}>
                  {details.address.billingAddress.country}
                </Text>
              </View>
            </View>

            <View style={{ width: '50%', padding: '10px' }}>
              <View style={styles.sectionWrapper}>
                <Text style={styles.titleText}>Shipping Date</Text>
                <Text style={styles.contentText}>{details.shippingDate}</Text>
              </View>

              <View style={styles.sectionWrapper}>
                <Text style={styles.titleText}>Recipient Phone</Text>
                <Text style={styles.contentText}>{details.receiverInfo.phoneNumber}</Text>
              </View>

              <View style={styles.sectionWrapper}>
                <Text style={styles.titleText}>Shipping Address</Text>
                <Text style={styles.addressText}>
                  {details.address.shippingAddress.address1}
                </Text>
                <Text style={styles.addressText}>
                  {details.address.shippingAddress.address2}
                </Text>
                <Text style={styles.addressText}>
                  {details.address.shippingAddress.city}
                </Text>
                <Text style={styles.addressText}>
                  {details.address.shippingAddress.postalCode}
                </Text>
                <Text style={styles.addressText}>
                  {details.address.shippingAddress.state}
                </Text>
                <Text style={styles.addressText}>
                  {details.address.shippingAddress.country}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={{
          padding: '10px 15px 0px',
          margin: 0,
          borderColor: 'gray',
          borderWidth: 1,
          borderTopWidth: 0,
        }}>
          <Text style={styles.contentText}>Payment Method: {paymentMethod}</Text>
          <Text style={styles.contentText}>Courier: {courierName(details.courier)}</Text>
        </View>
      </Page>
    </Document>
    // </PDFViewer>
  )
}
