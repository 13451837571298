import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
//components
import Sidebar from "./components/common/sidebar_components/sidebar";
import RightSidebar from "./components/common/right-sidebar";
import Footer from "./components/common/footer";
import Header from "./components/common/header_components/header";
import Dashboard from "./components/dashboard";
import Login from "./components/auth/login";
//Products
import Product_Category from "./components/products/category";
import Product_Add_Product from "./components/products/digital-add-pro";
import Product_Product_List from "./components/products/digital-pro-list";
// Products physical
import Category from "./components/products/physical/category";
import Sub_category from "./components/products/physical/sub-category";
import Product_list from "./components/products/physical/product-list";
import Add_product from "./components/products/physical/add-product";
import Product_detail from "./components/products/physical/product-detail";
//Product Digital
import Digital_category from "./components/products/digital/digital-category";
import Digital_sub_category from "./components/products/digital/digital-sub-category";
import Digital_pro_list from "./components/products/digital/digital-pro-list";
import Digital_add_pro from "./components/products/digital/digital-add-pro";
//Sales
import Orders from "./components/sales/orders";
import Transactions_sales from "./components/sales/transactions-sales";
//voucher
import ListVoucher from "./components/voucher/list-voucher";
import CreateVoucher from "./components/voucher/create-voucher";
// import Rates from "./components/localization/rates";
// import Taxes from "./components/localization/taxes";
import Profile from "./components/settings/profile";
import Reports from "./components/reports/report";
import ListUser from "./components/users/list-user";
import CreateUser from "./components/users/create-user";
import CreateAdmin from "./components/admins/create-admin";
import ListAdmin from "./components/admins/list-admin";
import UserDetails from "./components/users/details-user";
import ClaimList from "./components/claims/claim-list";
import ClaimDetails from "./components/claims/claim-details";
import ShopOrders from "./components/sales/shop-order";
import OrderDetails from "./components/sales/order-details";
import OrderDetailsShop from "./components/sales/order-details-shop";
import Configuration from "./components/settings/configurations";
import ShopVerifyList from "./components/shop/list-verify-shop";
import ShopDetails from "./components/shop/shop-details";
import ShopList from "./components/shop/list-shop";
import AdminOrders from "./components/sales/admin-order";

const Layout = (props) => {
  return (
    <React.Fragment>
      <div className="page-wrapper">
        <Header />
        <div className="page-body-wrapper">
          <Sidebar />
          <RightSidebar />
          <div className="page-body">{props.children}</div>
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

const Router = () => {
  return (
    <BrowserRouter basename={"/"}>
      {/*<PerfectScrollbar>*/}
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/`} component={Login} />

          <Layout>
            <Route exact path={`${process.env.PUBLIC_URL}/dashboard`} component={Dashboard} />

            <Route exact path={`${process.env.PUBLIC_URL}/products/category`} component={Product_Category} />
            <Route
              exact
              path={[
                `${process.env.PUBLIC_URL}/products/add-product`,
                `${process.env.PUBLIC_URL}/products/add-product/:productID`,
              ]}
              component={Product_Add_Product}
            />

            {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/products/add-product/:productID`}
              component={Product_Add_Product}
            /> */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/products/product-list`}
              component={Product_Product_List}
            />

            <Route exact path={`${process.env.PUBLIC_URL}/products/physical/category`} component={Category} />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/products/physical/sub-category`}
              component={Sub_category}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/products/physical/product-list`}
              component={Product_list}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/products/physical/product-detail`}
              component={Product_detail}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/products/physical/add-product`}
              component={Add_product}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/products/digital/digital-category`}
              component={Digital_category}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/products/digital/digital-sub-category`}
              component={Digital_sub_category}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/products/digital/digital-product-list`}
              component={Digital_pro_list}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/products/digital/digital-add-product`}
              component={Digital_add_pro}
            />

            <Route exact path={`${process.env.PUBLIC_URL}/sales/orders`} component={Orders} />
            <Route exact path={`${process.env.PUBLIC_URL}/sales/orders-details/:orderID`} component={OrderDetails} />
            <Route exact path={`${process.env.PUBLIC_URL}/sales/orders-shop`} component={ShopOrders} />
            <Route exact path={`${process.env.PUBLIC_URL}/sales/orders-admin`} component={AdminOrders} />
            <Route exact path={`${process.env.PUBLIC_URL}/sales/orders-details-shop/:orderID`} component={OrderDetailsShop} />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/sales/transactions`}
              component={Transactions_sales}
            />

            <Route exact path={`${process.env.PUBLIC_URL}/voucher/list-voucher`} component={ListVoucher} />
            <Route
              exact
              path={[
                `${process.env.PUBLIC_URL}/voucher/create-voucher`,
                `${process.env.PUBLIC_URL}/voucher/create-voucher/:voucherID`,
              ]}
              component={CreateVoucher}
            />
            {/* <Route exact path={`${process.env.PUBLIC_URL}/voucher/create-voucher/:voucherID`} render={()=><CreateVoucher/>} /> */}

            <Route exact path={`${process.env.PUBLIC_URL}/users/list-user`} component={ListUser} />
            <Route exact path={`${process.env.PUBLIC_URL}/users/create-user`} component={CreateUser} />
            <Route exact path={`${process.env.PUBLIC_URL}/users/details-user/:userID`} component={UserDetails} />



            <Route exact path={`${process.env.PUBLIC_URL}/admins/list-admin`} component={ListAdmin} />
            <Route exact path={`${process.env.PUBLIC_URL}/admins/create-admin`} component={CreateAdmin} />

            {/* <Route path={`${process.env.PUBLIC_URL}/localization/currency-rates`} component={Rates} />
            <Route path={`${process.env.PUBLIC_URL}/localization/taxes`} component={Taxes} /> */}
            <Route exact path={`${process.env.PUBLIC_URL}/claim`} component={ClaimList} />
            <Route exact path={`${process.env.PUBLIC_URL}/claims/details-claim/:claimID`} component={ClaimDetails} />

            {/* <Route exact path={`${process.env.PUBLIC_URL}/reports/report`} component={Reports} /> */}
            <Route exact path={`${process.env.PUBLIC_URL}/shop/list-verify-shop`} component={ShopVerifyList} />
            <Route exact path={`${process.env.PUBLIC_URL}/shop/list-shop`} component={ShopList} />
            <Route exact path={`${process.env.PUBLIC_URL}/shop/verify-details-shop/:shopID`} component={ShopDetails} />
            <Route exact path={`${process.env.PUBLIC_URL}/shop/details-shop/:shopID`} component={ShopDetails} />

            <Route exact path={`${process.env.PUBLIC_URL}/settings/profile`} component={Profile} />
            <Route exact path={`${process.env.PUBLIC_URL}/settings/config`} component={Configuration} />
          </Layout>
        </Switch>
      {/*</PerfectScrollbar>*/}
    </BrowserRouter>
  );
};

export default Router;
