import fetchData from "../../utils/fetchData";

export function getShopVerifyList(params) {
  return fetchData({
    url: "/shop/verify-list",
    method: "GET",
    params: params,
  });
}

export function getShopList(params) {
  return fetchData({
    url: "/shop",
    method: "GET",
    params: params,
  });
}

export function getShopExportList(params) {
  return fetchData({
    url: "/shop/export",
    method: "GET",
    params: params,
  });
}

export function getShopDetails(shopID) {
  return fetchData({
    url: `/shop/${shopID}`,
    method: "GET",
  });
}

export function updateShopDetails(shopID, body) {
  return fetchData({
    url: `/shop/${shopID}`,
    method: "PUT",
    data: body,
  });
}

export function addProduct(body, shopID) {
  return fetchData({
    url: `/shop/add-product/${shopID}`,
    method: "POST",
    data: body,
  });
}

export function modifyProduct(body, shopID) {
  return fetchData({
    url: `/shop/modify-product/${shopID}`,
    method: "POST",
    data: body,
  });
}



export function shopVerify(shopID) {
  return fetchData({
    url: `/shop/verify/${shopID}`,
    method: "PUT",
  });
}

export function shopDelivered(shopID) {
  return fetchData({
    url: `/shop/delivered/${shopID}`,
    method: "PUT",
  });
}

export function shopCompleted(shopID) {
  return fetchData({
    url: `/shop/complete/${shopID}`,
    method: "PUT",
  });
}

export function shopSuspend(body, shopID) {
  return fetchData({
    url: `/shop/suspend/${shopID}`,
    method: "PUT",
    data: body,
  });
}

export function shopRemove(shopID) {
  return fetchData({
    url: `/shop/remove/${shopID}`,
    method: "DELETE",
  });
}

export function getShopProductList(params, shopID) {
  return fetchData({
    url: `/shop-product/${shopID}`,
    method: "GET",
    params: params,
  });
}

export function getShopStockTransactionList(params, shopID) {
  return fetchData({
    url: `/shop/stock-transaction/${shopID}`,
    method: "GET",
    params: params,
  });
}

export function getProductList() {
  return fetchData({
    url: "/shop/product-list",
    method: "GET",
  });
}

export function getProductListWithShop(shopID) {
  return fetchData({
    url: `/shop/product-list/${shopID}`,
    method: "GET",
  });
}

export function updateProductStockLimit(body, stockID) {
  return fetchData({
    url: `/shop/stock-limit/${stockID}`,
    method: "PUT",
    data: body,
  });
}

export function getAvailableIC(shopID) {
  return fetchData({
    url: `/shop/available-member/${shopID}`,
    method: "GET",
  });
}
