import React, { Fragment, useEffect, useState } from "react";
import UserPanel from "./user-panel";
import { Link } from "react-router-dom";
import { MENUITEMS } from "../../../constants/menu";

// image import
// import logo from "../../../assets/images/dashboard/multikart-logo.png";
import logo from "../../../assets/images/dashboard/vose-panel-logo.png";
import { useProps } from "../../../contexts";
import { STATIC_VARIABLE } from "../../../constants/static-data";
import { getArrayObjByLabel, getArrayObjByValue } from "../../../utils";

const Sidebar = () => {
  const { user } = useProps();
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const currentUrl = window.location.pathname;

  useEffect(() => {
    mainmenu.map((items) => {
      if (!items.children && items.path === currentUrl) {
        items.active = true;
      }

      if (items.children) {
        items.children.map((subItems) => {
          if (subItems.path === currentUrl) {
            items.active = true;
            subItems.active = true;
          }
        })
      }
    })
  }, [window.location.pathname]);

  const setNavActive = (item) => {
    MENUITEMS.filter((menuItem) => {
      if (menuItem !== item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item)) menuItem.active = true;
      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems !== item) {
            submenuItems.active = false;
          }
          if (submenuItems.children) {
            submenuItems.children.map((childItem) => (childItem.active = false));
            if (submenuItems.children.includes(item)) {
              submenuItems.active = true;
              menuItem.active = true;
            }
          }
          return false;
        });
      }
      return false;
    });
    item.active = !item.active;
    setMainMenu(MENUITEMS);
  };

  const mainMenu = mainmenu.map((menuItem, i) => {

    //Add admin role permission
    if (user.role === getArrayObjByLabel(STATIC_VARIABLE.roleArray, 'Admin').value ) {
      if (menuItem.title === 'Admins' ) {return }
    } 

    return(
    <li className={`${menuItem.active ? "active" : ""}`} key={i}>
      {menuItem.sidebartitle ? <div className="sidebar-title">{menuItem.sidebartitle}</div> : ""}
      {menuItem.type === "sub" ? (
        <a className="sidebar-header " href="#javaScript" onClick={() => setNavActive(menuItem)}>
          <menuItem.icon />
          <span>{menuItem.title}</span>
          <i className="fa fa-angle-right pull-right"></i>
        </a>
      ) : (
        ""
      )}
      {menuItem.type === "link" ? (
        <Link
          to={`${process.env.PUBLIC_URL}${menuItem.path}`}
          className={`sidebar-header ${menuItem.active ? "active" : ""}`}
          onClick={() => setNavActive(menuItem)}
        >
          <menuItem.icon />
          <span>{menuItem.title}</span>
          {menuItem.children ? <i className="fa fa-angle-right pull-right"></i> : ""}
        </Link>
      ) : (
        ""
      )}
      {menuItem.children ? (
        <ul
          className={`sidebar-submenu ${menuItem.active ? "menu-open" : ""}`}
          style={menuItem.active ? { opacity: 1, transition: "opacity 500ms ease-in" } : {}}
        >
          {menuItem.children.map((childrenItem, index) => {
          
            //Add admin role permission
            if (user.role === getArrayObjByLabel(STATIC_VARIABLE.roleArray, 'Admin').value) {
              if (menuItem.title === 'Settings' && childrenItem.title === "Configuration" ) {return }
            } 

            return(
            <li key={index} className={childrenItem.children ? (childrenItem.active ? "active" : "") : ""}>
              {childrenItem.type === "sub" ? (
                <a href={"#javaScript"} onClick={() => setNavActive(childrenItem)}>
                  <i className="fa fa-circle"></i>
                  {childrenItem.title} <i className="fa fa-angle-right pull-right"></i>
                </a>
              ) : (
                ""
              )}

              {childrenItem.type === "link" ? (
                <Link
                  to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                  className={childrenItem.active ? "active" : ""}
                  onClick={() => setNavActive(childrenItem)}
                >
                  <i className="fa fa-circle"></i>
                  {childrenItem.title}
                </Link>
              ) : (
                ""
              )}
              {childrenItem.children ? (
                <ul className={`sidebar-submenu ${childrenItem.active ? "menu-open" : "active"}`}>
                  {childrenItem.children.map((childrenSubItem, key) => (
                    <li className={childrenSubItem.active ? "active" : ""} key={key}>
                      {childrenSubItem.type === "link" ? (
                        <Link
                          to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                          className={childrenSubItem.active ? "active" : ""}
                          onClick={() => setNavActive(childrenSubItem)}
                        >
                          <i className="fa fa-circle"></i>
                          {childrenSubItem.title}
                        </Link>
                      ) : (
                        ""
                      )}
                    </li>
                  ))}
                </ul>
              ) : (
                ""
              )}
            </li>
          )}
          )}
        </ul>
      ) : (
        ""
      )}
    </li>
  )}
  );

  return (
    <Fragment>
      <div className="page-sidebar">
        <div className="main-header-left d-none d-lg-block">
          <div className="logo-wrapper">
            <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
              <img style={{ width: 220 }} className="blur-up lazyloaded" src={logo} alt="" />
            </Link>
          </div>
        </div>
        <div className="sidebar custom-scrollbar">
          {/* <UserPanel /> */}
          <ul className="sidebar-menu">{mainMenu}</ul>
        </div>
      </div>
    </Fragment>
  );
};

export default Sidebar;
