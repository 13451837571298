import React, { Fragment, useEffect, useRef, useState } from "react";
import { useProps } from "../../contexts/index";
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
//hooks
import useQuery from "../../hooks/useQuery";
//Component
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../common/datatable";
import CreateCategoryModal from "../common/modal/CreateCategoryModal";
import DeletePopUp from "../common/modal/DeletePopUp";
import "react-toastify/dist/ReactToastify.css";
//Controller
import {
  handleDeleteCategoryAPI,
  handleGetCategoryListAPI,
  handleCreateCategoryAPI,
  handleUpdateCategoryAPI,
} from "../../controller/product/category";
import { data } from "../../assets/data/category";

const generateBody = (body, page, rowsPerPage, handleOpenEditPopUp, handleDelete = () => {}) => {
  return body.map((e, i) => ({
    id: <p style={{ fontWeight: "bold" }}>{(page - 1) * rowsPerPage + i + 1}.</p>,
    title: e.title,
    content: e.content,
    createdAt: e.createdAt,
    edit: (
      <div>
        <span onClick={() => handleDelete(e.ID, e.title)}>
          <i
            className="fa fa-trash"
            style={{
              width: 35,
              fontSize: 20,
              padding: 11,
              color: "#e4566e",
              cursor: "pointer",
            }}
          />
        </span>

        <span>
          <i
            onClick={(event) => handleOpenEditPopUp(event, e)}
            className="fa fa-pencil"
            style={{
              width: 35,
              fontSize: 20,
              padding: 11,
              color: "rgb(40, 167, 69)",
              cursor: "pointer",
            }}
          />
        </span>
      </div>
    ),
  }));
};
const header = [
  { id: "id", label: "", align: "center" },
  { id: "title", label: "Title", align: "center", isLimitCol: true },
  { id: "content", label: "Content", align: "left" },
  { id: "createdAt", label: "Create Date", align: "left" },
  { id: "edit", label: "Action", align: "center" },
];

const Category = () => {
  const [query, setQuery] = useQuery();
  const { _handleChange } = useProps();
  const deletePopUpRef = useRef();
  const createPopUpRef = useRef();
  const [mainState, _setMainState] = useState({
    list: [],
    page: query.page ? parseInt(query.page) : 1,
    limit: query.limit ? parseInt(query.limit) : 10,
    total: 10,
    order: query.order || "desc",
    orderBy: query.orderBy || "date",
    search: "",
    refreshData: false,
  });

  const setMainState = (newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleRefreshData = () => {
    setMainState({ refreshData: !mainState.refreshData });
  };

  const handleOpenCreatePopUp = () => {
    createPopUpRef.current.setMainState({
      id: 0,
      title: "",
      content: "",
      showInFilter: 1,
      isOpen: true,
      isCreate: true,
    });
  };

  const handleOpenEditPopUp = (event, details) => {
    const detailsParams = {
      id: details.ID,
      title: details.title,
      content: details.content,
      showInFilter: 1,
      isCreate: false,
      isOpen: true,
    };
    createPopUpRef.current.setMainState(detailsParams);
  };

  const handleOpenDeletePopUp = (id, itemName) => {
    deletePopUpRef.current.setMainState({ isOpen: true, deleteID: id, itemName: itemName });
  };

  const handleDelete = (id) => {
    handleDeleteCategoryAPI(id, _handleChange).then((isSuccess) => {
      if (isSuccess) {
        handleRefreshData();
        deletePopUpRef.current.handleClose();
      }
    });
  };

  const handleSubmit = (details, isCreate, categoryID) => {
    if (isCreate) {
      handleCreateCategoryAPI(details, _handleChange).then((isSuccess) => {
        if (isSuccess) {
          handleRefreshData();
          createPopUpRef.current.handleClose();
        }
      });
    } else {
      handleUpdateCategoryAPI(details, categoryID, _handleChange).then((isSuccess) => {
        if (isSuccess) {
          handleRefreshData();
          createPopUpRef.current.handleClose();
        }
      });
    }
  };
  const body = generateBody(
    mainState.list,
    mainState.page,
    mainState.limit,
    handleOpenEditPopUp,
    handleOpenDeletePopUp
  );

  const _handleGetCourseList = () => {
    const queryObj = {
      page: mainState.search ? 1 : mainState.page,
      limit: mainState.limit,
      order: mainState.order,
      orderBy: mainState.orderBy,
      search: mainState.search,
    };
    const param = {
      ...queryObj,
      changeContext: _handleChange,
      setMainState: setMainState,
    };
    setQuery(queryObj);
    handleGetCategoryListAPI(param);
  };
  useEffect(() => {
    _handleGetCourseList();
  }, [mainState.page, mainState.order, mainState.orderBy, mainState.refreshData]);

  return (
    <Fragment>
      <Breadcrumb title="Category" parent="Products" />
      {/* <!-- Container-fluid starts--> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Products Category</h5>
              </CardHeader>
              <CardBody>
                <div className="btn-popup pull-right">
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => handleOpenCreatePopUp()}
                    // data-toggle="modal"
                    // data-original-title="test"
                    // data-target="#exampleModal"
                  >
                    Add Category
                  </Button>
                  <CreateCategoryModal ref={createPopUpRef} handleSubmit={handleSubmit} />
                </div>
                <div className="clearfix"></div>
                <div id="basicScenario" className="product-physical">
                  <Datatable
                    // multiSelectOption={true}
                    multiSelectOption={false}
                    header={header}
                    myData={body}
                    // myData={data}
                    order={mainState.order}
                    orderBy={mainState.orderBy}
                    setState={setMainState}
                    rowsPerPage={mainState.limit}
                    count={mainState.total}
                    page={mainState.page}
                    total={mainState.total}
                    pagination={true}
                    class="-striped -highlight"
                  />
                  <DeletePopUp ref={deletePopUpRef} title="Category" handleDelete={handleDelete} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* <!-- Container-fluid Ends--> */}
    </Fragment>
  );
};

export default Category;
