import React, { Fragment, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { variables } from "../../config/variables";
import CKEditors from "react-ckeditor-component";
import { Editor } from "@tinymce/tinymce-react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  ButtonDropdown,
  Label,
  Row,
} from "reactstrap";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import FormLabel from '@mui/material/FormLabel';
import Dropzone, { useDropzone } from "react-dropzone";

import { useParams } from "react-router-dom";
import { useProps } from "../../contexts";
//Components
import Breadcrumb from "../common/breadcrumb";
import MyDropzone from "../common/dropzone";
//Controller
import {
  handleCreateProductAPI,
  handleGetProductAPI,
  handleUpdateProductAPI, handleUploadProductImage,
  handleGetCategoriesAPI,
} from "../../controller/product";
import MultipleSelectChip from "../common/multiple-select-chip";

const Digital_add_pro = ({ onBlur, onChange, afterPaste }) => {
  const history = useHistory();
  const { productID } = useParams();
  const { _handleChange } = useProps();
  const isCreate = typeof productID === "undefined";
  const editorRef = useRef(null);
  const useDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [mainState, _setMainState] = useState({
    ID: 0,
    title: "",
    initialDescription: "",
    initialDetails: "",
    details: "",
    description: "",
    price: 0,
    discountPrice: 0,
    pvPoint: 0,
    code: "",
    stock: 0,
    weight: 0,
    height: 0,
    width: 0,
    length: 0,
    returnGolden: 0,
    returnPlatinum: 0,
    status: 2,
    createdAt: "2021-12-09",
    updatedAt: "2021-12-09",
    photos: [],
    category: [],
    initialCategory: [],
    categoryList: [],
    refreshData: false,
    checked: false,
    discountMethod: 1,
    discountAmount: 0,
    discountType: 1,
    discountQuantity: 0,
    discountPV: 0,
  });

  const setMainState = (newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const handleOnChangeCategory = (event) => {
    setMainState({category: [parseInt(event.target.value)]})
  }

  const handleOnChange = (event) => {
    setMainState({status: parseInt(event.target.value)})
  }

  const handleCkeChange = (event) => {
    const newContent = event.editor.getData();
    setMainState({ description: newContent })
  }

  const handleCheck = (event) => {
    if (event.target.checked) {
      setMainState({checked: event.target.checked})
    } else {
      setMainState({
        checked: event.target.checked,
        discountMethod: 1,
        discountAmount: 0,
        discountType: 1,
        discountQuantity: 0,
        discountPV: 0,
      });
    }
  };

  const handleChange = (key) => (event) => {
    setMainState({ [key]: event.target.value });
  };

  const handleOnchangeTimy = (content) => {
    setMainState({ description: content })
  }

  const handleOnchangeDetailsTimy = (content) => {
    setMainState({ details: content })
  }
  const handleTextInput = (event, key) => {
    let value = event.target.value;
    if (
      key === "stock" ||
      key === "length" ||
      key === "width" ||
      key === "height"
    ) {
      value = parseInt(value);
    }

    if (
      key === "price" ||
      key === "weight" ||
      key === "discountPrice" ||
      key === "pvPoint"
    ) {
      value = parseFloat(value);
    }

    if (event.target.type === 'number') {
      if (parseFloat(value) <= 0) {
        value = 0;
      }
    }

    setMainState({ ...mainState, [key]: value });
  };

  const handleRefreshData = () => {
    setMainState({ refreshData: !mainState.refreshData });
  };

  const handleSubmit = () => {
    if (isCreate) {
      handleCreateProductAPI(mainState, _handleChange).then((isSuccess) => {
        if (isSuccess) {
          history.push("/products/product-list");
        }
      });
    } else {
      handleUpdateProductAPI(mainState, productID, _handleChange).then((isSuccess) => {
        if (isSuccess) {
          handleRefreshData();
        }
      });
    }
  };

  const handleAddFile = (list = []) => {
    let allList = [...mainState.photos];
    if (list.length === 0) return allList;
    list.map((file) => {
      allList.push(file);
    });
    return allList;
  };

  const handleDeleteFile = (index) => {
    let list = [...mainState.photos];
    list.splice(index, 1);
    setMainState({ photos: list });
  };

  // DropZone functions
  const handleOnDrop = (acceptedFiles) => {
    if (!acceptedFiles.length) return;
    const tmpData = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        remarks: "",
      })
    );

    handleUploadProductImage(tmpData, mainState, setMainState, _handleChange)
  };

  const handleDropRejected = (files) => {
    if (!files.length) return;
    // const file = files[0];
    // if (file.size >= FILE_UPLOAD_SIZE) {
    //   _handleChange &&
    //     _handleChange({
    //       snackbar: true,
    //       snackbarType: 2,
    //       snackbarContent: "The chosen file is too large (5MB maximum)", //TODO
    //     });
    // }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: "image/*",
    onDrop: handleOnDrop,
    onDropRejected: handleDropRejected,
    multiple: true,
  });

  useEffect(() => {
    handleGetCategoriesAPI(setMainState, _handleChange);
    if (productID) {
      handleGetProductAPI(productID, setMainState, _handleChange);
    }
  }, [mainState.refreshData]);

  console.log(mainState)

  return (
    <Fragment>
      <Breadcrumb title={`${isCreate ? "Create" : "Edit"} Products`} parent="Products" />
      <Container fluid={true}>
        <Row className="product-adding">
          <Col xl="8">
            <Card>
              <CardHeader>
                <h5>General</h5>
              </CardHeader>
              <CardBody>
                <div className="digital-add needs-validation">
                  <FormGroup>
                    <Label className="col-form-label pt-0">
                      <span>*</span> Title
                    </Label>
                    <Input
                      className="form-control"
                      id="validationCustom01"
                      type="text"
                      required=""
                      value={mainState.title}
                      onChange={(event) => handleTextInput(event, "title")}
                    />
                  </FormGroup>
                  <FormGroup>
                  <Label className="col-form-label pt-0">
                      Details
                    </Label>
                    <div className="digital-add needs-validation">
                      <FormGroup className=" mb-0">
                        <div className="description-sm">
                          <Editor
                            apiKey={variables.tinymce_key}
                            // @ts-ignore
                            onInit={(evt, editor) => (editorRef.current = editor)}
                            initialValue={mainState.initialDetails}
                            onEditorChange={handleOnchangeDetailsTimy}
                            init={{
                              height: 200,
                              menubar: false,
                              plugins: [
                                "advlist autolink lists link image charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "insertdatetime media table paste code help wordcount",
                                "imagetools",
                              ],
                              imagetools_cors_hosts: ["picsum.photos"],
                              toolbar:
                                "undo redo | fontselect | fontsizeselect |" +
                                "bold italic forecolor backcolor | alignleft aligncenter " +
                                "alignright alignjustify | bullist numlist | " +
                                "removeformat fullscreen | image link",
                              toolbar_sticky: true,
                              fontsize_formats: "9pt 10pt 11pt 12pt 13pt 14pt 18pt 24pt 36pt 48pt 64pt 72pt 96pt 144pt 288pt",
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px;}",
                              toolbar_mode: "sliding",
                              contextmenu: "link image imagetools table",
                              skin: useDarkMode ? "oxide-dark" : "oxide",
                              automatic_uploads: true,
                              file_picker_types: "image media",
                            }}
                          />

                        </div>
                      </FormGroup>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label pt-0">
                      Product Information
                    </Label>
                    <div className="digital-add needs-validation">
                      <FormGroup className=" mb-0">
                        <div className="description-sm">
                          <Editor
                            apiKey={variables.tinymce_key}
                            // @ts-ignore
                            onInit={(evt, editor) => (editorRef.current = editor)}
                            initialValue={mainState.initialDescription}
                            onEditorChange={handleOnchangeTimy}
                            init={{
                              height: 500,
                              menubar: false,
                              plugins: [
                                "advlist autolink lists link image charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "insertdatetime media table paste code help wordcount",
                                "imagetools",
                              ],
                              imagetools_cors_hosts: ["picsum.photos"],
                              toolbar:
                                "undo redo | fontselect | fontsizeselect |" +
                                "bold italic forecolor backcolor | alignleft aligncenter " +
                                "alignright alignjustify | bullist numlist | " +
                                "removeformat fullscreen | image link",
                              toolbar_sticky: true,
                              fontsize_formats: "9pt 10pt 11pt 12pt 13pt 14pt 18pt 24pt 36pt 48pt 64pt 72pt 96pt 144pt 288pt",
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px;}",
                              toolbar_mode: "sliding",
                              contextmenu: "link image imagetools table",
                              skin: useDarkMode ? "oxide-dark" : "oxide",
                              automatic_uploads: true,
                              file_picker_types: "image media",
                            }}
                          />

                        </div>
                      </FormGroup>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label pt-0">
                      <span>*</span>Product Code
                    </Label>
                    <Input
                      disabled={!isCreate}
                      className="form-control"
                      id="validationCustom01"
                      type="text"
                      required=""
                      value={mainState.code}
                      onChange={(event) => handleTextInput(event, "code")}
                    />
                  </FormGroup>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h5>Image</h5>
              </CardHeader>
              <CardBody>
                <Label className="col-form-label pt-0"> Product Upload</Label>
                <Row>
                  <Col md="12">
                    <div className="row">
                    {mainState.photos.map((item, index) => (
                        <div className="customize-img-container col-md-4">
                          <img alt="" src={item.url} onClick={open}/>
                          <span
                            onClick={() => handleDeleteFile(index)}
                            className="customize-btn-container"
                          >
                          <i className="fa fa-remove" style={{ padding: 6, cursor: "pointer", }} />
                        </span>
                        </div>
                    ))}
                    </div>
                  </Col>
                </Row>

                {/* <MyDropzone /> */}
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h5>Pricing</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xl="6">
                    <FormGroup>
                      <Label className="col-form-label pt-0"><span>*</span>Price</Label>
                      <InputGroup>
                        <Input disabled className="customize-first-border customize-side-input" value="MYR" />
                        <Input
                          className="form-control customize-second-border"
                          id="validationCustom02"
                          type="number"
                          min={0}
                          required=""
                          value={mainState.price}
                          onChange={(event) => handleTextInput(event, "price")}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label className="col-form-label pt-0">Discount Price</Label>
                      <InputGroup>
                        <Input disabled className="customize-side-input customize-first-border" value="MYR" />

                        <Input
                          className="form-control customize-second-border "
                          id="validationCustom02"
                          type="number"
                          min={0}
                          required=""
                          value={mainState.discountPrice}
                          onChange={(event) => handleTextInput(event, "discountPrice")}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xl="12">
                    <FormGroup>
                      <Label className="col-form-label pt-0">Product Weight</Label>
                      <InputGroup>
                        <Input
                          className="form-control customize-first-border"
                          id="validationCustom02"
                          type="number"
                          min={0}
                          required=""
                          value={mainState.weight}
                          onChange={(event) => handleTextInput(event, "weight")}
                        />
                        <Input disabled className="customize-side-input customize-second-border" value="KG" />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label className="col-form-label pt-0">Point</Label>
                      <InputGroup>
                        <Input
                          className="form-control"
                          id="validationCustom02"
                          type="number"
                          min={0}
                          required=""
                          value={mainState.pvPoint}
                          onChange={(event) => handleTextInput(event, "pvPoint")}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup>
                      <Label className="col-form-label pt-0"><span>*</span>Stock</Label>
                      <InputGroup>
                        <Input
                          className="form-control"
                          id="validationCustom02"
                          type="number"
                          min={0}
                          required=""
                          value={mainState.stock}
                          onChange={(event) => handleTextInput(event, "stock")}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <Stack direction='row' alignItems='center'>
                  <h5>Bundle</h5>
                  <FormGroup style={{ marginLeft: 20, marginBottom: -5 }}>
                    <FormControlLabel control={
                      <Checkbox checked={mainState.checked} onChange={handleCheck}/>
                    }/>
                  </FormGroup>
                </Stack>
              </CardHeader>

                <CardBody>
                  <Collapse in={mainState.checked}>
                    <Row>
                      <Col xl="6">
                        <FormControl>
                          <Label className="col-form-label pt-0">Offer Type</Label>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={mainState.discountMethod}
                            onChange={handleChange('discountMethod')}
                          >
                            <FormControlLabel value="1" control={<Radio />} label="One time" />
                            <FormControlLabel value="2" control={<Radio />} label="Recurring" />
                          </RadioGroup>
                        </FormControl>
                      </Col>

                      <Col xl="6">
                        <FormControl>
                          <Label className="col-form-label pt-0"><span>*</span>Discount</Label>
                          <Stack direction='row'>
                            <Input
                              style={{ width: 'auto' }}
                              className="form-control"
                              id="validationCustom02"
                              type="number"
                              required=""
                              value={mainState.discountAmount}
                              onChange={(event) => handleTextInput(event, "discountAmount")}
                            />

                            <ButtonDropdown>
                              <select
                                className="custom-select"
                                required=""
                                defaultValue={1}
                                value={mainState.discountType}
                                onChange={(event) => handleTextInput(event, "discountType")}
                              >
                                <option value="1">MYR</option>
                                <option value="2">Percents (%)</option>
                              </select>
                            </ButtonDropdown>
                          </Stack>
                        </FormControl>
                      </Col>

                      <Col xl="6">
                        <FormGroup>
                          <Label className="col-form-label pt-0"><span>*</span>Quantity</Label>
                          <InputGroup>
                            <Input
                              className="form-control"
                              id="validationCustom02"
                              type="number"
                              min={0}
                              required=""
                              value={mainState.discountQuantity}
                              onChange={(event) => handleTextInput(event, "discountQuantity")}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col xl="6">
                        <FormGroup>
                          <Label className="col-form-label pt-0"><span>*</span>Reduce Point</Label>
                          <InputGroup>
                            <Input
                              className="form-control"
                              id="validationCustom02"
                              type="number"
                              min={0}
                              required=""
                              value={mainState.discountPV}
                              onChange={(event) => handleTextInput(event, "discountPV")}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>

                    </Row>
                </Collapse>
              </CardBody>
            </Card>
            {/*<Card>
               <CardHeader>
                <h5>Shipping</h5>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <Label className="col-form-label pt-0">Weight</Label>
                  <InputGroup>
                    <Input
                      className="form-control"
                      id="validationCustom02"
                      type="number"
                      required=""
                      value={mainState.weight}
                      onChange={(event) => handleTextInput(event, "weight")}
                    />
                    <ButtonDropdown>
                      <select className="custom-select" required="" disabled defaultValue={1}>
                        <option value="1">kg</option>
                        <option value="2">g </option>
                        <option value="3">oz</option>
                        <option value="4">lb</option>
                      </select>
                    </ButtonDropdown>
                  </InputGroup>
                </FormGroup>
                <Row>
                  <Col xl="4">
                    <FormGroup>
                      <Label className="col-form-label pt-0">Length(cm)</Label>
                      <InputGroup>
                        <Input
                          className="form-control customize-first-border"
                          id="validationCustom02"
                          type="text"
                          required=""
                          value={mainState.length}
                          onChange={(event) => handleTextInput(event, "length")}
                        />
                        <Input disabled className="customize-side-input customize-second-border" value="cm" />
                         <InputGroupText>cm</InputGroupText>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xl="4">
                    <FormGroup>
                      <Label className="col-form-label pt-0">Width(cm)</Label>
                      <InputGroup>
                        <Input
                          className="form-control customize-first-border"
                          id="validationCustom02"
                          type="text"
                          required=""
                          value={mainState.width}
                          onChange={(event) => handleTextInput(event, "width")}
                        />
                        <Input disabled className="customize-side-input customize-second-border" value="cm" />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col xl="4">
                    <FormGroup>
                      <Label className="col-form-label pt-0">Height(cm)</Label>
                      <InputGroup>
                        <Input
                          className="form-control customize-first-border"
                          id="validationCustom02"
                          type="number"
                          required=""
                          value={mainState.height}
                          onChange={(event) => handleTextInput(event, "height")}
                        />
                        <Input disabled className="customize-side-input customize-second-border" value="cm" />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card> */}
          </Col>
          <Col xl="4">
            <Card>
              <CardHeader>
                <h5>Category List</h5>
              </CardHeader>
              <CardBody>
                <MultipleSelectChip
                  defaultValue={mainState.initialCategory}
                  categoryList={mainState.categoryList}
                  setState={setMainState}
                />
                {/*<select className="custom-select" required="" value={mainState.category[0]} onChange={handleOnChangeCategory}>*/}
                {/*  <option value="">--Select--</option>*/}
                {/*  {mainState.categoryList.map(item => (*/}
                {/*    <option value={item.ID}>{item.title}</option>*/}
                {/*  ))}*/}
                {/*</select>*/}
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h5>Visibility</h5>
              </CardHeader>
              <CardBody>
                <div className="needs-validation">
                  <FormGroup>
                    <div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                      <Label className="d-block">
                        <Input
                          id="edo-ani"
                          className="radio_animated"
                          type="radio"
                          name="rdo-ani"
                          value="1"
                          checked={mainState.status === 1}
                          onChange={handleOnChange}
                        />
                        Enable
                      </Label>
                      <Label className="d-block">
                        <Input
                          id="edo-ani2"
                          className="radio_animated"
                          type="radio"
                          name="rdo-ani"
                          value="2"
                          checked={mainState.status === 2}
                          onChange={handleOnChange}
                        />
                        Draft
                      </Label>
                    </div>
                  </FormGroup>

                  <FormGroup className="mb-0">
                    <div className="product-buttons customize-f-end">
                      <Button type="button" color="primary" onClick={() => handleSubmit()}>
                        {isCreate ? "Create" : "Save"}
                      </Button>
                      <Button type="button" style={{marginLeft: 10}} outline color="secondary" onClick={() => {
                        history.goBack()
                      }}>
                        Discard
                      </Button>
                    </div>
                  </FormGroup>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Digital_add_pro;
