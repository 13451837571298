import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody, Button, CardHeader, Container } from "reactstrap";
import { useParams } from "react-router-dom";
import { useProps } from "../../contexts";
//Component
import Breadcrumb from "../common/breadcrumb";
import Tabset from "./tabset-user";
import ListSetUser from "./listset-user";
import PVPointUser from "./pvpoint-user";
import {handleGetUserDetailsAPI, handleUpdateUserAPI} from "../../controller/user";
import {getArrayObjByLabel, setStateHelper} from "../../utils";
import { STATIC_VARIABLE } from "../../constants/static-data";
import DeletePopUp from "../common/modal/DeletePopUp";

//Controller

const UserDetails = () => {
  const { userID } = useParams();
  const history = useHistory();
  const { _handleChange } = useProps();
  const suspendPopUpRef = useRef(null);
  const [mainState, setMainState] = useState({
    status: 1,
    upgradeDirect: 10,
    username: ''
  })

  const handleOpenSuspenPopUp = () => {
    suspendPopUpRef.current.setMainState({ isOpen: true, deleteID: 0, itemName: "" });
  };

  const _handleUpdateUserAPI = () => {
    handleUpdateUserAPI(
      mainState.username,
      getArrayObjByLabel(STATIC_VARIABLE.userStatusArray, "Suspend").value,
      userID,
      _handleChange
    ).then((isSuccess) => {
      if (isSuccess) {
        history.push('/users/list-user');
      }
    });
  };

  const _handleGetUserDetails = () => {
    handleGetUserDetailsAPI(setStateHelper(setMainState), userID, _handleChange);
  }

  useEffect(() => {
    _handleGetUserDetails()
  }, [])

  return (
    <Fragment>
      <Breadcrumb title={"Edit User"} parent="User" />
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <div className="customize-header-bottom-container">
              <h5>Account Details</h5>
              {(mainState.pureStatus !== 2 && mainState.isShop !== 1) &&
                <Button
                  type="button"
                  color="secondary"
                  outline
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    handleOpenSuspenPopUp();
                  }}
                >
                  Suspend Account
                </Button>
              }
            </div>
          </CardHeader>
          <CardBody>
            <Tabset userID={userID} />
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <h5>Downline Details</h5>
          </CardHeader>
          <CardBody>
            <ListSetUser userID={userID} />
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <h5>Monthly Point Claim Details</h5>
          </CardHeader>
          <CardBody>
            <PVPointUser userID={userID} upgradeDirect={mainState.upgradeDirect} />
          </CardBody>
        </Card>
        <DeletePopUp ref={suspendPopUpRef} isSuspend handleDelete={() => _handleUpdateUserAPI()} />
      </Container>
    </Fragment>
  );
};

export default UserDetails;
