import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, FormGroup } from "reactstrap";
import { useProps } from "../../contexts";
import moment from "moment";
import { saveAs } from 'file-saver';
import { Tabs, TabList, Tab } from "react-tabs";
import {Grid, IconButton, Stack} from "@mui/material";
//hooks
import useQuery from "../../hooks/useQuery";
//Component
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../common/datatable";
//Controller
import {setStateHelper, setClaimStatusTag, setTargetStatusTag, setPriceFormat, getArrayObjByValue} from "../../utils";
import {handleGetClaimListAPI, handleGetClaimListExcelAPI} from "../../controller/claims";
import { STATIC_VARIABLE } from "../../constants/static-data";
import SearchInput from "../common/search-input";
import DateFilter from "../common/datepicker";
import LoadingButton from "@mui/lab/LoadingButton";
import IosShareIcon from "@mui/icons-material/IosShare";
import ExcelClaimList from "../../export/excel/ExcelClaimList";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {apiMiddleware} from "../../utils/middleware";
import {getClaim} from "../../services/claims";
import ClaimPDF from "../../export/pdf/ClaimPDF";
import { pdf } from '@react-pdf/renderer';

const generateBody = (body, page, rowsPerPage, handleOpen, handleDownloadPDF) => {
  return body.map((e, i) => ({
    id: <p style={{ fontWeight: "bold" }}>{(page - 1) * rowsPerPage + i + 1}.</p>,
    date: moment(e.createdAt).format("MM-YYYY"),
    user: (
      <a className={"customize-a"} href={`/users/details-user/${e.memberID}`}>
        #{e.memberID} {e.username}
      </a>
    ),
    firstLayer: e.firstLayer,
    secondLayer: e.secondLayer,
    shopLayer: e.shopLayer == '-' ? 0 : e.shopLayer,
    total: "RM " + setPriceFormat(e.total),
    claimStatus: setClaimStatusTag(e.claimStatus),
    targetComplete: setTargetStatusTag(e.targetComplete ? 1 : 2),
    edit: (
      <Stack direction='row' justifyContent='center' alignItems='center'>
        <a href={`/claims/details-claim/${e.ID}`}>
          <i
            // onClick={() => handleOpen(e.ID)}
            className="fa fa-pencil"
            style={{
              width: 35,
              fontSize: 20,
              padding: 11,
              color: "rgb(40, 167, 69)",
              cursor: "pointer",
            }}
          />
        </a>

        <IconButton onClick={handleDownloadPDF(e.ID)}>
          <PictureAsPdfIcon sx={{ fontSize: '18px' }} />
        </IconButton>
      </Stack>
    ),
  }));
};

const header = [
  { id: "id", label: "", align: "center" },
  { id: "date", label: "Date", align: "left" },
  { id: "user", label: "User", align: "left" },
  { id: "firstLayer", label: "First Layer Point", align: "left" },
  { id: "secondLayer", label: "Second Layer Point", align: "left" },
  { id: "shopLayer", label: "Shop Point", align: "left" },
  { id: "total", label: "Total Amount", align: "left" },
  { id: "claimStatus", label: "Claim Status", align: "left" },
  { id: "targetComplete", label: "Target Complete", align: "left" },
  { id: "edit", label: "", align: "center" },
];

const ClaimList = () => {
  const [query, setQuery] = useQuery();
  const history = useHistory();
  const { _handleChange } = useProps();
  const [excelFormat, setExcelFormat] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pdfData, setPDFData] = useState(false);
  const [mainState, setMainState] = useState({
    list: [],
    page: query.page ? parseInt(query.page) : 1,
    limit: query.limit ? parseInt(query.limit) : 10,
    total: 10,
    order: query.order || "desc",
    orderBy: query.orderBy || "createdAt",
    orderStatus: query.orderStatus || 0,
    search: "",
    refreshData: false,
    startDate: moment().subtract("3", "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const handleOpenEdit = (id) => {
    history.push(`/claims/details-claim/${id}`);
  };

  const setDownloadPDF = (ID: any) => async () => {
    const { data, success } = await apiMiddleware(_handleChange, getClaim, ID);

    if (success) {
      data.claimStatusLabel = getArrayObjByValue(STATIC_VARIABLE.claimStatusArray, data.claimStatus).label;
      data.levelLabel = getArrayObjByValue(STATIC_VARIABLE.levelStatusArray, data.claimDetails.tier).label;
      data.shopLabel = data.claimDetails.isShop ? 'Yes' : 'No'
      data.bank.name = data.bank.name || '-'
      data.bank.recipientName = data.bank.recipientName || '-'
      data.bank.account = data.bank.account || '-'
      data.claimDetails.phoneNumber = data.claimDetails.phoneNumber || '-'
      data.claimDetails.email = data.claimDetails.email || '-'
      // setPDFData(data)
      const blob = await pdf((
        <ClaimPDF details={data}/>
      )).toBlob();

      saveAs(blob, `claim-${data.claimDetails.username}-${moment(data.createdAt).format('YYYYMM')}.pdf`);
    }
  }

  const handleExport = async () => {
    setLoading(true)

    const queryObj = {
      order: mainState.order,
      orderBy: mainState.orderBy,
      search: mainState.search,
      orderStatus: mainState.orderStatus,
      startDate: mainState.startDate,
      endDate: mainState.endDate,
      changeContext: _handleChange,
    };

    const { data, success } = await handleGetClaimListExcelAPI(queryObj)
    if (success) {
      const targetID = document.getElementById('claim-export')
      setExcelFormat(data)
      setTimeout(function() {
        targetID.click()
      }, 1000)
    }

    setTimeout(function() {
      setLoading(false)
    }, 1000)
  }

  const _handleGetClaimsList = () => {
    const queryObj = {
      page: mainState.search ? 1 : mainState.page,
      limit: mainState.limit,
      order: mainState.order,
      orderBy: mainState.orderBy,
      search: mainState.search,
      orderStatus: mainState.orderStatus,
      startDate: mainState.startDate,
      endDate: mainState.endDate
    };
    const param = {
      ...queryObj,
      changeContext: _handleChange,
      setMainState: setStateHelper(setMainState),
    };
    setQuery(queryObj);
    handleGetClaimListAPI(param);
  };

  const body = generateBody(mainState.list, mainState.page, mainState.limit, handleOpenEdit, setDownloadPDF);

  const _handleChangeStatus = (e) => {
    let value = e;
    if (e === 3 ) {
      value = e + 1
    }
    setStateHelper(setMainState)({ orderStatus: value });
  };

  useEffect(() => {
    _handleGetClaimsList();
  }, [
    mainState.page,
    mainState.order,
    mainState.limit,
    mainState.orderBy,
    mainState.orderStatus,
    mainState.search,
    mainState.startDate,
    mainState.endDate,
    mainState.refreshData,
  ]);

  return (
    <Fragment>
      <Breadcrumb title="Claim List" />
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <Stack direction='row' justifyContent='space-between' alignItems="center" sx={{ width: '100%', mb: 2 }}>
              <h5>List of Claims</h5>
              <LoadingButton
                onClick={handleExport}
                startIcon={<IosShareIcon />}
                loading={loading}
                loadingPosition="center"
                variant="outlined"
              >
                Export
              </LoadingButton>
            </Stack>
          </CardHeader>
          <CardBody>
            <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
              <Tabs
                defaultIndex={mainState.orderStatus}
                onSelect={(e) => {
                  _handleChangeStatus(e);
                }}
              >
                <TabList className="nav nav-tabs customize-filter-tab-header">
                  <Tab className="nav-link f-w-600">All</Tab>
                  {STATIC_VARIABLE.claimStatusArray.map((e) => {
                    if (e.value === 3) return
                    return <Tab className="nav-link f-w-600">{e.label}</Tab>;
                  })}
                </TabList>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid items sx={6}>
                    <FormGroup className="row">
                      <SearchInput
                        onChange={(e) => {
                          setStateHelper(setMainState)({ search: e });
                        }}
                        value={mainState.search}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid items sx={6}>
                    <DateFilter
                      startDate={mainState.startDate}
                      endDate={mainState.endDate}
                      handleSearch={(startDate, endDate) => {
                        const _startDate = moment(startDate).format("YYYY-MM-DD");
                        const _endDate = moment(endDate).format("YYYY-MM-DD");
                        setStateHelper(setMainState)({ startDate: _startDate, endDate: _endDate });
                      }}
                    />
                  </Grid>
                </Grid>

                <Datatable
                  // multiSelectOption={true}
                  multiSelectOption={false}
                  header={header}
                  myData={body}
                  // myData={data}
                  order={mainState.order}
                  orderBy={mainState.orderBy}
                  setState={setStateHelper(setMainState)}
                  rowsPerPage={mainState.limit}
                  count={mainState.total}
                  page={mainState.page}
                  total={mainState.total}
                  pagination={true}
                  class="-striped -highlight"
                />
              </Tabs>
            </div>
          </CardBody>
        </Card>
      </Container>
      <ExcelClaimList items={excelFormat} />

      {/*{pdfData && <ClaimPDF details={pdfData} />}*/}
    </Fragment>
  );
};

export default ClaimList;
