import React, { Fragment, useState, useEffect, useRef } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { STATIC_VARIABLE } from "../../constants/static-data";
import { getArrayObjByValue } from "../../utils";

const DetailsSetClaim = ({userDetails, bankDetails}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [profileMainState, setProfileMainState] = useState({
    memberID: 1,
    username: null,
    phoneNumber: null,
    email: "james@gmail.com",
    claimStatus: 4,
    isShop: false,
    tier: 3,
  });

  const [bankMainState, setBankMainState] = useState({
    name: "",
    account: "",
    recipientName: "",
    icImg: "",
    billingAddress: {
      address: "",
      state: "",
      postcode: 81000,
      country: "",
    },
  });

  useEffect(() => {
    setProfileMainState(userDetails);
    setBankMainState(bankDetails);
  }, [userDetails, bankDetails]);

  const FormDiv = ({label, type, id}) => {
    let state = tabIndex == 0 ? profileMainState : bankMainState;
    let value = state[id] != null ? state[id] : '-';
    if (id === 'tier') {
      value = getArrayObjByValue(STATIC_VARIABLE.levelStatusArray, value).label
    }

    if (id === 'claimStatus') {
      value = getArrayObjByValue(STATIC_VARIABLE.claimStatusArray, value).label
    }

    if (id === 'isShop') {
      value = value ? 'Yes' : 'No';
    }

    if (id === 'billingAddress') {
      let address = ''

      if (value.address1) {
        address = value.address1 + "," + value.address2 + ",\n" + value.postcode + " " + value.state + " " + value.country;
      }
      value = address;
    }

    let input =  <Input
      className="form-control col-xl-8 col-md-7"
      id={id}
      type={type}
      disabled={true}
      value={value}
      required=""
    />

    if (type === 'link' && id === 'username') {
      input = <a
        className={'customize-a'}
        style={{padding: '0.48rem 0rem'}}
        href={`/users/details-user/${state.memberID}`} >
          #{state.memberID} {value}
        </a>
    }

    return (
      <FormGroup className="row">
        <Label className="col-xl-3 col-md-4">
         {label}
        </Label>
        {input}
      </FormGroup>
    );
  }

	return (
		<Fragment>
			<Tabs
      defaultIndex={tabIndex}
      onSelect={(e) => {
        setTabIndex(e);
      }}>
				<TabList className="nav nav-tabs customize-filter-tab-header">
					<Tab className="nav-link">General</Tab>
					<Tab className="nav-link">Bank</Tab>
				</TabList>
				<TabPanel>
					<Form className="needs-validation user-add" noValidate="">
            <Row>
              <Col xl="6">
                <FormDiv label={'Claim By'} id={'username'} type={'link'}/>
                <FormDiv label={'Phone'} id={'phoneNumber'} type={'phone'}/>
                <FormDiv label={'Email'} id={'email'} type={'email'}/>
              </Col>
              <Col xl="6">
                <FormDiv label={'Claim Status'} id={'claimStatus'} type={'text'}/>
                <FormDiv label={'Tier'} id={'tier'} type={'text'}/>
                <FormDiv label={'Shop'} id={'isShop'} type={'text'}/>
              </Col>
            </Row>
					</Form>
				</TabPanel>
        <TabPanel>
					<Form className="needs-validation user-add" noValidate="">
            <Row>
              <Col xl="6">
                <FormDiv label={'Bank Name'} id={'name'} type={'text'}/>
                <FormDiv label={'Recipient Name'} id={'recipientName'} type={'text'}/>
              </Col>
              <Col xl="6">
                {/* <FormDiv label={'Billing Address'} id={'billingAddress'} type={'textarea'}/> */}
                <FormDiv label={'Bank Account'} id={'account'} type={'text'}/>
                <FormGroup className="row">
                    <Label className="col-xl-3 col-md-4">
                    IC Image:
                    </Label>
                    {bankMainState.icImg &&
                      <Col xl="3">
                        <img style={{width: '100%'}} src={bankMainState.icImg} />
                      </Col>
                    }
                  </FormGroup>
              </Col>

              
            </Row>
					</Form>
				</TabPanel>
			</Tabs>
		</Fragment>
	);
};

export default DetailsSetClaim;
