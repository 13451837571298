import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { STATIC_VARIABLE } from '../../constants/static-data';

const steps = STATIC_VARIABLE.shopStatusArray.slice(0,4);
const stepDescription = [
  <div>
    Press "Next" to verify Shop Account.
  </div>,
  <div>Waiting Shop Payment Complete.</div>,
  <div>Press "Next" after the stock has been shipped.</div>,
  <div>Press "Finish" after the Shop received the stock.</div>,
]
export default function HorizontalLinearStepper({props, handleVerify, handleCompleteShop}) {
  const [activeStep, setActiveStep] = React.useState(0);
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  const handleNext = () => {
    if (activeStep == 0) {
      handleVerify();
    }
    else if (activeStep == 2) {
      handleCompleteShop();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const getEditByAdmin = (key) => {
    const index = key == 'verify' ? 0 : 1
    const obj = props && props.statusLog.length > 0 && props.statusLog[index][key].admin;
    let name = '-';
    if (obj) {
      name = obj.firstName + ' ' + obj.lastName;
    }

    return name
  };

  React.useEffect(() => {
    if (props) {
      setActiveStep(props.status - 1)
    }
  }, [props]);
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} orientation={matchesSM ? 'vertical' : 'horizontal'}>
        {steps.map((e, index) => {
          let addText = '';
          if (index == 0 && activeStep >= index) {
            addText =  '[ Verify By: ' + getEditByAdmin('verify') + ' ]';
          }
          if (index == 3 && activeStep >= index) {
            // const obj = props.statusLog && props.statusLog[index].completed.admin;
            // const name = obj.firstName + ' ' + obj.lastName;
            addText =  '[ Completed By: ' + getEditByAdmin('complete') + ' ]';
          }
          return (
            <Step key={e.label}>
              <StepLabel>{e.label} <span className="f-w-600">{addText}</span></StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            Verify Steps Completed
          </Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
          {stepDescription[activeStep]}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            {
              activeStep != 1 &&
              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            }

          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
