import React, { Fragment, useState, useEffect, useRef } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Form } from "reactstrap";
import { useProps } from "../../contexts";
import { handleGetShopStockTransactionListAPI } from "../../controller/shop";
import { setStateHelper, setStockTransactionStatusTag } from "../../utils";
import Datatable from "../common/datatable";
import CommissionModal from "../common/modal/commission-modal";
import moment from "moment";
import ProductListImage from "../common/product-list-img";

const header = [
  { id: "id", label: "", align: "center" },
  // { id: "photo", label: "Images", align: "center" },
  { id: "photos", label: "Item(s)", align: "center", sortable: false },
  { id: "quantity", label: "Quantity", align: "center", sortable: false },
  { id: "status", label: "Status", align: "center", sortable: false },
  { id: "submitBy", label: "Submit By", align: "center", sortable: false },
  { id: "orderID", label: "Order ID", align: "center", sortable: false },
  { id: "createdAt", label: "Created Date", align: "center", sortable: false },
];

const generateBody = (body, page, rowsPerPage) => {
  return body.map((e, i) => {
    let photoPath = null;
    if ("photo" in e) {
      if (e.photo.length > 0) {
        photoPath = e.photo[0].URL;
      }
    }
    let submitBy = '-'
    if (e.admin.ID) {
      submitBy = e.admin.firstName + ' ' + e.admin.lastName;
    }
    return {
      id: <p style={{ fontWeight: "bold" }}>{(page - 1) * rowsPerPage + i + 1}.</p>,
      photos: (
        <ProductListImage photoPath={photoPath} label={e.productName}/>
      ),
      quantity: e.stock,
      orderID: e.orderID || '-',
      status: setStockTransactionStatusTag(e.type),
      submitBy: submitBy,
      createdAt: <div style={{whiteSpace: "break-spaces"}}>{moment(e.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>,
    };
  });
};

const StockTransactionList = ({ shopID, isRefresh }) => {
  const { _handleChange } = useProps();
  const commissionModelRef = useRef();
  const [mainState, setMainState] = useState({
    list: [],
    page: 1,
    limit: 10,
    total: 10,
    order: "desc",
    orderBy: "createdAt",
    search: "",
    refreshData: false,
    startDate: "",
    endDate: "",
  });

  const _handleGetStockTransactionList = () => {
    const queryObj = {
      page: mainState.search ? 1 : mainState.page,
      limit: mainState.limit,
      order: mainState.order,
      orderBy: mainState.orderBy,
    };
    const param = {
      ...queryObj,
      shopID,
      changeContext: _handleChange,
      setMainState: setStateHelper(setMainState),
    };
    handleGetShopStockTransactionListAPI(param);
  };

  useEffect(() => {
    _handleGetStockTransactionList();
  }, [
    mainState.page,
    mainState.order,
    mainState.orderBy,
    mainState.tabIndex,
    JSON.stringify(...mainState.list),
    isRefresh,
  ]);

  const body = generateBody(mainState.list, mainState.page, mainState.limit);

  return (
    <Fragment>
      <Tabs
        defaultIndex={mainState.tabIndex}
        onSelect={(e) => {
          setStateHelper(setMainState)({ tabIndex: e });
        }}
      >
        <TabList className="nav nav-tabs tab-coupon">
          <Tab className="nav-link">Transaction History</Tab>
        </TabList>
        <TabPanel>
          <Form className="needs-validation user-add" noValidate="">
            <div className="permission-block">
              <div className="attribute-blocks">
                <Datatable
                  // multiSelectOption={true}
                  multiSelectOption={false}
                  header={header}
                  myData={body}
                  order={mainState.order}
                  orderBy={mainState.orderBy}
                  setState={setStateHelper(setMainState)}
                  // myData={data}
                  rowsPerPage={mainState.limit}
                  count={mainState.total}
                  page={mainState.page}
                  total={mainState.total}
                  pagination={true}
                  class="-striped -highlight"
                />
              </div>
            </div>
          </Form>
        </TabPanel>
      </Tabs>
      <CommissionModal ref={commissionModelRef} handleSubmit={() => {}} />
    </Fragment>
  );
};

export default StockTransactionList;
