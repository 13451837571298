import fetchData from "../../utils/fetchData";

export function login(body) {
  return fetchData({
    url: "/admin/login",
    method: "POST",
    data: body,
  });
}

export function getDetails() {
  return fetchData({
    url: "/admin/details",
    method: "GET",
  });
}

export function updateDetails(body) {
  return fetchData({
    url: "/admin/details",
    method: "PUT",
    data:body,
  });
}

export function updatePassword(body) {
  return fetchData({
    url: "/admin/password",
    method: "PUT",
    data:body,
  });
}

export function checkLogin() {
  return fetchData({
    url: "/admin/check-login",
    method: "GET"
  });
}

export function logout(body) {
  return fetchData({
    url: "/admin/logout",
    method: "GET",
  });
}