import React, { Fragment, useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  ButtonDropdown,
  Label,
  Row,
} from "reactstrap";

import { useParams } from "react-router-dom";
import { useProps } from "../../contexts";
//Components
import Breadcrumb from "../common/breadcrumb";
//Controller
import { getArrayObjByValue, setOrderStatusTag, setPriceFormat, setStateHelper } from "../../utils";
import { STATIC_VARIABLE } from "../../constants/static-data";
import Datatable from "../common/datatable";
import CustomSelector from "../common/custom-selector";
import { handleGetUserOrderDetailsAPI } from "../../controller/order";
import ProductListImage from "../common/product-list-img";

const generateBody = (body, subTotal, totalAmount, appliedCoin, shoppingDollar, voucher, isMemberFee, registerFee) => {
  let arr = body.map((e, i) => ({
    photos: (
      <ProductListImage photoPath={e.photo.length > 0 ? e.photo[0].URL : null} label={e.title} point={e.pvPoint}/>
    ),
    unitPrice: <div style={{ overflow: "initial", whiteSpace: "break-spaces" }}>RM{setPriceFormat(e.unitPrice)}</div> ,
    quantity: e.quantity,
    subTotalSum: "RM " + (e.isBundle ? setPriceFormat(e.discountAmount) : setPriceFormat(e.unitPrice * e.quantity)),
  }));

  arr.push({
    photos: "",
    unitPrice: "",
    quantity: <div className="f-w-600">SubTotal</div>,
    subTotalSum: <div className="f-w-600">RM {setPriceFormat(subTotal)}</div>,
  });

  if (isMemberFee) {
    arr.push({
      photos: "Join Member Fee",
      unitPrice: "",
      quantity: "",
      subTotalSum: <div className="f-w-600">RM {registerFee}</div>,
    });
  }

  arr.push({
    photos: <div className="f-w-600">Shopping Coins: {appliedCoin} Coin(s)</div>,
    unitPrice: "",
    quantity: "",
    subTotalSum: (
      <div className="f-w-600" style={{ color: "red" }}>
        -RM {setPriceFormat(shoppingDollar)}
      </div>
    ),
  });

  arr.push({
    photos: <div className="f-w-600">Voucher Discount: {voucher.voucherCode ? <a className={'customize-a'} href={'#'}>#{voucher.voucherCode}</a> : '-'}</div>,
    unitPrice: "",
    quantity: "",
    subTotalSum: (
      <div className="f-w-600" style={{color: 'red'}}> -RM {voucher.voucherDiscount ? setPriceFormat(voucher.voucherDiscount)  : '0.00'}</div>
    ),
  });

  arr.push({
    photos: "",
    unitPrice: "",
    quantity: <div className="f-w-600">Total</div>,
    subTotalSum: <div className="f-w-600">RM {setPriceFormat(totalAmount)}</div>,
  });

  return arr;
};

const header = [
  { id: "photos", label: "Item(s)", align: "center", sortable: false },
  { id: "unitPrice", label: "Unit Price", align: "center", sortable: false },
  { id: "quantity", label: "Quantity", align: "center", sortable: false },
  { id: "subTotalSum", label: "Sub Total", align: "center", sortable: false },
];

const OrderDetailsShop = () => {
  const { orderID } = useParams();
  const { _handleChange } = useProps();
  const [mainState, setMainState] = useState({
    ID: "#",
    orderStatus: 2,
    product: [],
    subTotal: 0,
    coinReceive: 0,
    totalPoint: 0,
    totalAmount: 0,
    receiverInfo: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: ''
    },
    shop: {
      ID: 25,
      name: "",
      phoneNumber: "",
      shopCode: "",
      email: "",
      SSMID: "",
      code: "",
      stock: 0,
      status: 1,
      address: {
        address1: "",
        address2: "",
        postalCode: '',
        city: "",
        state: "",
        country: "",
      },
    },
    address: {
      shippingAddress: {
        address1: "",
        address2: "",
        postalCode: '',
        city: "",
        state: "",
        country: "",
      },
      billingAddress: {
        address1: "",
        address2: "",
        postalCode: "",
        city: "",
        state: "",
        country: "",
      }
    },
    remarks: "",
    username: "",
    userID: 0,
    phone: "",
    email: "",
    type: 1,
    appliedCoin: 0,
    coinValue: 0,
    shippingFee: 0,
    isMemberFee: false,
    registerFee: "0.00",
    voucher: {
      voucherTitle: null,
      voucherCode: null
    },
    firstname: "Wilson",
    lastname: "Wong",
    country: "Malaysia 3",
    createdAt: "2022-05-17",
    updateAt: "2022-05-17",
    upperLine: null,
    upLine: null
  });

  const body = generateBody(
    mainState.product,
    mainState.subTotal,
    mainState.totalAmount,
    mainState.appliedCoin,
    mainState.coinValue,
    mainState.voucher,
    mainState.isMemberFee,
    mainState.registerFee
  );

  const _handleGetOrderAPI = () => {
    handleGetUserOrderDetailsAPI(setStateHelper(setMainState), orderID, _handleChange);
  };

  useEffect(() => {
    _handleGetOrderAPI();
  }, [mainState.refreshData]);

  const _addressCombine = (obj) => {
    let address1 = obj.address1 ? obj.address1 + ',' : '';
    let address2 = obj.address2 ? obj.address2 + ',': '';
    let address =
    address1 + address2 + "\n" + obj.postalCode + " " + obj.city + " " + obj.state +  " " + obj.country;
    return address;
  };

  const FormDiv = useCallback(({ value, label, type, id, disabled = false, isNormalLink = false, directID }) => {
    let input = (
      <Input
        className="form-control col-md-12 col-xs-6"
        id={id}
        type={type}
        readonly={disabled ? "readonly" : false}
        required=""
        value={value}
        onChange={(e) => {
          setStateHelper(setMainState)({ [e.target.id]: e.target.value });
        }}
      />
    );
    if (type == "link") {
      let href = '#';
      if (id === "username") {
        href = `/users/details-user/${directID}`;
      }
      if (id === "shop.name") {
        href = `/shop/details-shop/${directID}`;
      }

      if (id === "email") {
        href = `mailto: ${value}`;
      }

      if (id === "phone") {
        href = `tel: ${value}`;
      }

      if (id === "address") {
        href = `http://maps.google.com/?q=${value}`;
      }

      input = (
        <a className={"customize-a"} style={{ flex: 0.7 }} href={value ? href : '#'} target={id === 'address' && '_blank'}>
          {value}
        </a>
      );


      if (value == '-') {
        input = (
          <div className="empty-label">
            {value}
          </div>
        );
      }
    }

    if (type == "drop") {
      let cloneArr = [...STATIC_VARIABLE.orderStatusArray];
      cloneArr.shift();
      cloneArr.shift();
      input = (
        <div style={{ flex: "0.95" }}>
          <CustomSelector
            initStatus={getArrayObjByValue(cloneArr, mainState.orderStatus)}
            options={cloneArr}
            disabled={disabled}
            handleChange={(e) => {
              // setMainState({
              //   role: e.value,
              // });
            }}
          />
        </div>
      );
    }
    return (
      <FormGroup className={type === "link" && 'row'} style={type === "link" && !isNormalLink ? { marginBottom: 5 } : {}}>
        <Label className={type === "link" && !isNormalLink ? "col-lg-6 col-md-3 col-6" : ""}>
          {label}
        </Label>
        {input}
      </FormGroup>
    );
  }, []);

  return (
    <Fragment>
      <Breadcrumb title={`Order Details [With Shop Code]`} parent="Sales" />
      <Container fluid={true}>
        <Row className="product-adding">
          <Col xl="8">
            <Card>
              <CardHeader className={"customize-order-table-header"}>
                <h5>Order General [{mainState.ID}] </h5>
                <h7 style={{ marginLeft: 10 }}>{setOrderStatusTag(mainState.orderStatus)}</h7>
              </CardHeader>
              <CardBody>
                <div className="digital-add needs-validation">
                  <Row>
                    <Col xs={12} md={6} className='mx-auto'>
                      <FormDiv value={mainState.ID} label={"Order ID"} id={"ID"} type={"text"} disabled />
                      <FormDiv
                        value={
                          getArrayObjByValue(STATIC_VARIABLE.orderStatusArray, mainState.orderStatus).label
                        }
                        label={"Order Status"}
                        id={"orderStatus"}
                        type={"text"}
                        disabled
                      />
                    </Col>
                    <Col xs={12} md={6} className='mx-auto'>
                      <FormDiv
                        value={mainState.createdAt}
                        label={"Create Date"}
                        id={"createdAt"}
                        type={"text"}
                        disabled
                      />
                      <FormDiv
                        label={"Shop Code"}
                        id={"shop.shopCode"}
                        type={"text"}
                        value={mainState.shop.shopCode}
                        disabled
                      />
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h5>Order Product</h5>
              </CardHeader>
              <CardBody>
                <Datatable
                  header={header}
                  multiSelectOption={false}
                  // myData={data}
                  myData={body}
                  pageSize={mainState.total}
                  // pageSize={9}
                  pagination={false}
                  class="-striped -highlight"
                />
                  <FormGroup style={{ marginTop: 30, marginBottom: 0 }}>
                  <div className="digital-add needs-validation">
                    <Row>
                      <Col sx={12} md={6} className='mx-auto'>
                        <FormDiv
                          label={"Shopping Dollar"}
                          id={"coinReceive"}
                          type={"text"}
                          value={mainState.coinReceive ? setPriceFormat(mainState.coinReceive) : '0.00'}
                          disabled
                        />
                      </Col>
                      <Col sx={12} md={6} className='mx-auto'>
                        <FormDiv
                          label={"Total Point"}
                          id={"totalPoint"}
                          type={"text"}
                          value={mainState.totalPoint}
                          disabled
                        />
                      </Col>
                    </Row>
                  </div>
                </FormGroup>
                <FormGroup style={{ marginTop: 20 }}>
                  <Label className="col-form-label pt-0">Remarks</Label>
                  <Input
                    readonly={"readonly"}
                    id={"remark"}
                    type={"textarea"}
                    required=""
                    value={mainState.remarks}
                  />
                </FormGroup>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h5>Recipient Details</h5>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <div className="digital-add needs-validation">
                    <Row>
                      <Col xs={12} md={6} className='mx-auto'>
                        <FormDiv
                          label={"Recipient Name"}
                          id={"name"}
                          type={"text"}
                          value={mainState.receiverInfo.firstName + ' ' + mainState.receiverInfo.lastName}
                          disabled
                        />
                      </Col>
                      <Col xs={12} md={6} className='mx-auto'>
                        <FormDiv
                          label={"Recipient Email"}
                          id={"email"}
                          type={"text"}
                          value={mainState.receiverInfo.email}
                          disabled
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <FormDiv
                          label={"Recipient Phone"}
                          id={"phoneNumber"}
                          type={"text"}
                          value={mainState.receiverInfo.phoneNumber}
                          disabled
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <FormDiv
                          label={"Billing Address"}
                          id={"billingAddress"}
                          type={"textarea"}
                          value={_addressCombine(mainState.address.billingAddress)}
                          disabled
                        />
                      </Col>
                    </Row>
                  </div>
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card>
              <CardHeader>
                <h5>Purchased By</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xl="12">
                    <FormDiv
                      value={mainState.username ? "#" + mainState.userID + " " + mainState.username : '-'}
                      label={"Username"}
                      id={"username"}
                      type={"link"}
                      directID={mainState.userID}
                    />
                    <FormDiv value={mainState.phone ? mainState.phone : '-'} label={"Contact No."} id={"phone"} type={"link"} />
                    <FormDiv value={mainState.email ? mainState.email : '-'} label={"Email"} id={"email"} type={"link"} />
                    {
                      mainState.upLine &&
                      <FormDiv
                        value={mainState.upLine.username ? "#" + mainState.upLine.ID + " " + mainState.upLine.username : "-"}
                        label={"Up Line"}
                        id={'upLine'}
                        type={"link"}
                        userID={mainState.upLine.ID}
                      />
                    }
                    {
                      mainState.upperLine &&
                      <FormDiv
                        value={mainState.upperLine.username ? "#" + mainState.upperLine.ID + " " + mainState.upperLine.username : "-"}
                        label={"Upper Line"}
                        id={'upperLine'}
                        type={"link"}
                        userID={mainState.upperLine.ID}
                      />
                    }
                 
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h5>Serviced By</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xl="12">
                    <FormDiv
                      value={"#" + mainState.shop.ID + " " + mainState.shop.name}
                      label={"Shop"}
                      id={"shop.name"}
                      type={"link"}
                      directID={mainState.shop.ID }
                    />
                    <FormDiv
                      value={mainState.shop.phoneNumber ? mainState.shop.phoneNumber : '-'}
                      label={"Contact No."}
                      id={"phone"}
                      type={"link"}
                    />
                    <FormDiv
                      value={mainState.shop.email ? mainState.shop.email : '-'}
                      label={"Email"}
                      id={"email"}
                      type={"link"}
                    />
                    <FormDiv
                      value={_addressCombine(mainState.shop.address)}
                      label={"Address"}
                      id={"address"}
                      type={"link"}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default OrderDetailsShop;
