import { useRef, useState } from 'react';
import { Calendar } from 'react-feather';
import Picker from 'react-month-picker';
import moment from 'moment';
import "react-month-picker/css/month-picker.css";

const MonthlyDataPicker = ({onChange}) => {
  const pickRange = useRef(null);
  
  const [rangeValue, setRangeValue] = useState({from: {year: 2020, month: 1}, to: {year: parseInt(moment().format('YYYY')), month: parseInt(moment().format('M'))}});
  console.log(rangeValue)
  const pickerLang = {
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    from: 'From', to: 'To',
    }
    const makeText = m => {
      if (m && m.year && m.month) return (pickerLang.months[m.month-1] + '. ' + m.year)
      return '?'
    }

  return(
    <Picker
      ref={pickRange}
      years={{min: 2013}}
      value={rangeValue}
      lang={pickerLang}
      theme="light"
      onDismiss={(e) => {
        setRangeValue(e);
        onChange(e);
      }}
    >
      <div 
        className={'customize-picker-m'}
        onClick={(e) => {
            pickRange.current.show()
        }}>
        <Calendar className={'customize-picker-icon'}/>
        <label>{makeText(rangeValue.from) + ' - ' + makeText(rangeValue.to)}</label>
      </div>
    </Picker>
  );
}

export default MonthlyDataPicker;
