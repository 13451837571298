import React, { useEffect, useState, useImperativeHandle } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Datatable from "../datatable";

const header = [
  { id: "id", label: "Layer", align: "center" },
  { id: "point", label: "Total PV", align: "left" },
  { id: "rate", label: "Commission Rate", align: "left" },
  { id: "amount", label: "Amount(RM)", align: "left" },
];

const generateBody = (body) => {
  return body.map((e, i) => ({
    id: e.id,
    // photo: <img alt="" src={e.avtar} style={{ width: 50, height: 50 }} />,
    point: e.point ? e.point + 'PV' : '',
    rate: e.rate ? e.rate + "%" : 'Total:',
    amount: 'RM' + e.amount,
  }));
};


const CommissionModal = React.forwardRef((props, ref) => {
  const { handleSubmit = () => {}} = props;
  const [mainState, setMainState] = useState({
    list:[1,2],
    id:0,
    title: "",
    content: "",
    isOpen: false,
  });

  const handleTextInput = (event, key) => {
    setMainState({ ...mainState, [key]: event.target.value });
  };

  const handleClearData = () => {
    setMainState({
      title: "",
      content: "",
      showInFilter: 1,
    });
  };

  const handleOpen = () => {
    setMainState({ isOpen: true });
  };

  const handleClose = () => {
    setMainState({
      list:[],
      id:0,
      title: "",
      content: "",
      isOpen: false,
    });
  };

  const _handleSubmit = () => {
    const returnDetails = {
      title: mainState.title,
      content: mainState.content,
      showInFilter: mainState.showInFilter,
    };
    handleSubmit(returnDetails,mainState.isCreate,mainState.id);
  };

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleClose,
    setMainState,
  }));

  const body = generateBody(
    mainState.list,
  );

  return (
    <Modal isOpen={mainState.isOpen} toggle={handleClose}>
      <ModalHeader toggle={handleClose}>
        <h5 className="modal-title f-w-600">
          May 2020
        </h5>
      </ModalHeader>
      <ModalBody>
        <Form>
          <Datatable
            // multiSelectOption={true}
            multiSelectOption={false}
            header={header}
            myData={body}
            // myData={data}
            pageSize={10}
            pagination={false}
            class="-striped -highlight"
          />
        </Form>
      </ModalBody>
      <ModalFooter style={{justifyContent: 'flex-start'}}>
      <div>
        <span className="customize-commission-target-text">
          <i
            onClick={handleOpen}
            className={mainState.isHitTarget ? "fa fa-check-circle-o customize-commission-green" : "fa fa-times-circle-o customize-commission-red"}
            style={{
              paddingRight: 10,
              fontSize: 17,
            }}
          > </i>
          <div 
            className={mainState.isHitTarget ? "customize-commission-green" : "customize-commission-red"} style={{marginTop: -2}}>
            {mainState.isHitTarget ? `personal sales less then 10 order & total PV point didn't hit the target` : `personal sales more then 10 order & total PV point hit the target`}
            </div>
        </span>
      </div>
        {/* <Button type="button" color="primary" onClick={_handleSubmit}>
          {mainState.isCreate ? "Create" : "Save"}
        </Button>
        <Button
          type="button"
          color="secondary"
          onClick={() => {
            handleClearData();
            handleClose();
          }}
        >
          Close
        </Button> */}
      </ModalFooter>
    </Modal>
  );
});
export default CommissionModal;
