import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Form, FormGroup } from "reactstrap";
import { useProps } from "../../contexts";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";

//hooks
import useQuery from "../../hooks/useQuery";
//Component
import Breadcrumb from "../common/breadcrumb";
import Datatable from "../common/datatable";
import DeletePopUp from "../common/modal/DeletePopUp";
import CreateUserModal from "../common/modal/CreateUserModal";
//Controller
import { handleDeleteUserAPI, handleGetUserListAPI } from "../../controller/user";
import { setAccountStatusTag, setTierStatusTag, setShopStatusTag, setStateHelper } from "../../utils";
import SearchInput from "../common/search-input";
import { STATIC_VARIABLE } from "../../constants/static-data";

const generateBody = (body, page, rowsPerPage, handleEdit, handleDelete = () => {}) => {
  return body.map((e, i) => ({
    id: <p style={{ fontWeight: "bold" }}>{(page - 1) * rowsPerPage + i + 1}.</p>,
    username: e.username,
    firstName: e.firstName,
    lastName: e.lastName,
    email: e.email,
    tier: setTierStatusTag(e.level),
    shoppingDollars: e.coins,
    PVPoint: e.points,
    isShop: setShopStatusTag(e.isShop ? 1 : 2),
    status: setAccountStatusTag(e.status),
    edit: (
      <div>
        <a href={`/users/details-user/${e.ID}`}>
          <i
            className="fa fa-pencil"
            style={{
              width: 35,
              fontSize: 20,
              padding: 11,
              color: "rgb(40, 167, 69)",
              cursor: "pointer",
            }}
          />
        </a>
      </div>
    ),
  }));
};

const header = [
  { id: "id", label: "", align: "center" },
  // { id: "photo", label: "Images", align: "center" },
  { id: "email", label: "Email", align: "left" },
  { id: "username", label: "Username", align: "left" },
  { id: "firstName", label: "First Name", align: "left" },
  { id: "lastName", label: "Last Name", align: "left" },
  { id: "tier", label: "Tier", align: "center" },
  { id: "shoppingDollars", label: "Shopping Dollar", align: "center" },
  { id: "PVPoint", label: "Point", align: "center" },
  { id: "isShop", label: "Shop", align: "center" },
  { id: "status", label: "Status", align: "center" },
  { id: "edit", label: "Action", align: "center" },
];

const ListUser = () => {
  const [query, setQuery] = useQuery();
  const history = useHistory();
  const { _handleChange } = useProps();
  const deleteUserPopUpRef = useRef();

  const [mainState, setMainState] = useState({
    list: [],
    page: query.page ? parseInt(query.page) : 1,
    limit: query.limit ? parseInt(query.limit) : 10,
    total: 10,
    order: query.order || "desc",
    orderBy: query.orderBy || "createdAt",
    tier: query.tier || 0,
    shop: query.shop || 0,
    search: query.search || "",
    memberStatus: "",
    refreshData: false,
  });

  const handleRefreshData = () => {
    setStateHelper(setMainState)({ refreshData: !mainState.refreshData });
  };

  const handleOpenEdit = (details) => {
    history.push(`/users/details-user/${details.ID}`);
  };

  const handleOpenDeletePopUp = (id, itemName) => {
    deleteUserPopUpRef.current.setMainState({ isOpen: true, deleteID: id, itemName: itemName });
  };

  const handleDelete = (id) => {
    handleDeleteUserAPI(id, _handleChange).then((isSuccess) => {
      if (isSuccess) {
        handleRefreshData();
        deleteUserPopUpRef.current.handleClose();
      }
    });
  };

  const _handleGetUserList = () => {
    const queryObj = {
      page: mainState.search ? 1 : mainState.page,
      limit: mainState.limit,
      order: mainState.order,
      orderBy: mainState.orderBy,
      search: mainState.search,
      tier: mainState.tier,
      shop: mainState.shop,
    };
    const param = {
      ...queryObj,
      changeContext: _handleChange,
      setMainState: setStateHelper(setMainState),
    };
    setQuery(queryObj);
    handleGetUserListAPI(param);
  };

  const body = generateBody(
    mainState.list,
    mainState.page,
    mainState.limit,
    handleOpenEdit,
    handleOpenDeletePopUp
  );

  const changeMemberStatus = (e) => {
    let index = {
      tier: e,
      shop: 0
    };
    if (e == 4) {
      index = {
        tier: 0,
        shop: 1
      }
    }
    setStateHelper(setMainState)(index);
  }

  useEffect(() => {
    _handleGetUserList();
  }, [
    mainState.page,
    mainState.order,
    mainState.orderBy,
    mainState.limit,
    mainState.tier,
    mainState.total,
    mainState.search,
    mainState.shop,
    mainState.refreshData,
  ]);

  const currentTab = mainState.tier > 0 ? mainState.tier : mainState.shop == 1 ? 4 : mainState.tier;
  return (
    <Fragment>
      <Breadcrumb title="User List" parent="Users" />
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <h5>List of Users</h5>
          </CardHeader>
          <CardBody>
            <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
              <Tabs
                defaultIndex={currentTab}
                onSelect={(e) => {
                  changeMemberStatus(e)
                }}>
                <TabList className="nav nav-tabs customize-filter-tab-header">
                  <Tab className="nav-link f-w-600">All</Tab>
                  <Tab className="nav-link f-w-600">Member</Tab>
                  <Tab className="nav-link f-w-600">VIP</Tab>
                  <Tab className="nav-link f-w-600">VVIP</Tab>
                  <Tab className="nav-link f-w-600">Shop</Tab>
                </TabList>
                <Col xl="6">
                  <FormGroup className="row">
                    <SearchInput
                      onChange={(e) => {
                        setStateHelper(setMainState)({ search: e });
                      }}
                      value={mainState.search}
                    />
                  </FormGroup>
                </Col>
                  <Form className="needs-validation user-add" noValidate="">
                    <Datatable
                     header={header}
                     myData={body}
                     // myData={data}
                     order={mainState.order}
                     orderBy={mainState.orderBy}
                     setState={setStateHelper(setMainState)}
                     rowsPerPage={mainState.limit}
                     count={mainState.total}
                     page={mainState.page}
                     total={mainState.total}
                     pagination={true}
                     class="-striped -highlight"
                    />
                  </Form>
              </Tabs>
              <DeletePopUp ref={deleteUserPopUpRef} handleDelete={handleDelete} />
            </div>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default ListUser;
