import React, { useState, useEffect } from "react";
import Select from 'react-select';

const CustomSelector = ({initStatus, options, handleChange, disabled=false}) => {
  const [selectedOption, setSelectedOption] = useState(initStatus);

  const handleChangeSelect = (e) => {
    handleChange(e);
    setSelectedOption(e);
  }
  return (
      <Select
        isDisabled={disabled}
        defaultValue={initStatus}
        value={selectedOption}
        onChange={handleChangeSelect}
        options={options}
      />
  );
};

export default CustomSelector;
