import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import { useParams } from "react-router-dom";
import { useProps } from "../../contexts";
//Component
import Breadcrumb from "../common/breadcrumb";
import Tabset from "./tabset";
//Controller
import {
  handleCreateVoucherAPI,
  handleGetVoucherAPI,
  handleUpdateVoucherAPI,
} from "../../controller/voucher";

const CreateVoucher = () => {
  const { voucherID } = useParams();
  const isCreate = typeof voucherID === "undefined";
  const { _handleChange } = useProps();
  const [mainState, _setMainState] = useState({
    title: "", //*
    description: "",
    discount: 0,
    discountType: 1,
    type: 0, //*
    code: "", //*
    total: 0, //*
    minSpend: 0,
    points: 0, //*
    expiredDate: "",
    validDays: null,
    refreshData: false,
  });
  const history = useHistory();
  const setMainState = (newState) => {
    _setMainState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };
  const handleRefreshData = () => {
    setMainState({ refreshData: !mainState.refreshData });
  };

  const handleCreate = () => {
    if (isCreate) {
      handleCreateVoucherAPI(mainState, _handleChange).then((isSuccess) => {
        if (isSuccess) {
          history.push("/voucher/list-voucher");
        }
      });
    } else {
      handleUpdateVoucherAPI(mainState, _handleChange).then((isSuccess) => {
        if (isSuccess) {
          handleRefreshData()
        }
      });
    }
  };
  const handleTextInput = (event, statusKey) => {
    let value = event.target.value;
    if (statusKey === "type" || statusKey === "total") {
      value = parseInt(value);
    }

    if (statusKey === "status") {
      value = event.target.checked;
    }
    if (event.target.type === 'number') {
      if (parseFloat(value) <= 0) {
        value = 0;
      }
    }

    setMainState({ ...mainState, [statusKey]: value });
  };

  const handleSelectDate = (date, statusKey) => {
    // moment(date).format("YYYY-MM-DD")
    setMainState({ ...mainState, [statusKey]: date });
  };
  useEffect(() => {
    if (voucherID) {
      handleGetVoucherAPI(voucherID, setMainState, _handleChange);
    }
    else{
      setMainState({
        title: "", //*
        description: "",
        discount: 0,
        discountType: 1,
        type: 0, //*
        status: 0,
        code: "", //*
        total: 0, //*
        minSpend: 0,
        expiredDate: "",
        points: 0,
        validDays: null,
        refreshData: false,
      })
    }
  }, [mainState.refreshData,voucherID]);

  return (
    <Fragment>
      <Breadcrumb title={isCreate ? "Create Voucher" : "Edit Voucher"} parent="Voucher" />
      <Container fluid={true}>
        <Card>
          <CardHeader>
            <h5>Discount Voucher Details</h5>
          </CardHeader>
          <CardBody>
            <Tabset
              isCreate={isCreate}
              mainState={mainState}
              handleSubmit={handleCreate}
              handleTextInput={handleTextInput}
              handleSelectDate={handleSelectDate}
            />
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default CreateVoucher;
