import fetchData from "../../utils/fetchData";

export function getUserList(params) {
  return fetchData({
    url: "/user",
    method: "GET",
    params: params,
  });
}

export function getUser(userID) {
  return fetchData({
    url: `/user/${userID}`,
    method: "GET",
  });
}

export function getAllUser(userID) {
  return fetchData({
    url: `/all-user`,
    method: "GET",
  });
}

export function updateUser(userID, body) {
  return fetchData({
    url: `/user/${userID}`,
    method: "PUT",
    data: body,
  });
}

export function deleteUser(userID) {
  return fetchData({
    url: `/user/${userID}/details`,
    method: "DELETE",
  });
}

export function getUserDownList(userID, params) {
  return fetchData({
    url: `/user/${userID}/first-line`,
    method: "GET",
    params: params,
  });
}

export function getUserSecondDownList(userID, params) {
  return fetchData({
    url: `/user/${userID}/second-line`,
    method: "GET",
    params: params,
  });
}

export function getUserCommissionList(userID, params) {
  return fetchData({
    url: `/user/${userID}/commission`,
    method: "GET",
    params: params,
  });
}
