import React, { useEffect, useState, useImperativeHandle } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const CreateCategoryModal = React.forwardRef((props, ref) => {
  const { handleSubmit = () => {}} = props;
  const [mainState, setMainState] = useState({
    id:0,
    title: "",
    content: "",
    showInFilter: 1,
    isOpen: false,
    isCreate: false,
  });

  const handleTextInput = (event, key) => {
    setMainState({ ...mainState, [key]: event.target.value });
  };

  const handleClearData = () => {
    setMainState({
      title: "",
      content: "",
      showInFilter: 1,
    });
  };

  const handleOpen = () => {
    setMainState({ isOpen: true });
  };

  const handleClose = () => {
    setMainState({
      title: "",
      content: "",
      showInFilter: 1,
      isOpen: false,
      isCreate: false,
    });
  };

  const _handleSubmit = () => {
    const returnDetails = {
      title: mainState.title,
      content: mainState.content,
      showInFilter: mainState.showInFilter,
    };
    handleSubmit(returnDetails,mainState.isCreate,mainState.id);
  };

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleClose,
    setMainState,
  }));

  return (
    <Modal isOpen={mainState.isOpen} toggle={handleClose}>
      <ModalHeader toggle={handleClose}>
        <h5 className="modal-title f-w-600">
          {mainState.isCreate ? "Add" : "Edit"} Category
        </h5>
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label htmlFor="recipient-name" className="col-form-label">
              Title :
            </Label>
            <Input
              type="text"
              className="form-control"
              value={mainState.title}
              onChange={(event) => {
                handleTextInput(event, "title");
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="message-text" className="col-form-label">
              Content :
            </Label>
            <Input
              type="text"
              className="form-control"
              value={mainState.content}
              onChange={(event) => {
                handleTextInput(event, "content");
              }}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="primary" onClick={_handleSubmit}>
          {mainState.isCreate ? "Create" : "Save"}
        </Button>
        <Button
          type="button"
          color="secondary"
          outline
          onClick={() => {
            handleClearData();
            handleClose();
          }}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
});
export default CreateCategoryModal;
