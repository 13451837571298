import React, { useEffect, useState } from "react";
import { variables } from "../config/variables";
// contexts
import { utilsContext,defaultStatus } from "../contexts";
import Router from "../Router";
import CustomSnackbar from "./common/customSnackbar";

const App = () => {
	const [utilsContextState, setUtilsContext] = useState(defaultStatus);

	const handleUtilsContextChange = (newState) => {
	  setUtilsContext((prevState) => ({
		...prevState,
		...newState,
	  }));
	};

	window.localStorage.setItem(variables.local_storage_version, JSON.stringify(utilsContextState));
	//delete more than 1 local storage
	if (window.localStorage.length > 1) {
	  const currentKey = variables.local_storage_version;
	  for (var i = 0; i < window.localStorage.length; i++) {
		let key = window.localStorage.key(i);
		if (key !== currentKey && key !== null) {
		  window.localStorage.removeItem(key);
		}
	  }
	}

	return (
		<utilsContext.Provider
		value={{
		  ...utilsContextState,
		  _handleChange: handleUtilsContextChange,
		}}
	  >
		  <CustomSnackbar
		  open={utilsContextState.snackbar}
		  type={utilsContextState.snackbarType}
		  content={utilsContextState.snackbarContent}
		  handleIsOpen={handleUtilsContextChange}
		  />
		 <Router />
		</utilsContext.Provider>
	);
};
export default App;
