import { STATIC_VARIABLE } from "../../constants/static-data";
import useCheckIsValidation from "../../hooks/useCheckIsValidation";
import { deleteUser, getUser, getUserDownList, getUserList, updateUser, getUserSecondDownList, getUserCommissionList, getAllUser } from "../../services/user";
import { getArrayObjByValue } from "../../utils";
import { apiMiddleware } from "../../utils/middleware";
import { comparePassword, isEmpty } from "../../utils/validation";

const contextParams = {
  snackbar: true,
  snackbarType: 1,
  snackbarContent: "",
};

export const handleGetUserListAPI = async ({
  page,
  limit,
  order,
  orderBy,
  search,
  tier,
  shop,
  setMainState,
  changeContext,
}) => {
  const apiParams = {
    page,
    limit,
    order,
    orderBy,
    search,
    tier,
    shop
  };
  console.log(apiParams)
  const { data, success } = await apiMiddleware(changeContext, getUserList, apiParams);
  if (success) {
    setMainState({ list: data.list, total: data.total });
  }
  return success;
};

export const handleGetAllUserListAPI = async ({
  setMainState,
  changeContext,
}) => {
  const { data, success } = await apiMiddleware(changeContext, getAllUser);
  if (success) {
    let arr = [];
    data.map((e) => {
      arr.push({
        label: e.username,
        value: e.ID
      })
    })
    setMainState(arr);
  }
  return success;
};


export const handleUpdateUserAPI = async (username, status, userID, changeContext) => {
  const body = {
    username,
    // firstName: mainState.firstName,
    // lastName: mainState.lastName,
    // email: mainState.email,
    // username: mainState.username,
    // password: mainState.password,
    // isResetPassword: mainState.isResetPassword,
    // confirmPassword: mainState.confirmPassword,
    status: parseInt(status),
    // role: parseInt(mainState.role),
  };

  // const verifyArr = [
  //   isEmpty(body.firstName, "First Name"),
  //   isEmpty(body.lastName, "Last Name"),
  //   isEmpty(body.email, "Email"),
  //   body.isResetPassword ? comparePassword(body.password, mainState.confirmPassword, false) : null,
  // ];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const isValidate = useCheckIsValidation(verifyArr.reverse(), changeContext);

  // if (!isValidate) return false;

  const { data, success } = await apiMiddleware(changeContext, updateUser, userID, body);
  if (success) {
    contextParams.snackbarType = 0;
    contextParams.snackbarContent = `Update Successful!`;
  }
  changeContext(contextParams);
  return success;
};

export const handleDeleteUserAPI = async (id, changeContext) => {
  const { data, success } = await apiMiddleware(changeContext, deleteUser, id);
  if (success) {
    contextParams.snackbarType = 0;
    contextParams.snackbarContent = `Delete Successful!`;
  }
  changeContext(contextParams);
  return success;
};

export const handleGetUserDetailsAPI = async (setMainState, id, changeContext) => {
  const { data, success } = await apiMiddleware(changeContext, getUser, id);
  console.log(data);
  if (success) {
    data.pureStatus = data.status;
    data.pureLevel = data.level;
    data.level = getArrayObjByValue(STATIC_VARIABLE.levelStatusArray, data.level).label;
    data.status = getArrayObjByValue(STATIC_VARIABLE.userStatusArray, data.status).label;
    data.isBenefit = getArrayObjByValue(STATIC_VARIABLE.benefitStatusArray, 1).label;
    setMainState({
      ...data,
      companyName: data.shop.companyName,
      companyEmail: data.shop.companyEmail,
      companyPhone: data.shop.companyPhone,
      shopID: data.shop.shopID,
      shopCode: data.shop.shopCode,
      isBeautician: data.shop.isBeautician,
      SSM: data.shop.SSM,
      shopAddress:
        data.shop.address.address1 + ' ' + data.shop.address.address2 + ', '
        + data.shop.address.postalCode + ' '
        + data.shop.address.city + ' '
        + data.shop.address.state + ' '
        + data.country
    });
  }
  return success;
}

export const handleGetUserLineDetailsAPI = async ({
  userID,
  page,
  limit,
  order,
  orderBy,
  setMainState,
  changeContext,
}) => {
  const apiParams = {
    page,
    limit,
    order,
    orderBy,
  };

  const { data, success } = await apiMiddleware(changeContext, getUserDownList, userID, apiParams);
  if (success) {
    setMainState({ list: data.list, total: data.total });
  }
  return success;
};

export const handleGetUserSecondLineDetailsAPI = async ({
  userID,
  page,
  limit,
  order,
  orderBy,
  setMainState,
  changeContext,
}) => {
  const apiParams = {
    page,
    limit,
    order,
    orderBy,
  };

  const { data, success } = await apiMiddleware(changeContext, getUserSecondDownList, userID, apiParams);
  if (success) {
    setMainState({ list: data.list, total: data.total });
  }
  return success;
};

export const handleGetUserCommissionLineDetailsAPI = async ({
  userID,
  page,
  limit,
  order,
  orderBy,
  startDate,
  endDate,
  setMainState,
  changeContext,
}) => {
  const apiParams = {
    page,
    limit,
    order,
    orderBy,
    startDate,
    endDate,
  };
  console.log(apiParams)
  const { data, success } = await apiMiddleware(changeContext, getUserCommissionList, userID, apiParams);
  if (success) {
    setMainState({ list: data.list, total: data.total });
  }
  return success;
};
