import fetchData from "../../utils/fetchData";

export function getVoucherList(params) {
  return fetchData({
    url: "/vouchers",
    method: "GET",
    params: params,
  });
}

export function createVoucher(body) {
  return fetchData({
    url: "/vouchers",
    method: "POST",
    data: body,
  });
}

export function updateVoucher(voucherID, body) {
  return fetchData({
    url: `/vouchers/${voucherID}`,
    method: "PUT",
    data: body,
  });
}
export function getVoucherDetails(voucherID) {
  return fetchData({
    url: `/vouchers/${voucherID}`,
    method: "GET",
    });
}

export function deleteVoucher(voucherID) {
  return fetchData({
    url: `/vouchers/${voucherID}`,
    method: "DELETE",
  });
}
