import React, { useImperativeHandle, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const DeletePopUp = React.forwardRef((props, ref) => {
  const { title = "", handleDelete = () => {}, isSuspend = false} = props;

  const [mainState, setMainState] = useState({
    deleteID: 0,
    itemName: "",
    isOpen: false,
  });

  const handleOpen = () => {
    setMainState({ isOpen: true });
  };

  const handleClose = () => {
    setMainState({ isOpen: false, itemName: "", deleteID: 0 });
  };

  const _handleDelete = () =>{
    handleDelete(mainState.deleteID)
  }
  
  useImperativeHandle(ref, () => ({
    handleOpen,
    handleClose,
    setMainState,
  }));

  return (
    <Modal isOpen={mainState.isOpen} toggle={handleClose} centered>
      <ModalHeader toggle={handleClose}>
        <h5 className="modal-title f-w-600" id="exampleModalLabel2">
         {isSuspend ? 'Suspend' : 'Delete'}  {title}
        </h5>
      </ModalHeader>
      <ModalBody>
        Are you sure to {isSuspend ? 'suspend' : 'delete'}  <b>{mainState.itemName}</b>?
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="primary" onClick={_handleDelete}>
          {isSuspend ? 'Suspend' : 'Delete'} 
        </Button>
        <Button type="button" outline color="secondary" onClick={handleClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
});
export default DeletePopUp;
