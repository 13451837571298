

import React from "react";

const ProductListImage = ({ photoPath, label, point }) => {
	return (
		<div className={"customize-order-table-image"}>
      <img
        src={photoPath}
        style={{ width: 60, height: 60, marginRight: 10 }}
        alt={label}
      />
      <div style={{marginTop: point ? '0px' : '10px'}} >
        <div>{label}</div>
        { point && <div>[{point} Point]</div>}
      </div>
    </div>
	);
};

export default ProductListImage;
