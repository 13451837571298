import React, { useEffect, useState, useImperativeHandle } from "react";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CustomDropzone from "../drop-zone";
import MyUploader from "../dropzone";

const ClaimModal = React.forwardRef((props, ref) => {
  const { handleSubmit = () => {} } = props;
  const [mainState, setMainState] = useState({
    isOpen: false,
  });

  const [files, setFiles] = useState([]);

  const handleOpen = () => {
    setMainState({ ...mainState, isOpen: true });
  };

  const handleClose = () => {
    setMainState({
      list: [],
      id: 0,
      title: "",
      content: "",
      isOpen: false,
    });
  };

  const _handleSubmit = () => {
    handleSubmit(files);
  };

  useImperativeHandle(ref, () => ({
    handleOpen,
    handleClose,
    setMainState,
  }));

  return (
    <Modal isOpen={mainState.isOpen} toggle={handleClose}>
      <ModalHeader toggle={handleClose}>
        <h5 className="modal-title f-w-600">Upload Receipt To Completed</h5>
      </ModalHeader>
      <ModalBody>
        <Form>
          <CustomDropzone
            currentFile={files}
            handleGetFile={(e) => {
              setFiles(e);
            }}
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="primary" onClick={_handleSubmit}>
          Upload
        </Button>
      </ModalFooter>
    </Modal>
  );
});
export default ClaimModal;
