import React, {Fragment} from "react";
import Breadcrumb from "../common/breadcrumb";
import {Card, CardBody, CardHeader, Col, Row, Container} from "reactstrap";
import ConfigTabs from "./config-tabs";

const Configuration = () => {
  return (
    <Fragment>
      <Breadcrumb title="Configuration" parent="Settings" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Setting</h5>
              </CardHeader>
              <CardBody>
                <ConfigTabs />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default Configuration;
