import { handleDownloadImage } from "../../utils";
import Paper from "@mui/material/Paper";
import { Col } from "reactstrap";

const DownloadButton = ({ name, path }) => {
  return (
    <Col xl="4" style={{ margin: "10px 0px" }}>
      <Paper
        elevation={3}
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <i
          className="fa fa-file-text-o"
          style={{
            fontSize: 20,
            padding: 11,
            cursor: "pointer",
            color: "#777e67",
          }}
        />
        {name}
        <i
          className="fa fa-download"
          style={{
            fontSize: 15,
            padding: 11,
            cursor: "pointer",
          }}
          onClick={() => handleDownloadImage({ path: path, name: name })}
        />
      </Paper>
    </Col>
  );
};
export default DownloadButton;
