import fetchData from "../../utils/fetchData";

export function createAdmin(body) {
  return fetchData({
    url: "/admin/create",
    method: "POST",
    data: body,
  });
}

export function getAdminList(params) {
  return fetchData({
    url: "/admin/list",
    method: "GET",
    params: params,
  });
}

export function getAdmin(adminID) {
  return fetchData({
    url: `/admin/${adminID}/details`,
    method: "GET",
  });
}

export function updateAdmin(adminID, body) {
  return fetchData({
    url: `/admin/${adminID}/details`,
    method: "PUT",
    data: body,
  });
}

export function deleteAdmin(adminID) {
  return fetchData({
    url: `/admin/${adminID}/details`,
    method: "DELETE",
  });
}
