import {getClaim, getClaimListExcel, getClaimsList, setClaim} from "../../services/claims";
import { apiMiddleware } from "../../utils/middleware";

const contextParams = {
  snackbar: true,
  snackbarType: 1,
  snackbarContent: "",
};

export const handleGetClaimListAPI = async ({
  page,
  limit,
  order,
  orderBy,
  search,
  startDate,
  endDate,
  orderStatus,
  setMainState,
  changeContext,
}) => {
  const apiParams = {
    page,
    limit,
    order,
    orderBy,
    search,
    startDate,
    endDate,
    status: orderStatus
  };

  const { data, success } = await apiMiddleware(changeContext, getClaimsList, apiParams);
  if (success) {
    setMainState({ list: data.list, total: data.total, overview: data.overview });
  }
  return success;
};

export const handleGetClaimListExcelAPI = async ({
  order,
  orderBy,
  search,
  startDate,
  endDate,
  orderStatus,
  changeContext,
}) => {
  const apiParams = {
    page: 1,
    limit: 1,
    order,
    orderBy,
    search,
    startDate,
    endDate,
    status: orderStatus
  };

  return apiMiddleware(changeContext, getClaimListExcel, apiParams);
}


export const handleGetClaimDetailsAPI = async (setMainState, id, changeContext) => {
  const { data, success } = await apiMiddleware(changeContext, getClaim, id);
  if (success) {
    setMainState({ ...data });
  }
  return success;
}


export const handleSubmitReceiptAPI = async (
  currentUploadFile,
  pointID,
  status,
  changeContext,
) => {
  const formData = new FormData();
  currentUploadFile.forEach((file) => {
    formData.append("file", file);
  });
  formData.append("status", status);
  const { data, success } = await apiMiddleware(changeContext, setClaim, formData, pointID);

  if (success) {
    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "Submit Success!";
    changeContext(contextParams);
  }

  return success;
};
