import useCheckIsValidation from "../../hooks/useCheckIsValidation";
import {createVoucher, deleteVoucher, getVoucherDetails, getVoucherList, updateVoucher} from "../../services/voucher";
import { apiMiddleware } from "../../utils/middleware";
import { isEmpty, isEmptyNumber, isEmptySelected } from "../../utils/validation";
import moment from "moment";

const contextParams = {
  snackbar: true,
  snackbarType: 1,
  snackbarContent: "",
};

export const handleCreateVoucherAPI = async (mainState, changeContext) => {
  const body = {
    title: mainState.title, //*
    description: mainState.description,
    type: mainState.type, //*
    code: mainState.code, //*
    discount: parseFloat(mainState.discount), //*
    discountType: parseInt(mainState.discountType), //*
    total: mainState.total, //*
    minSpend: mainState.minSpend,
    status: mainState.status ? 1 : 2, //*
    expiredDate: mainState.expiredDate ? moment(mainState.expiredDate).format("YYYY-MM-DD") : null, //mainState.expiredDate,
    points: mainState.points,
    validDays: mainState.validDays,
  };

  const verifyArr = [
    isEmpty(body.title, "Title"),
    isEmpty(body.code, "Code"),
    isEmptyNumber(body.discount, "Discount"),
    isEmpty(body.minSpend, "Minimum Spend"),
    isEmpty(body.points, "Points"),
    isEmptySelected(body.type, "Type"),
    isEmptySelected(body.discountType, "Discount Type"),
  ];

  // Only normal voucher need a totals
  if (body.type === 3) verifyArr.push(isEmptyNumber(body.total, "Total Quantity"));

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isValidate = useCheckIsValidation(verifyArr.reverse(), changeContext);
  if (!isValidate) return false;

  const { success } = await apiMiddleware(changeContext, createVoucher, body);
  if (success) {
    contextParams.snackbarType = 0;
    contextParams.snackbarContent = `Create Successful!`;
  }
  changeContext(contextParams);
  return success;
};

export const handleGetVoucherListAPI = async ({
  page,
  limit,
  order,
  orderBy,
  search,
  type,
  status,
  setMainState,
  changeContext,
}) => {
  const apiParams = {
    page,
    limit,
    order,
    orderBy,
    search,
    type,
    status,
  };

  const { data, success } = await apiMiddleware(changeContext, getVoucherList, apiParams);
  if (success) {
    setMainState({ list: data.list, total: data.total });
  }
  return success;
};

export const handleGetVoucherAPI = async (voucherID, setMainState, changeContext) => {
  const { data, success } = await apiMiddleware(changeContext, getVoucherDetails, voucherID);
  if (success) {
    const { ID, title, description, code, discount, discountType, points, minSpend, createdAt, expiredAt, validDays, type, status, total, balance } = data;
    setMainState({
      ID,
      title,
      balance,
      code,
      discount,
      discountType,
      createdAt,
      expiredDate: expiredAt,
      points,
      minSpend,
      validDays,
      description,
      status: (status === 1),
      total,
      type,
    });
  }
  return success;
};

export const handleUpdateVoucherAPI = async (mainState, changeContext) => {
  const body = {
    title: mainState.title, //*
    description: mainState.description,
    type: mainState.type, //*
    code: mainState.code, //*
    discount: parseFloat(mainState.discount), //*
    discountType: parseInt(mainState.discountType), //*
    total: mainState.total, //*
    status: mainState.status ? 1 : 2, //*
    minSpend: mainState.minSpend,
    points: mainState.points,
    expiredDate: mainState.expiredDate ? moment(mainState.expiredDate).format("YYYY-MM-DD") : null, //mainState.expiredDate,
    validDays: mainState.validDays,
  };

  const verifyArr = [
    isEmpty(body.title, "Title"),
    isEmpty(body.code, "Code"),
    isEmptyNumber(body.discount, "Discount"),
    isEmpty(body.points, "Points"),
    isEmpty(body.minSpend, "Minimum Spend"),
    isEmptySelected(body.type, "Type"),
    isEmptySelected(body.discountType, "Discount Type"),
  ];

  // Only normal voucher need a totals
  if (body.type === 3) verifyArr.push(isEmptyNumber(body.total, "Total Quantity"));

  // console.log(body.type)
  // if (body.type === 2) verifyArr.push(isEmpty(body.validDays, "Valid Days"));

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isValidate = useCheckIsValidation(verifyArr.reverse(), changeContext);
  if (!isValidate) return false;

  const { success } = await apiMiddleware(changeContext, updateVoucher, mainState.ID,body);
  if (success) {
    contextParams.snackbarType = 0;
    contextParams.snackbarContent = `Update Successful!`;
  }
  changeContext(contextParams);
  return success;
};


export const handleDeleteVoucherAPI = async (id, changeContext) => {
  const {success} = await apiMiddleware(changeContext, deleteVoucher, id)
  if (success) {
    contextParams.snackbarType = 0;
    contextParams.snackbarContent = `Delete Successful!`;
  }
  changeContext(contextParams);
  return success;
}
