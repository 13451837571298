import useCheckIsValidation from "../../hooks/useCheckIsValidation";
import { getDetails, login, updateDetails, checkLogin, logout } from "../../services/account";
import { apiMiddleware } from "../../utils/middleware";
import { isEmpty } from "../../utils/validation";

const contextParams = {
  snackbar: true,
  snackbarType: 1,
  snackbarContent: "",
};

export const handleLoginAPI = async (username, password, rememberMe, changeContext) => {
  const body = {
    username,
    password,
    rememberMe
  };

  const verifyArr = [isEmpty(username, "Username"), isEmpty(password, "Password")];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isValidate = useCheckIsValidation(verifyArr.reverse(), changeContext);
  if (!isValidate) return false;

  const { data, success } = await apiMiddleware(changeContext, login, body);
  console.log(data)
  if (success) {
    //setLocalStorage
    contextParams.isLogin = true;
    contextParams.user = data;
    contextParams.snackbarType = 3;
    contextParams.snackbarContent = `Welcome ${data.username}!`;
   }
  // else {
  //   contextParams.snackbarType = 1;
  //   contextParams.snackbarContent = data.message;
  // }
  changeContext(contextParams);

  return success;
};

export const handleLogoutAPI = async (changeContext) => {
  const { data, success } = await apiMiddleware(changeContext, logout);
  if(success){
    contextParams.isLogin = false;
    contextParams.user = {
      username: "A",
      name: "",
      email: "",
      role: 0,
      status: 0,
    };
    contextParams.snackbarType = 3;
    contextParams.snackbarContent = "Logout Successfully";
    changeContext(contextParams);
  }

  return true;
};

export const handleGetUserDetailsAPI = async (setMainState, changeContext) => {
  const { data, success } = await apiMiddleware(changeContext, getDetails);

  if (success) {
    setMainState({ ...data });
  }

  return success;
};

export const handleUpdateDetailsAPI = async (mainState, changeContext) => {

  const body = {
    username: mainState.username,
    firstName: mainState.firstName,
    lastName: mainState.lastName,
    email: mainState.email,
    role: mainState.role,
    status: mainState.status,
  };

  const { data, success } = await apiMiddleware(changeContext, updateDetails, body);


  if (success) {
    contextParams.snackbarType = 0;
    contextParams.snackbarContent = "Update Successful!";
  }
  changeContext(contextParams);

  return success;
};

export const handleCheckLoginAPI = async (changeContext) => {
  const { data, success } = await apiMiddleware(changeContext, checkLogin);
  
  return {data, success}
}
