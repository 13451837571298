import React, { Fragment, useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  ButtonDropdown,
  Label,
  Row,
} from "reactstrap";

import { useParams } from "react-router-dom";
import { useProps } from "../../contexts";
//Components
import Breadcrumb from "../common/breadcrumb";
//Controller
import { getArrayObjByValue, setOrderStatusTag, setPriceFormat, setStateHelper } from "../../utils";
import { STATIC_VARIABLE } from "../../constants/static-data";
import Datatable from "../common/datatable";
import CustomSelector from "../common/custom-selector";
import { handleGetUserOrderDetailsAPI, handleUpdateStatusAPI } from "../../controller/order";
import ProductListImage from "../common/product-list-img";

const generateDirectCourier = (link: string, trackingID: string) => {
  let newDirectLink = link

  switch (link) {
    case 'track.logistika.com.my':
      newDirectLink = `https://${link}`
      break;
    case 'www.fmx.asia':
      newDirectLink = `https://www.fmx.asia/result_detail.php?p=1&conno=${trackingID}`
      break;
    case 'www.aramex.com/us/en/track/shipments':
      newDirectLink = `https://www.aramex.com/us/en/track/results?mode=0&ShipmentNumber=${trackingID}`
      break;
  }

  return newDirectLink
}


const generateBody = (body, subTotal, totalAmount, appliedCoin, shoppingDollar, voucher, isMemberFee, registerFee, shippingFee) => {
  let arr = body.map((e, i) => ({
    photos: <ProductListImage photoPath={e.photo.length > 0 ? e.photo[0].URL : null} label={e.title} point={e.pvPoint}/>,
    unitPrice: <div style={{ overflow: "initial", whiteSpace: "break-spaces" }}>RM{setPriceFormat(e.unitPrice)}</div>,
    quantity: e.quantity,
    subTotalSum: "RM " + (e.isBundle ? setPriceFormat(e.discountAmount) : setPriceFormat(e.unitPrice * e.quantity)),
  }));


  arr.push({
    photos: "",
    unitPrice: "",
    quantity: <div className="f-w-600">SubTotal</div>,
    subTotalSum: <div className="f-w-600">RM {setPriceFormat(subTotal)}</div>,
  });


  if (isMemberFee) {
    arr.push({
      photos: <div className="f-w-600">Register Fee:</div>,
      unitPrice: "",
      quantity: "",
      subTotalSum: <div className="f-w-600">RM {registerFee}</div>,
    });
  }

  arr.push({
    photos: <div className="f-w-600">Shipping Fee: </div>,
    unitPrice: "",
    quantity: "",
    subTotalSum: <div className="f-w-600"> RM {setPriceFormat(shippingFee)}</div>,
  });

  arr.push({
    photos: <div className="f-w-600">Shopping Coins: {appliedCoin} Coin(s)</div>,
    unitPrice: "",
    quantity: "",
    subTotalSum: (
      <div className="f-w-600" style={{ color: "red" }}>
        {" "}
        -RM {setPriceFormat(shoppingDollar)}
      </div>
    ),
  });

  arr.push({
    photos: (
      <div className="f-w-600">
        Voucher Discount:{" "}
        {voucher.voucherCode ? `#${voucher.voucherCode}`: "-"}
      </div>
    ),
    unitPrice: "",
    quantity: "",
    subTotalSum: (
      <div className="f-w-600" style={{ color: "red" }}>
        {" "}
        -RM {voucher.voucherDiscount ? setPriceFormat(voucher.voucherDiscount) : '0.00'}
      </div>
    ),
  });

  arr.push({
    photos: "",
    unitPrice: "",
    quantity: <div className="f-w-600">Total</div>,
    subTotalSum: (
      <div className="f-w-600" style={{ overflow: "initial" }}>
        RM {setPriceFormat(totalAmount)}
      </div>
    ),
  });

  return arr;
};

const header = [
  { id: "photos", label: "Item(s)", align: "center", sortable: false },
  { id: "unitPrice", label: "Unit Price", align: "center", sortable: false },
  { id: "quantity", label: "Quantity", align: "center", sortable: false },
  { id: "subTotalSum", label: "Sub Total", align: "center", sortable: false },
];

const OrderDetails = () => {
  const { orderID } = useParams();
  const { _handleChange } = useProps();
  const history = useHistory();
  const [mainState, setMainState] = useState({
    ID: "",
    orderStatus: 2,
    product: [],
    subTotal: 0,
    coinReceive: 0,
    totalAmount: 0,
    trackingID: '',
    courier: '',
    receiverInfo: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: ''
    },
    address: {
      shippingAddress: {
        address1: "",
        address2: "",
        postalCode: "",
        city: "",
        state: "",
        country: "",
      },
      billingAddress: {
        address1: "",
        address2: "",
        postalCode: "",
        city: "",
        state: "",
        country: "",
      },
    },
    remarks: "",
    username: "",
    userID: 0,
    phoneNumber: "",
    email: "",
    appliedCoin: 0,
    coinValue: 0,
    type: 2,
    shippingFee: 0,
    shippingDate: null,
    isMemberFee: false,
    registerFee: "0.00",
    voucher: {
      voucherTitle: null,
      voucherCode: null,
      voucherDiscount: 0,
    },
    firstname: "",
    lastname: "",
    country: "",
    createdAt: "",
    updateAt: "",
    selectedOrderStatus: 0,
    upperLine: null,
    upLine: null
  });

  const body = generateBody(
    mainState.product,
    mainState.subTotal,
    mainState.totalAmount,
    mainState.appliedCoin,
    mainState.coinValue,
    mainState.voucher,
    mainState.isMemberFee,
    mainState.registerFee,
    mainState.shippingFee
  );

  const _handleGetOrderAPI = () => {
    handleGetUserOrderDetailsAPI(setStateHelper(setMainState), orderID, _handleChange);
  };

  useEffect(() => {
    _handleGetOrderAPI();
  }, [mainState.refreshData]);


  const _addressCombine = (obj) => {
    let address =
      obj.address1 + "," + obj.address2 + "," + obj.postalCode + " " + obj.city + " " + obj.state + " " + obj.country;
    return address;
  };

  const _handleUpdateStatus = () => {
    handleUpdateStatusAPI(mainState, orderID, _handleChange).then((isSuccess) => {
      if (isSuccess) {
        _handleGetOrderAPI();
      }
    });
  };

  const FormDiv = useCallback(({ value, label, type, id, disabled = false, userID }) => {
    let input = (
      <Input
        className="form-control"
        id={id}
        type={type}
        readonly={disabled ? "readonly" : false}
        required=""
        value={value}
        onChange={(e) => {
          setStateHelper(setMainState)({ [e.target.id]: e.target.value });
        }}
      />
    );
    if (type == "link") {
      let href = "#";
      if (id === "username") {
        href = `/users/details-user/${userID}`;
      }

      if (id === "phoneNumber") {
        href = `tel: ${value}`;
      }

      if (id === "email") {
        href = `mailto: ${value}`;
      }
      input = (
        <a className={"customize-a"} href={href}>
          {value}
        </a>
      );

      if (value == "-") {
        input = <div className="empty-label">{value}</div>;
      }
    }

    if (type == "drop") {
      let cloneArr = [...STATIC_VARIABLE.orderStatusArray];
      cloneArr.shift();
      cloneArr.shift();
      cloneArr.pop();
      input = (
        // <div style={{ flex: "0.90" }}>
        <div className="p-0">
          <CustomSelector
            initStatus={getArrayObjByValue(cloneArr, mainState.selectedOrderStatus)}
            options={cloneArr}
            disabled={disabled}
            handleChange={(e) => {
              setStateHelper(setMainState)({
                selectedOrderStatus: e.value,
              });
            }}
          />
        </div>
      );
    }
    return (
      <FormGroup className={type === "link" && 'row'} style={type === "link" ? { marginBottom: 5 } : {}}>
        <Label className={type === "link" ? "col-lg-5 col-md-3 col-6" : ""}>{label}</Label>
        {input}
      </FormGroup>
    );
  }, []);

  return (
    <Fragment>
      <Breadcrumb title={`Order Details`} parent="Sales" />
      <Container fluid={true}>
        <Row className="product-adding">
          <Col xl="8">
            <Card>
              <CardHeader className={"customize-order-table-header"}>
                <h5>Order General [{mainState.ID}] </h5>
                <h7 style={{ marginLeft: 10 }}>{setOrderStatusTag(mainState.orderStatus)}</h7>
              </CardHeader>
              <CardBody>
                <div className="digital-add needs-validation">
                  <Row>
                    <Col xs={12} md={6} className='mx-auto'>
                      <FormDiv value={mainState.ID} label={"Order ID"} id={"ID"} type={"text"} disabled />
                      <FormDiv
                        value={
                          getArrayObjByValue(STATIC_VARIABLE.orderStatusArray, mainState.orderStatus).label
                        }
                        label={"Order Status"}
                        id={"orderStatus"}
                        type={"text"}
                        disabled
                      />
                    </Col>
                    <Col xs={12} md={6} className='mx-auto'>
                      <FormDiv
                        value={mainState.createdAt}
                        label={"Create Date"}
                        id={"createdAt"}
                        type={"text"}
                        disabled
                      />
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h5>Order Product {mainState.type == 2 && "[Shop Activate Order]"}</h5>
              </CardHeader>
              <CardBody>
                <Datatable
                  header={header}
                  multiSelectOption={false}
                  // myData={data}
                  myData={body}
                  pageSize={mainState.total}
                  // pageSize={9}
                  pagination={false}
                  class="-striped -highlight"
                />
                {
                  mainState.type != 2 && 
                  <FormGroup style={{ marginTop: 30, marginBottom: 0 }}>
                    <div className="digital-add needs-validation">
                      <Row>
                        <Col sx={12} md={6} className='mx-auto'>
                          <FormDiv
                            label={"Shopping Dollar"}
                            id={"coinReceive"}
                            type={"text"}
                            value={mainState.coinReceive ? setPriceFormat(mainState.coinReceive) : '0.00'}
                            disabled
                          />
                        </Col>
                        <Col sx={12} md={6} className='mx-auto'>
                          <FormDiv
                            label={"Total Point"}
                            id={"totalPoint"}
                            type={"text"}
                            value={mainState.totalPoint}
                            disabled
                          />
                        </Col>
                      </Row>
                    </div>
                  </FormGroup>
                }
               
                <FormGroup style={{ marginTop: "0px" }}>
                  <Label className="col-form-label pt-0">Remarks</Label>
                  <Input
                    readonly={"readonly"}
                    id={"remark"}
                    type={"textarea"}
                    required=""
                    value={mainState.remarks}
                  />
                </FormGroup>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h5>Shipping Details</h5>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <div className="digital-add needs-validation">
                    <Row>
                      <Col sx={12} md={6} className='mx-auto'>
                        <FormDiv
                          label={"Recipient Name"}
                          id={"name"}
                          type={"text"}
                          value={mainState.receiverInfo.firstName + ' ' + mainState.receiverInfo.lastName}
                          disabled
                        />
                      </Col>
                      <Col sx={12} md={6} className='mx-auto'>
                        <FormDiv
                          label={"Shipping Date"}
                          id={"updateAt"}
                          type={"text"}
                          value={mainState.orderStatus < 2 ? "-" : mainState.shippingDate}
                          disabled
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sx={12} md={6} className='mx-auto'>
                        <FormDiv
                          label={"Recipient Email"}
                          id={"email"}
                          type={"text"}
                          value={mainState.receiverInfo.email}
                          disabled
                        />
                      </Col>
                      <Col sx={12} md={6} className='mx-auto'>
                        <FormDiv
                          label={"Recipient Phone"}
                          id={"phoneNumber"}
                          type={"text"}
                          value={mainState.receiverInfo.phoneNumber}
                          disabled
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="6">
                        <FormDiv
                          label={"Shipping Address"}
                          id={"shippingAddress"}
                          type={"textarea"}
                          value={_addressCombine(mainState.address.shippingAddress)}
                          disabled
                        />
                      </Col>
                      <Col xl="6">
                        <FormDiv
                          label={"Billing Address"}
                          id={"billingAddress"}
                          type={"textarea"}
                          value={_addressCombine(mainState.address.billingAddress)}
                          disabled
                        />
                      </Col>
                    </Row>
                  </div>
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card>
              <CardHeader>
                <h5>Status Update</h5>
              </CardHeader>
              <CardBody>
                <div className="digital-add needs-validation">
                  <Row>
                    <Col xl="12">
                      <FormDiv
                        value={mainState.trackingID}
                        label={"Status"}
                        id={"status"}
                        type={"drop"}
                        disabled={mainState.orderStatus != 2}
                      />
                      <FormDiv
                        value={mainState.trackingID}
                        label={"Tracking ID"}
                        id={"trackingID"}
                        type={"text"}
                      />
                    </Col>
                  </Row>
                  {mainState.trackingID &&
                    <h7 className="customize-a mb-1">
                      <a href={generateDirectCourier(mainState.courier, mainState.trackingID)} target="_blank" style={{ marginLeft: 5 }}>{mainState.courier}</a>
                      <br />
                    </h7>
                  }
                  <h7 className={"customize-reminder-text"}>
                    {mainState.orderStatus == 2 && "Noted: Tracking ID can be updated after payment is completed"}
                  </h7>
                </div>
                <FormGroup className="mb-0 mt-3">
                  <div className="product-buttons customize-f-end">
                    <Button
                      type="button"
                      color="primary"
                      onClick={() => {
                        _handleUpdateStatus();
                      }}
                    >
                      Update
                    </Button>
                  </div>
                </FormGroup>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h5>Purchased By</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xl="12">
                    <FormDiv
                      value={mainState.username ? "#" + mainState.userID + " " + mainState.username : "-"}
                      label={"Username"}
                      id={"username"}
                      type={"link"}
                      userID={mainState.userID}
                    />
                    <FormDiv
                      value={mainState.phoneNumber ? mainState.phoneNumber : "-"}
                      label={"Contact No."}
                      id={"phoneNumber"}
                      type={"link"}
                    />
                    <FormDiv value={mainState.email ? mainState.email : "-"} label={"Email"} id={"email"} type={"link"} />
                    {
                      mainState.upperLine &&
                      <FormDiv
                        value={mainState.upperLine.username ? "#" + mainState.upperLine.ID + " " + mainState.upperLine.username : "-"}
                        label={"Upper Line"}
                        id={'upperLine'}
                        type={"link"}
                        userID={mainState.upperLine.ID}
                      />
                    }
                   {
                      mainState.upLine &&
                      <FormDiv
                        value={mainState.upLine.username ? "#" + mainState.upLine.ID + " " + mainState.upLine.username : "-"}
                        label={"Up Line"}
                        id={'upLine'}
                        type={"link"}
                        userID={mainState.upLine.ID}
                      />
                    }
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default OrderDetails;
